<template>
  <div class="send">
    <BaseCard>
      <template #title>Send message</template>
      <template #description>
        Your friend will be notified and can reply using your email address.
      </template>
      <form
        id="shareByEmailForm"
        class="send__form"
        autocomplete="off"
        @submit.prevent="handleSendRequest"
      >
        <div
          class="send__form-field"
          :class="{
            'send__form-field--is-error': $v.form.recipientName.$error
          }"
        >
          <label
            class="send__form-label send__form-label--is-hidden"
            for="recipientName"
          >
            Your friend's name
          </label>
          <input
            ref="recipientName"
            id="recipientName"
            class="send__form-input"
            :class="{
              'send__form-input--is-error': $v.form.recipientName.$error
            }"
            type="text"
            v-model.trim="$v.form.recipientName.$model"
            autocomplete="given-name"
            autocapitalize="words"
            placeholder="Your friend's name"
            @blur="checkInputIsValid($v.form.recipientName, $event)"
          />
          <transition name="fade">
            <div
              class="send__input-error"
              v-if="!$v.form.recipientName.required"
            >
              Oops, you need to enter your friend's name so we can personalise
              the email.
            </div>
          </transition>
        </div>
        <div
          class="send__form-field"
          :class="{
            'send__form-field--is-error': $v.form.recipientEmail.$error
          }"
        >
          <label
            class="send__form-label send__form-label--is-hidden"
            for="recipientEmail"
          >
            Your friend's email address
          </label>
          <input
            ref="recipientEmail"
            id="recipientEmail"
            class="send__form-input"
            :class="{
              'send__form-input--is-error': $v.form.recipientEmail.$error
            }"
            type="email"
            v-model.trim="$v.form.recipientEmail.$model"
            autocomplete="email"
            autocapitalize="none"
            placeholder="Your friend's email address"
            @input="delayTouch($v.form.recipientEmail)"
            @blur="checkInputIsValid($v.form.recipientEmail, $event)"
          />
          <transition name="fade">
            <div class="send__input-error" v-if="!$v.form.recipientEmail.email">
              There's something wrong with this address. Please check and type
              it again.
            </div>
          </transition>
          <transition name="fade">
            <div
              class="send__input-error"
              v-if="!$v.form.recipientEmail.required"
            >
              Your friend's email address is required.
            </div>
          </transition>
        </div>
        <div class="send__form-field" v-show="messageData.secretKey.length > 0">
          <label class="checkbox__label" for="includeSecretKey">
            Include secret key
            <input
              id="includeSecretKey"
              class="checkbox__checkbox"
              type="checkbox"
              v-model="form.includeSecretKey"
            />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
        <BaseButtonGroup>
          <BaseButton secondary pad @click.native.prevent="cancelSend">
            Cancel
          </BaseButton>
          <BaseButton
            @click.native.prevent="handleSendRequest"
            type="submit"
            primary
            pad
            :loading="loading"
            :disabled="
              !this.form.recipientEmail ||
                !this.form.recipientName ||
                this.$v.form.$anyError ||
                loading
            "
          >
            Send
          </BaseButton>
        </BaseButtonGroup>
      </form>
    </BaseCard>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createMessage } from "@/graphql/mutations";
import { sendNotificationEmail } from "@/graphql/queries";
import { getBlockedUserCustom } from "@/graphql/custom/blockedUser.queries";
import { email, required } from "vuelidate/lib/validators";
import { Howl } from "howler";
// // Setup sounds
const sendMessageSuccessSound = new Howl({
  src: [
    "https://res.cloudinary.com/dv5har4fh/video/upload/v1600971650/StickerCode/audio/sendEmailSuccess.mp3"
  ]
});
const sendMessageFailureSound = new Howl({
  src: [
    "https://res.cloudinary.com/dv5har4fh/video/upload/v1603104327/StickerCode/audio/stickerDecodeFailure.mp3"
  ]
});
// For use in delayTouch() method used to delay email validation
const touchMap = new WeakMap();

export default {
  name: "Send",
  data() {
    return {
      form: {
        recipientName: "",
        recipientEmail: "",
        includeSecretKey: true
      },
      messageId: null,
      loading: false,
      user: {}
    };
  },
  props: {
    messageData: {
      type: Object,
      required: true
    }
  },
  validations: {
    form: {
      recipientName: {
        required
      },
      recipientEmail: {
        required,
        email
      }
    }
  },
  created() {
    if (this.$route.params.senderName) {
      this.form.recipientName = this.$route.params.senderName;
    } else {
      this.$nextTick(() => {
        this.$refs.recipientName.focus();
      });
    }
    if (this.$route.params.senderEmail) {
      this.form.recipientEmail = this.$route.params.senderEmail;
    }
  },
  mounted() {
    this.getCurrentUser();
  },
  methods: {
    cancelSend() {
      this.$emit("cancel");
    },
    done() {
      this.$emit("done");
    },
    // Delay (by 2 seconds after last input) the validation of fields by vuelidate (see https://vuelidate.js.org/#sub-delayed-validation-errors)
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 2000));
    },
    checkInputIsValid(value, event) {
      if (value.$anyError) {
        event.target.classList.add("send__form-input--shake");
        setTimeout(() => {
          event.target.classList.remove("send__form-input--shake");
        }, 600);
      }
    },
    async getCurrentUser() {
      this.user = await Auth.currentAuthenticatedUser();
    },
    getMessageUrl() {
      return process.env.VUE_APP_ROOT_URL + "read/" + this.messageId;
    },
    getReportUrl() {
      return process.env.VUE_APP_ROOT_URL + "report/" + this.messageId;
    },
    async getBlockedUserCustom(recipientEmail, senderId) {
      try {
        const result = await API.graphql(
          graphqlOperation(getBlockedUserCustom, {
            blockingUserEmail: recipientEmail,
            blockedUserId: senderId
          })
        );
        // console.log(result);
        return result;
      } catch (error) {
        return error;
      }
    },
    async handleSendRequest() {
      if (!this.$v.form.$anyError) {
        this.loading = true;
        try {
          await this.saveMessage();
        } catch (error) {
          return this.$emit("saveError", this.form);
        }
        try {
          await this.sendMessage();
          this.loading = false;
          return this.$emit("sendSuccess", this.form);
        } catch (error) {
          this.$emit("sendError", this.form);
          return error;
        }
      }
    },
    async saveMessage() {
      // Generate a new messageId
      const messageId = await this.generateId();
      // Store the messageId for use in this component
      this.messageId = messageId;
      // Generate the expiration date for this message
      const expiration = await this.generateExpiration();
      // Build a message data object
      const message = {
        id: this.messageId,
        senderId: this.user.attributes.sub,
        senderName: this.user.attributes.name,
        senderEmail: this.user.attributes.email,
        recipientName: this.form.recipientName,
        recipientEmail: this.form.recipientEmail,
        content: this.messageData.content,
        secretKey: this.messageData.secretKey,
        stickerPack: this.messageData.stickerPack,
        expiration: expiration
      };
      // console.log(message);
      try {
        // Attempt to save message to backend. This could fail if the messageId is already taken.
        const data = await API.graphql(
          graphqlOperation(createMessage, { input: message })
        );
        return data;
      } catch (error) {
        return error;
      }
    },
    async generateId() {
      // Generate a random ID (e.g. 3fd-j6n-27e).
      var firstPart = (Math.random() * 46656) | 0;
      var secondPart = (Math.random() * 46656) | 0;
      var thirdPart = (Math.random() * 46656) | 0;
      firstPart = ("000" + firstPart.toString(36)).slice(-3);
      secondPart = ("000" + secondPart.toString(36)).slice(-3);
      thirdPart = ("000" + thirdPart.toString(36)).slice(-3);
      return firstPart + "-" + secondPart + "-" + thirdPart;
    },
    async generateExpiration() {
      const numWeeks = 4; // Number of weeks before expiration
      // Get the current date/time
      const now = new Date();
      // Add on the weeks before expiration to produce an expiration Unix epoch timestamp in milliseconds
      const expiration = Math.floor(now.setDate(now.getDate() + numWeeks * 7));
      // alert(expiration);
      return expiration;
    },
    async sendMessage() {
      const messageData = {
        senderId: this.user.attributes.sub,
        senderName: this.user.attributes.name,
        senderEmail: this.user.attributes.email,
        recipientName: this.form.recipientName,
        recipientEmail: this.form.recipientEmail,
        messageUrl: this.getMessageUrl(),
        reportUrl: this.getReportUrl(),
        secretKey: this.messageData.secretKey
      };
      // console.log(messageData);
      // Has the recipient blocked this sender?
      const result = await this.getBlockedUserCustom(
        messageData.recipientEmail,
        messageData.senderId
      );
      // console.log(result);
      // NB: Note that we need to use `getBlockedUser` here not `getBlockedUserCustom` as this is the name of model that we are querying
      if (result.data.getBlockedUser === null) {
        try {
          const email = await API.graphql(
            graphqlOperation(sendNotificationEmail, messageData)
          );
          this.emailHasBeenSent = true;
          this.playMessageSendSuccessSound();
          return email;
        } catch (error) {
          let errors = error.errors;
          this.playMessageSendFailureSound();
          return errors;
        }
      } else {
        this.playMessageSendFailureSound();
        return this.$emit("sendBlocked", this.form);
      }
    },
    playMessageSendSuccessSound() {
      sendMessageSuccessSound.play();
    },
    playMessageSendFailureSound() {
      sendMessageFailureSound.play();
    },
    clearForm() {
      (this.form.recipientEmail = ""), (this.form.recipientName = "");
      this.$nextTick(() => {
        // Reset validations so that we don't display errors
        this.$v.$reset();
      });
    },
    resetForm() {
      this.clearForm();
    }
  }
};
</script>

<style lang="scss">
.send {
  padding: 1.25rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.send__form {
}
.send__form-field {
  margin: 1rem 0;
  position: relative; // So we can position error messages abaolutely
}
.send__form-label--is-hidden {
  @include visually-hidden;
}
.send__form-input {
  width: 100%;
  height: 2.5rem;
  padding: 0 0.5rem;
  background-color: #ebecef;
  box-sizing: border-box;
  border: none;
  border-radius: 0.5rem;
  font-family: $base-font-family;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  outline: none;
  resize: vertical;
  -webkit-appearance: none;
  color: #202127;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  @include responsive(small) {
    height: 3rem;
    padding: 0 1rem;
  }
}
.send__form-input--is-error {
  border: 1px solid red;
}
.send__input-error {
  padding: 0.5rem 0.75rem;
  background: $background-dark-linear;
  border-radius: 0.5rem;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 3rem;
  display: none;
  @include responsive(small) {
    padding: 0.75rem 1rem;
    bottom: 3.5rem;
  }
}
.send__form-input--shake {
  animation: shake 0.2s;
  animation-iteration-count: 3;
}
.send__form-field--is-error .send__input-error {
  display: block;
}

.checkbox__label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
}
.checkbox__checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #e6e7eb;
  box-shadow: inset 0 0 4px rgba(#a3b8ab, 0.8);
  border-radius: 0.25rem;
}
/* On mouse-over, add a grey background color */
.checkbox__label:hover .checkbox__checkbox ~ .checkbox__checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a green background */
.checkbox__label .checkbox__checkbox:checked ~ .checkbox__checkmark {
  background-color: #353741;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkbox__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox__label .checkbox__checkbox:checked ~ .checkbox__checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkbox__label .checkbox__checkmark:after {
  left: 8px;
  top: 4px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
