<template>
  <li
    class="cipher-text-character"
    :class="{
      'cipher-text-character--is-selected': character.selected,
      'cipher-text-character--is-decrypted':
        character.encryptionState === 'decrypted',
      'cipher-text-character--is-decryption-error':
        character.encryptionState === 'decryptionError'
    }"
  >
    <BaseSticker1 v-if="glyphCipherCode === 1" :stickerPack="stickerPack" />
    <BaseSticker2 v-if="glyphCipherCode === 2" :stickerPack="stickerPack" />
    <BaseSticker3 v-if="glyphCipherCode === 3" :stickerPack="stickerPack" />
    <BaseSticker4 v-if="glyphCipherCode === 4" :stickerPack="stickerPack" />
    <BaseSticker5 v-if="glyphCipherCode === 5" :stickerPack="stickerPack" />
    <BaseSticker6 v-if="glyphCipherCode === 6" :stickerPack="stickerPack" />
    <BaseSticker7 v-if="glyphCipherCode === 7" :stickerPack="stickerPack" />
    <BaseSticker8 v-if="glyphCipherCode === 8" :stickerPack="stickerPack" />
    <BaseSticker9 v-if="glyphCipherCode === 9" :stickerPack="stickerPack" />
    <BaseSticker10 v-if="glyphCipherCode === 10" :stickerPack="stickerPack" />
    <BaseSticker11 v-if="glyphCipherCode === 11" :stickerPack="stickerPack" />
    <BaseSticker12 v-if="glyphCipherCode === 12" :stickerPack="stickerPack" />
    <BaseSticker13 v-if="glyphCipherCode === 13" :stickerPack="stickerPack" />
    <BaseSticker14 v-if="glyphCipherCode === 14" :stickerPack="stickerPack" />
    <BaseSticker15 v-if="glyphCipherCode === 15" :stickerPack="stickerPack" />
    <BaseSticker16 v-if="glyphCipherCode === 16" :stickerPack="stickerPack" />
    <BaseSticker17 v-if="glyphCipherCode === 17" :stickerPack="stickerPack" />
    <BaseSticker18 v-if="glyphCipherCode === 18" :stickerPack="stickerPack" />
    <BaseSticker19 v-if="glyphCipherCode === 19" :stickerPack="stickerPack" />
    <BaseSticker20 v-if="glyphCipherCode === 20" :stickerPack="stickerPack" />
    <BaseSticker21 v-if="glyphCipherCode === 21" :stickerPack="stickerPack" />
    <BaseSticker22 v-if="glyphCipherCode === 22" :stickerPack="stickerPack" />
    <BaseSticker23 v-if="glyphCipherCode === 23" :stickerPack="stickerPack" />
    <BaseSticker24 v-if="glyphCipherCode === 24" :stickerPack="stickerPack" />
    <BaseSticker25 v-if="glyphCipherCode === 25" :stickerPack="stickerPack" />
    <BaseSticker26 v-if="glyphCipherCode === 26" :stickerPack="stickerPack" />
    <BaseSticker27 v-if="glyphCipherCode === 27" :stickerPack="stickerPack" />
    <BaseSticker28 v-if="glyphCipherCode === 28" :stickerPack="stickerPack" />
    <BaseSticker29 v-if="glyphCipherCode === 29" :stickerPack="stickerPack" />
    <BaseSticker30 v-if="glyphCipherCode === 30" :stickerPack="stickerPack" />
    <BaseSticker31 v-if="glyphCipherCode === 31" :stickerPack="stickerPack" />
    <BaseSticker32 v-if="glyphCipherCode === 32" :stickerPack="stickerPack" />
    <BaseSticker33 v-if="glyphCipherCode === 33" :stickerPack="stickerPack" />
    <BaseSticker34 v-if="glyphCipherCode === 34" :stickerPack="stickerPack" />
    <BaseSticker35 v-if="glyphCipherCode === 35" :stickerPack="stickerPack" />
    <BaseSticker36 v-if="glyphCipherCode === 36" :stickerPack="stickerPack" />
    <BaseSticker37 v-if="glyphCipherCode === 37" :stickerPack="stickerPack" />
    <BaseSticker38 v-if="glyphCipherCode === 38" :stickerPack="stickerPack" />
    <BaseSticker39 v-if="glyphCipherCode === 39" :stickerPack="stickerPack" />
    <BaseSticker40 v-if="glyphCipherCode === 40" :stickerPack="stickerPack" />
    <span
      class="cipher-text-character__letter"
      v-if="character.encryptionState === 'decrypted'"
      :class="{
        'cipher-text-character__letter--is-decrypted':
          character.encryptionState === 'decrypted'
      }"
      >{{ character.glyph || _ }}</span
    >
    <span
      class="cipher-text-character__letter"
      v-else-if="character.selected === true"
    >
      ?
    </span>
    <span class="cipher-text-character__letter" v-else></span>
  </li>
</template>

<script>
export default {
  name: "CipherTextCharacter",
  props: {
    character: {
      type: Object,
      required: true
    },
    glyphCipherCode: {
      type: Number,
      required: true
    },
    stickerPack: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$emit("stickerAdded");
  },
  destroyed() {
    this.$emit("stickerRemoved");
  }
};
</script>

<style lang="scss">
.cipher-text-character {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.2s ease-in-out;
  @include responsive(small) {
    margin-bottom: 0.25rem;
    padding: 0.125rem;
  }
  @include responsive(medium) {
    margin-bottom: 0.5rem;
    padding: 0.125rem;
  }
  @include responsive(large) {
    margin-bottom: 0.75rem;
    padding: 0.125rem;
  }
}
@media (hover: hover) {
  .cipher-text-character:hover {
    transform: scale(1.1);
  }
}
.cipher-text-character > .sticker {
  width: 47px;
  height: 47px;
  overflow: visible; // Prevent clipping of drop shadow when in selected state.
  opacity: 1;
  transition: all 1s ease-in-out;
  z-index: 10;
  @include responsive(small) {
    width: 64px;
    height: 64px;
  }
  @include responsive(medium) {
    width: 66px;
    height: 66px;
  }
  @include responsive(large) {
    width: 76px;
    height: 76px;
  }
  @include responsive(x-large) {
    width: 86px;
    height: 86px;
  }
}
.cipher-text-character--is-selected > .sticker {
  filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.5));
  animation: bounce 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
}
.cipher-text-character--is-decrypted {
  // background-color: transparent;
}
.cipher-text-character--is-decrypted > .sticker {
  opacity: 0.5;
  filter: grayscale(100%);
}
.cipher-text-character--is-decryption-error {
  // animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: shake 0.2s;
  animation-iteration-count: 3;
  // animation-delay: 0.2s;
}
.cipher-text-character__letter {
  width: 2rem;
  height: 2rem;
  margin: 0.25rem 0 0.5rem;
  padding: 0.3rem 0.25rem 0.2rem;
  background: none;
  border-radius: 50%;
  font-size: 1.25rem;
  font-weight: 500;
  // text-transform: capitalize;
  line-height: 0;
  transition: all 0.41s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive(small) {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.125rem 0 0.75rem;
    font-size: 1.5rem;
  }
}
.cipher-text-character--is-selected .cipher-text-character__letter {
  background: rgba(#353741, 1);
  // -webkit-backdrop-filter: $backdrop-blur;
  // backdrop-filter: $backdrop-blur;
  color: #fff;
}
.cipher-text-character--is-decrypted .cipher-text-character__letter {
  background: rgba(#fff, 0.75);
  // -webkit-backdrop-filter: $backdrop-blur;
  // backdrop-filter: $backdrop-blur;
  color: $base-text-color;
}
// Collapse letters in Editor view
.editor .cipher-text-character__letter {
  width: 0;
  height: 0;
}
// Expand letters in Editor view
.editor .cipher-text-character--is-decrypted .cipher-text-character__letter {
  width: 2rem;
  height: 2rem;
  @include responsive(small) {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.cipher-text-character__letter--is-decrypted {
  animation: throb 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 0.2s;
  transform: scale(1);
  backface-visibility: hidden;
  perspective: 1000px;
}
.message--is-decrypted .cipher-text-character > .sticker {
  opacity: 0.25;
}
.message--is-decrypted .cipher-text-character__letter {
  background: rgba(#fff, 0.85);
}

@media print {
  .cipher-text-character__input::placeholder {
    color: transparent;
  }
}
</style>
