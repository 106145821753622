<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="print-icon"
      d="M18,18 L18,20 C18,21.1045695 17.1045695,22 16,22 L8,22 C6.8954305,22 6,21.1045695 6,20 L6,18 L4,18 C2.8954305,18 2,17.1045695 2,16 L2,10 C2,8.9 2.9,8 4,8 L6,8 L6,4 C6,2.9 6.9,2 8,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,8 L20,8 C21.1045695,8 22,8.8954305 22,10 L22,16 C22,17.1045695 21.1045695,18 20,18 L18,18 Z M18,16 L20,16 L20,10 L4,10 L4,16 L6,16 L6,14 C6,12.9 6.9,12 8,12 L16,12 C17.1045695,12 18,12.8954305 18,14 L18,16 Z M16,8 L16,4 L8,4 L8,8 L16,8 Z M8,14 L8,20 L16,20 L16,14 L8,14 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BasePrintIcon"
};
</script>

<style>
.print-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
