<template>
  <div class="block-user-already-blocked">
    <BaseErrorMessage>
      <template #title>Error</template>
      <template #description>
        <p>This sender is already blocked.</p>
      </template>
    </BaseErrorMessage>
    <BaseButtonGroup>
      <BaseButton primary pad @click.native="close">Done</BaseButton>
    </BaseButtonGroup>
  </div>
</template>

<script>
export default {
  name: "BlockUserAlreadyBlocked",
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
