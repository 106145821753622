<template>
  <div class="neutral-message">
    <h3 class="neutral-message__title" v-if="$slots.title">
      <slot name="title" />
    </h3>
    <div class="neutral-message__description" v-if="$slots.description">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseNeutralMessage"
};
</script>

<style lang="scss">
.neutral-message {
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.neutral-message__title {
  font-size: 1.5rem;
  font-weight: 600;
  @include responsive(small) {
    font-size: 1.5rem;
  }
}
.neutral-message__description {
  color: #74778a;
}
.neutral-message__description p {
  margin: 0.5rem 0;
}
</style>
