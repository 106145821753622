<template>
  <img
    class="app-logo"
    src="https://res.cloudinary.com/dv5har4fh/image/upload/v1609858537/StickerCode/beta_logo.png"
    width="166"
    height="40"
    alt="StickerCode logo"
  />
</template>

<script>
export default {
  name: "AppLogo"
};
</script>

<style lang="scss">
.app-logo {
  width: 166px;
  height: 40px;
}
</style>
