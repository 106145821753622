<template>
  <div class="block-user-confirm">
    <BaseCard>
      <template #title>Block sender</template>
      <div class="block-user-confirm__body">
        <p>
          Are you sure you want to block {{ message.senderName }} ({{
            message.senderEmail
          }})?
        </p>
        <p>
          If you change your mind later, you can visit your Account to undo this
          action.
        </p>
      </div>
      <BaseButtonGroup>
        <BaseButton secondary pad @click.native="cancel">Cancel</BaseButton>
        <BaseButton
          primary
          pad
          :loading="loading"
          type="submit"
          @click.native="blockUser"
        >
          Block
        </BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createBlockedUser } from "@/graphql/mutations";

export default {
  name: "BlockUserConfirm",
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async blockUser() {
      this.loading = true;
      // NB: Users must be authenticated in order to be able to block another user.
      try {
        const user = await Auth.currentAuthenticatedUser();
        // Build a blockedUser data object
        const blockedUser = {
          blockedUserId: this.message.senderId,
          blockedUserName: this.message.senderName,
          blockedUserEmail: this.message.senderEmail,
          blockingUserId: user.attributes.sub,
          // blockingUserName: user.attributes.name,
          blockingUserEmail: user.attributes.email
        };
        // console.log(blockedUser);
        try {
          // Attempt to add a blockedUser record in backend.
          const data = await API.graphql(
            graphqlOperation(createBlockedUser, { input: blockedUser })
          );
          // console.log(data);
          this.$emit("blockSuccess");
          return data;
        } catch (error) {
          // Check whether user has already been blocked (indicated by 'DynamoDB:ConditionalCheckFailedException' error)
          if (
            // TODO: Loop through ALL errors to check for this exception
            error.errors[0].errorType ===
            "DynamoDB:ConditionalCheckFailedException"
          ) {
            // console.log(error.errors[0].errorType);
            this.$emit("userAlreadyBlocked");
          } else {
            this.$emit("blockError");
          }
        }
      } catch (error) {
        this.$router.push("/auth");
        return error;
      }
    }
  }
};
</script>

<style lang="scss">
.block-user-confirm {
}
.block-user-confirm__body {
  margin: 1rem 0;
}
.block-user-confirm__body > p {
  margin: 0.5rem 0;
}
</style>
