<template>
  <PageView>
    <AccountEssentials
      v-if="activeComponent === 'AccountEssentials'"
      :user="user"
      @signOut="signOut"
    />
    <NotificationSettings
      v-if="activeComponent === 'NotificationSettings'"
      :user="user"
    />
    <BlockedSenders
      v-if="activeComponent === 'AccountEssentials'"
      :user="user"
    />
    <MessageHistory v-if="activeComponent === 'MessageHistory'" :user="user" />
    <DownloadContent
      v-if="activeComponent === 'DownloadContent'"
      :user="user"
    />
    <DeleteAccount v-if="activeComponent === 'DeleteAccount'" :user="user" />
    <Loading v-if="activeComponent === 'Loading'" />
  </PageView>
</template>

<script>
import Loading from "@/components/Loading";
import PageView from "@/components/PageView";
import AccountEssentials from "@/components/account/AccountEssentials";
import NotificationSettings from "@/components/account/NotificationSettings";
import BlockedSenders from "@/components/account/BlockedSenders";
import MessageHistory from "@/components/account/MessageHistory";
import DownloadContent from "@/components/account/DownloadContent";
import DeleteAccount from "@/components/account/DeleteAccount";
import Auth from "@aws-amplify/auth";

export default {
  name: "Account",
  components: {
    Loading,
    PageView,
    AccountEssentials,
    NotificationSettings,
    BlockedSenders,
    MessageHistory,
    DownloadContent,
    DeleteAccount
  },
  data() {
    return {
      activeComponent: "Loading",
      user: null
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.activeComponent = "Loading";
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.activeComponent = "AccountEssentials";
        return (this.user = user);
      } catch (error) {
        // TODO: add a generic error component in case of failure to get user
        return error;
      }
    },
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        return error;
      }
    }
  }
};
</script>
