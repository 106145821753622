import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// Integrate AWS Amplify
import API from "@aws-amplify/api";
import PubSub from "@aws-amplify/pubsub";
import Auth from "@aws-amplify/auth";
import awsconfig from "./aws-exports";

// console.log("VUE_APP_ROOT_URL: " + process.env.VUE_APP_ROOT_URL);

// Workaround for Amplify OAuth bug (https://github.com/aws-amplify/amplify-cli/issues/2792#issuecomment-671944193)
awsconfig.oauth.redirectSignIn = process.env.VUE_APP_ROOT_URL;
awsconfig.oauth.redirectSignOut = process.env.VUE_APP_ROOT_URL;

API.configure(awsconfig);
PubSub.configure(awsconfig);
Auth.configure(awsconfig);

// Use vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// Service worker
import "./registerServiceWorker";

// Automatic Global Registration of Base Components (https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components)
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
const requireComponent = require.context(
  // The relative path of the components folder
  "./components",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
