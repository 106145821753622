<template>
  <div class="message-in-review">
    <BaseCard>
      <BaseErrorMessage>
        <template #title>
          Message unavailable
        </template>
        <template #description>
          This message is currently being reviewed.
        </template>
      </BaseErrorMessage>
      <div class="message-in-review__actions">
        <BaseButton primary pad @click.native="done">Done</BaseButton>
      </div>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "MessageInReview",
  methods: {
    done() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
.message-in-review {
  height: 100vh;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-in-review__actions {
  margin: 1rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.message-in-review__actions .button {
  margin-left: 0.5rem;
}
</style>
