<template>
  <div class="loading">
    <div class="loading__content">
      <!-- <div class="loading__label">
        Loading
      </div> -->
      <div class="loading__spinner">
        <BaseSpinner />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style lang="scss">
.loading {
  min-height: 8rem;
  // background-color: #f1f2f3;
  // border-radius: 0.5rem;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.loading__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loading__label {
  font-size: 1rem;
  color: #8b8e9e;
}
.loading__spinner {
  width: 24px;
  height: 24px;
  margin-left: 1rem;
  color: #8b8e9e;
}
</style>
