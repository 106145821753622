<template>
  <PageView
    class="message"
    :class="{
      'message--is-decrypted': isMessageDecrypted()
    }"
  >
    <template #pageViewHeaderRight>
      <div class="message__header-group">
        <MessageActions
          @reportMessage="reportMessage"
          @blockSender="blockSender"
        />
      </div>
    </template>
    <transition name="fade">
      <SecretKeyChallenge
        v-if="message.secretKey && !secretKeySubmitted"
        @submit="checkSecretKey"
      />
    </transition>
    <Cipher
      v-if="!message.secretKey || secretKeySubmitted"
      :cipher="cipher"
      :stickerPack="message.stickerPack"
      @selectCipherCharacter="updateSelectedCipherCharacterAndCheckForMatch"
    />
    <transition name="fade">
      <div class="message__decoding-tip" v-show="showDecodingTip">
        <div class="message__decoding-tip-content">
          Select the matching sticker below to reveal each encoded character.
        </div>
        <div class="message__decoding-tip-actions">
          <BaseButton
            primary
            pad
            small
            fullWidth
            @click.native="closeDecodingTip()"
          >
            Got it
          </BaseButton>
        </div>
      </div>
    </transition>
    <CipherText
      class="message__cipher-text"
      :messageChars="messageChars"
      :cipher="cipher"
      :stickerPack="message.stickerPack"
      @selectCipherTextCharacter="updateSelectedCipherTextCharacter"
    />
    <transition name="fade">
      <MessageDeciphered
        v-if="showMessageDecipheredModal"
        :message="message"
        @close="closeMessageDecipheredModal"
        @reply="reply"
      />
    </transition>
  </PageView>
</template>

<script>
import PageView from "@/components/PageView";
import MessageActions from "@/components/MessageActions";
import Cipher from "@/components/Cipher";
import SecretKeyChallenge from "@/components/SecretKeyChallenge";
import CipherText from "@/components/CipherText";
import MessageDeciphered from "@/components/MessageDeciphered";
import API, { graphqlOperation } from "@aws-amplify/api";
import { updateMessage } from "@/graphql/mutations";
import { Howl } from "howler";
// Setup howler sounds
const messageUnlockSuccessSound = new Howl({
  src: [
    "https://res.cloudinary.com/dv5har4fh/video/upload/v1603190859/StickerCode/audio/messageUnlockSuccess.mp3"
  ]
});
const messageUnlockFailureSound = new Howl({
  src: [
    "https://res.cloudinary.com/dv5har4fh/video/upload/v1603190859/StickerCode/audio/messageUnlockSuccess.mp3"
  ]
});
const messageDecodedSound = new Howl({
  src: [
    "https://res.cloudinary.com/dv5har4fh/video/upload/v1603190885/StickerCode/audio/messageDecodeSuccess.mp3"
  ]
});
const stickerDecodeSuccessSound = new Howl({
  src: [
    "https://res.cloudinary.com/dv5har4fh/video/upload/v1603059762/StickerCode/audio/stickerDecodeSuccess.mp3"
  ]
});
const stickerDecodeFailureSound = new Howl({
  src: [
    "https://res.cloudinary.com/dv5har4fh/video/upload/v1603104327/StickerCode/audio/stickerDecodeFailure.mp3"
  ]
});
export default {
  name: "Message",
  components: {
    PageView,
    // PrimaryNav,
    MessageActions,
    Cipher,
    SecretKeyChallenge,
    CipherText,
    MessageDeciphered
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      secretKeySubmitted: false,
      showDecodingTip: true,
      showMessageDecipheredModal: false,
      decodeAllCharacterInstances: true,
      messageChars: [],
      selectedCipherCharIndex: null,
      selectedCipherTextCharacterIndex: null,
      cipher: [
        { glyph: "a", code: 1, selected: false },
        { glyph: "b", code: 2, selected: false },
        { glyph: "c", code: 3, selected: false },
        { glyph: "d", code: 4, selected: false },
        { glyph: "e", code: 5, selected: false },
        { glyph: "f", code: 6, selected: false },
        { glyph: "g", code: 7, selected: false },
        { glyph: "h", code: 8, selected: false },
        { glyph: "i", code: 9, selected: false },
        { glyph: "j", code: 10, selected: false },
        { glyph: "k", code: 11, selected: false },
        { glyph: "l", code: 12, selected: false },
        { glyph: "m", code: 13, selected: false },
        { glyph: "n", code: 14, selected: false },
        { glyph: "o", code: 15, selected: false },
        { glyph: "p", code: 16, selected: false },
        { glyph: "q", code: 17, selected: false },
        { glyph: "r", code: 18, selected: false },
        { glyph: "s", code: 19, selected: false },
        { glyph: "t", code: 20, selected: false },
        { glyph: "u", code: 21, selected: false },
        { glyph: "v", code: 22, selected: false },
        { glyph: "w", code: 23, selected: false },
        { glyph: "x", code: 24, selected: false },
        { glyph: "y", code: 25, selected: false },
        { glyph: "z", code: 26, selected: false },
        { glyph: ".", code: 27, selected: false },
        { glyph: "?", code: 28, selected: false },
        { glyph: "!", code: 29, selected: false },
        { glyph: "_", code: 30, selected: false },
        { glyph: "0", code: 31, selected: false },
        { glyph: "1", code: 32, selected: false },
        { glyph: "2", code: 33, selected: false },
        { glyph: "3", code: 34, selected: false },
        { glyph: "4", code: 35, selected: false },
        { glyph: "5", code: 36, selected: false },
        { glyph: "6", code: 37, selected: false },
        { glyph: "7", code: 38, selected: false },
        { glyph: "8", code: 39, selected: false },
        { glyph: "9", code: 40, selected: false }
      ]
    };
  },
  async created() {
    this.checkOnboardingStatus();
    this.generateCipher();
    this.getMessageChars();
    this.selectFirstCharacter();
  },
  methods: {
    checkOnboardingStatus() {
      let dismissed = localStorage.getItem("StickerCodeDecodingTipDismissed");
      // If a user has previously dismissed the decoding tip then don't show it again.
      if (dismissed) {
        this.showDecodingTip = false;
      }
    },
    playMessageUnlockSuccessSound() {
      messageUnlockSuccessSound.play();
    },
    playMessageUnlockFailureSound() {
      messageUnlockFailureSound.play();
    },
    playMessageDecodedSound() {
      messageDecodedSound.play();
    },
    playStickerDecodeSuccessSound() {
      stickerDecodeSuccessSound.play();
    },
    playStickerDecodeFailureSound() {
      stickerDecodeFailureSound.play();
    },
    checkSecretKey(submittedKey) {
      if (this.message.secretKey === submittedKey) {
        this.playMessageUnlockSuccessSound();
        this.secretKeySubmitted = true;
      } else {
        // TODO: play error sound and increment count of unsuccessful submissions
      }
    },
    closeMessageDecipheredModal() {
      this.showMessageDecipheredModal = false;
    },
    closeDecodingTip() {
      this.showDecodingTip = false;
      localStorage.setItem("StickerCodeDecodingTipDismissed", true);
    },
    reportMessage() {
      this.$router.push("/report/" + this.message.id);
    },
    blockSender() {
      this.$router.push("/block/" + this.message.id);
    },
    async updateMessage() {
      // Build a message data object
      const message = {
        id: this.message.id,
        decodedAt: Date.now()
      };
      try {
        // Attempt to update message in backend.
        const data = await API.graphql(
          graphqlOperation(updateMessage, { input: message })
        );
        // console.log(data);
        return data;
      } catch (error) {
        return error;
      }
    },
    getMessageChars() {
      let lowercaseString = this.message.content.toLowerCase();
      // Replace all spaces, tabs, new lines and carriage returns with underscores
      let parsedString = lowercaseString.replace(/\s/g, "_");
      let messageChars = [];
      // Use spread operator to create array of characters from message
      [...parsedString].forEach(glyph => {
        let character = {
          glyph: glyph,
          encryptionState: "encrypted",
          selected: false
        };
        messageChars.push(character);
      });
      return (this.messageChars = messageChars);
    },
    reply() {
      // Pass message as a route prop so that we can populate name/email of recipient
      this.$router.push({
        name: "write",
        params: {
          senderName: this.message.senderName,
          senderEmail: this.message.senderEmail
        }
      });
    },
    // Update the cipher based on the secret key (if there is one)
    generateCipher() {
      const secretKey = this.message.secretKey;
      let offset = secretKey.length + 1;
      this.cipher.forEach((character, i) => {
        if (secretKey.includes(character.glyph)) {
          let index = secretKey.indexOf(character.glyph);
          this.cipher[i].code = index + 1;
        } else {
          this.cipher[i].code = offset;
          offset++;
        }
      });
    },
    selectFirstCharacter() {
      this.selectCipherTextCharacter(0);
    },
    selectNextCharacter(currentCharIndex) {
      let targetChar;
      if (currentCharIndex + 1 < this.messageChars.length) {
        targetChar = currentCharIndex + 1;
      } else {
        targetChar = 0;
      }
      // If the character has already been decrypted, we should try the next one
      if (this.isCharacterEncrypted(targetChar)) {
        return this.updateSelectedCipherTextCharacter(targetChar);
      } else return this.selectNextCharacter(targetChar);
    },
    isCharacterEncrypted(charIndex) {
      if (this.messageChars[charIndex].encryptionState === "encrypted") {
        return true;
      } else return false;
    },
    async checkForMatch(cipherCharIndex) {
      const cipherCharacter = this.cipher[cipherCharIndex];
      const charIndex = this.selectedCipherTextCharacterIndex;
      const cipherTextCharacter = this.messageChars[charIndex];
      // Set state to encrypted in case we've already had a decryption error (this will reset animations)
      cipherTextCharacter.encryptionState = "encrypted";
      // Do the selected cipher character and cipher text character match?
      if (cipherCharacter.glyph === cipherTextCharacter.glyph) {
        // Decrypt the character/s
        await this.decryptCharacter(cipherTextCharacter, cipherCharacter);
        // Deselect the currently selected cipher character
        this.deselectCipherCharacter();
      } else {
        // No match!
        // Set state to decryptionError so we can apply an animation to the cipher text character
        cipherTextCharacter.encryptionState = "decryptionError";
        this.playStickerDecodeFailureSound();
        // Pulse the vibration hardware on the device, if such hardware exists.
        // window.navigator.vibrate(200);
        // Deselect the cipher character and don't select next cipher text character
        this.deselectCipherCharacter();
        return;
      }
      if (this.isMessageDecrypted()) {
        // The whole message has been decrypted!
        // Deselect currently selected character
        this.deselectCipherTextCharacter();
        // Mark the message as decrypted in the backend
        const result = this.updateMessage(); // TODO: Should we use a Lambda function instead to update the message so signed out users don't have access to message model?
        console.log(result);
        setTimeout(() => {
          this.playMessageDecodedSound();
          this.showMessageDecipheredModal = true;
        }, 1000);
      } else {
        // The message is not yet decrypted so select the next encrypted character
        return this.selectNextCharacter(charIndex);
      }
    },
    decryptCharacter(cipherTextCharacter, cipherCharacter) {
      this.playStickerDecodeSuccessSound();
      // Set the selected cipher text character encryption state to decrypted
      cipherTextCharacter.encryptionState = "decrypted";
      // If decodeAllCharacterInstances is true, decrypt all character instances
      if (this.decodeAllCharacterInstances === true) {
        this.messageChars.forEach(character => {
          if (character.glyph === cipherCharacter.glyph) {
            character.encryptionState = "decrypted";
          }
        });
      }
    },
    isMessageDecrypted() {
      if (this.messageChars.length < 1) return false;
      if (
        this.messageChars.every(
          character => character.encryptionState === "decrypted"
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    updateSelectedCipherCharacterAndCheckForMatch(cipherCharIndex) {
      this.deselectCipherCharacter();
      this.selectCipherCharacter(cipherCharIndex);
      return this.checkForMatch(cipherCharIndex);
    },
    selectCipherCharacter(cipherCharIndex) {
      this.selectedCipherCharIndex = cipherCharIndex;
      this.cipher[cipherCharIndex].selected = true;
    },
    deselectCipherCharacter() {
      const cipherCharIndex = this.selectedCipherCharIndex;
      if (cipherCharIndex !== null) {
        this.cipher[cipherCharIndex].selected = false;
      }
    },
    updateSelectedCipherTextCharacter(charIndex) {
      // Deselect any previously selected character
      this.deselectCipherTextCharacter();
      // Select the newly identified character
      this.selectCipherTextCharacter(charIndex);
    },
    deselectCipherTextCharacter() {
      const charIndex = this.selectedCipherTextCharacterIndex;
      if (charIndex !== null) {
        this.messageChars[charIndex].selected = false;
      }
    },
    selectCipherTextCharacter(charIndex) {
      setTimeout(() => {
        this.selectedCipherTextCharacterIndex = charIndex;
        this.messageChars[charIndex].selected = true;
      }, 750);
    }
  }
};
</script>

<style lang="scss">
.message {
  margin: 0 auto;
  padding: 1rem;
  @include responsive(small) {
    padding: 1.5rem 2rem;
  }
}
.message__header-group {
  display: flex;
  justify-content: flex-start;
}
.message__cipher-text {
  min-height: calc(100vh - 16rem);
  display: flex;
  align-items: center;
  position: relative;
}
.message__cipher-text .cipher-text__characters {
  margin-bottom: 3.5rem;
  padding-top: 3.25rem;
  @include responsive(medium) {
    margin-bottom: 4rem;
  }
}
.message__decoding-tip {
  width: 18rem;
  padding: 1.5rem 1.5rem;
  background: $background-dark-linear;
  border-radius: 0.5rem;
  color: #fff;
  position: fixed;
  right: calc(50% - 9rem);
  bottom: 8rem;
  z-index: 30;
  @include responsive(small) {
    bottom: 10rem;
  }
}
.message__decoding-tip-content {
  margin-bottom: 1.25rem;
}
.message__decoding-tip-content p {
  margin-bottom: 0.75rem;
}
.message__decoding-tip-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media print {
  .message__header {
    display: none;
  }
  .message__decoding-tip {
    display: none;
  }
}
</style>
