<template>
  <ul class="identity-provider-auth">
    <div class="identity-provider-auth__header">
      <img
        class="identity-provider-auth__logo"
        src="https://res.cloudinary.com/dv5har4fh/image/upload/v1611052401/StickerCode/beta-logo-large.png"
      />
    </div>
    <!-- Auth options for iOS app -->
    <template v-if="platform === 'ios'">
      <li class="identity-provider-auth__option">
        <!-- Sign in with Apple (iOS only) -->
        <BaseButton primary pad @click.native="iosSignInWithApple">
          <template #icon>
            <BaseAppleLogoIcon />
          </template>
          Sign in with Apple
        </BaseButton>
      </li>
      <li class="identity-provider-auth__option">
        <!-- Sign in with Google (iOS only) -->
        <BaseButton secondary pad @click.native="iosSignInWithGoogle">
          <template #icon>
            <BaseGoogleLogoIcon />
          </template>
          Sign in with Google
        </BaseButton>
      </li>
    </template>
    <!-- Auth options for Android app -->
    <template v-if="platform === 'android'">
      <li class="identity-provider-auth__option">
        <!-- Sign in with Google (Android only) -->
        <BaseButton secondary pad @click.native="androidSignInWithGoogle">
          <template #icon>
            <BaseGoogleLogoIcon />
          </template>
          Sign in with Google
        </BaseButton>
      </li>
      <li class="identity-provider-auth__option">
        <!-- Sign in with Apple (Android only) -->
        <BaseButton primary pad @click.native="androidSignInWithApple">
          <template #icon>
            <BaseAppleLogoIcon />
          </template>
          Sign in with Apple
        </BaseButton>
      </li>
    </template>
    <!-- Auth options for web app -->
    <template v-if="platform === 'web'">
      <li class="identity-provider-auth__option">
        <!-- Sign in with Apple (web) -->
        <BaseButton
          primary
          pad
          :loading="loadingApple"
          @click.native="signInWithApple"
        >
          <template #icon>
            <BaseAppleLogoIcon />
          </template>
          Sign in with Apple
        </BaseButton>
      </li>
      <li class="identity-provider-auth__option">
        <!-- Sign in with Google (web) -->
        <BaseButton
          secondary
          pad
          :loading="loadingGoogle"
          @click.native="signInWithGoogle"
        >
          <template #icon>
            <BaseGoogleLogoIcon />
          </template>
          Sign in with Google
        </BaseButton>
      </li>
      <!-- <li class="identity-provider-auth__option">
        <BaseButton
          primary
          pad
          @click.native="signInWithHostedUI"
        >
        <template #icon>
          <BaseEmailIcon />
        </template>
          Sign in with email
        </BaseButton>
      </li> -->
    </template>
  </ul>
</template>

<script>
import Auth from "@aws-amplify/auth";
import { Plugins } from "@capacitor/core";
// import { ResponseSignInWithApplePlugin } from "@capacitor-community/apple-sign-in";
export default {
  name: "IdentityProviderAuth",
  data() {
    return {
      platform: "",
      user: {},
      loadingApple: false,
      loadingGoogle: false
    };
  },
  created() {
    this.getPlatform();
  },
  methods: {
    async getPlatform() {
      const { Device } = Plugins;
      let device = await Device.getInfo();
      // Should return either 'web', 'ios' or 'android'
      return (this.platform = device.platform);
    },
    // For Sign in with Apple on iOS only
    async iosSignInWithApple() {
      const { SignInWithApple } = Plugins;
      try {
        const result = await SignInWithApple.Authorize();
        return (this.user = result.response);
      } catch (error) {
        return error;
      }
    },
    // For Sign in with Apple on Android only
    async androidSignInWithApple() {
      const { SignInWithApple } = Plugins;
      try {
        const result = await SignInWithApple.Authorize();
        return (this.user = result.response);
      } catch (error) {
        return error;
      }
    },
    // For Sign in with Apple on web only
    async signInWithApple() {
      try {
        this.loadingApple = true;
        return await Auth.federatedSignIn({ provider: "SignInWithApple" });
      } catch (error) {
        this.loadingApple = false;
        // console.log("Error signing in with Apple: ", error);
        return error;
      }
    },
    // For Sign in with Google on iOS only
    async iosSignInWithGoogle() {
      try {
        //
      } catch (error) {
        // console.log("Error signing in with Google: ", error);
        return error;
      }
    },
    // For Sign in with Google on Android only
    async androidSignInWithGoogle() {
      try {
        //
      } catch (error) {
        // console.log("Error signing in with Google: ", error);
        return error;
      }
    },
    // For Sign in with Google on web only
    async signInWithGoogle() {
      try {
        this.loadingGoogle = true;
        return await Auth.federatedSignIn({ provider: "Google" });
      } catch (error) {
        this.loadingGoogle = false;
        // console.log("Error signing in with Google: ", error);
        return error;
      }
    },
    async signInWithHostedUI() {
      try {
        return await Auth.federatedSignIn();
      } catch (error) {
        // console.log("Error signing in with HostedUI: ", error);
        return error;
      }
    }
  }
};
</script>

<style lang="scss">
.identity-provider-auth {
  min-width: 20rem;
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.identity-provider-auth__header {
  width: 100%;
  margin: 0 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.identity-provider-auth__logo {
  width: 249px;
  height: 60px;
}
.identity-provider-auth__option {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include responsive(small) {
    padding: 0.75rem;
  }
}
</style>
