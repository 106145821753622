<template>
  <div class="message-preview-characters">
    <div class="message-preview-characters__characters">
      <MessagePreviewCharactersCharacter
        v-for="(character, index) in messagePreviewChars"
        :key="index"
        :character="character"
        :glyphCipherCode="glyphCipherCode(character.glyph)"
        :stickerPack="stickerPack"
      />
    </div>
  </div>
</template>

<script>
import MessagePreviewCharactersCharacter from "@/components/MessagePreviewCharactersCharacter";
export default {
  name: "MessagePreviewCharacters",
  components: {
    MessagePreviewCharactersCharacter
  },
  props: {
    messagePreviewChars: {
      type: Array,
      required: true
    },
    cipher: {
      type: Array,
      required: true
    },
    stickerPack: {
      type: String,
      required: true
    }
  },
  methods: {
    // Get the cipher code for a specified glyph
    glyphCipherCode(g) {
      let glyphCipher = this.cipher.find(({ glyph }) => glyph === g);
      return glyphCipher.code;
    }
  }
};
</script>

<style lang="scss">
.message-preview-characters {
  height: 2.75rem;
  padding: 2px 0; // Prevent sticker shadows from being clipped
  overflow: hidden;
  display: flex;
  @include responsive(small) {
    height: 3.25rem;
  }
}
.message-preview-characters__characters {
  display: flex;
  flex-wrap: wrap;
}
</style>
