<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="key-icon"
      d="M11.85,17.56 C11.5689653,17.841385 11.1876907,17.9996499 10.79,17.9999999 L10,17.9999999 L10,18.4999999 C10,19.33 9.33,19.9999999 8.5,19.9999999 L8,19.9999999 L8,20.4999999 C8,21.33 7.33,21.9999999 6.5,21.9999999 L4,21.9999999 C2.8954305,21.9999999 2,21.1045695 2,19.9999999 L2,17.41 C2.00223279,16.8804232 2.21441221,16.3733503 2.59,15.9999999 L8.15,10.44 C7.72772302,8.37753677 8.25199363,6.23438189 9.57867284,4.59975817 C10.905352,2.96513445 12.8947806,2.0111184 15,1.99999987 C17.6747287,1.99948305 20.1159437,3.52321839 21.2904185,5.92629619 C22.4648933,8.32937399 22.1672972,11.1916696 20.5235856,13.3017374 C18.879874,15.4118053 16.1774132,16.4007469 13.56,15.85 L11.86,17.56 L11.85,17.56 Z M12.97,13.61 L13.55,13.79 C15.7444821,14.4531059 18.1087823,13.5388977 19.2862919,11.5719376 C20.4638016,9.6049775 20.1528253,7.08922992 18.5317977,5.46820231 C16.9107701,3.84717469 14.3950225,3.53619838 12.4280624,4.71370805 C10.4611023,5.89121772 9.54689406,8.25551793 10.21,10.45 L10.39,11.03 L4,17.4 L4,20 L6,20 L6,19.5 C6,18.67 6.67,18 7.5,18 L8,18 L8,17.5 C8,16.67 8.67,16 9.5,16 L10.59,16 L12.97,13.61 Z M18,9 C18,9.55228475 17.5522847,10 17,10 C16.4477153,10 16,9.55228475 16,9 C16,8.44771525 15.5522847,8 15,8 C14.4477153,8 14,7.55228475 14,7 C14,6.44771525 14.4477153,6 15,6 C16.6568542,6 18,7.34314575 18,9 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseKeyIcon"
};
</script>

<style>
.key-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
