<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="sent-icon"
      d="M1.95585017,8.0513167 L1.84104815,8.09713715 C1.14591123,8.42343675 1.06033012,9.41419728 1.73520245,9.84366149 L9.325,14.674 L14.1563385,22.2647976 C14.6084061,22.9751895 15.6824087,22.8429737 15.9486833,22.0441498 L22.3126443,2.95226674 C22.5732303,2.1705087 21.8294913,1.42676966 21.0477333,1.68735567 L1.95585017,8.0513167 Z M19.783,4.217 L14.715,19.419 L10.893914,13.412872 L10.8301928,13.3238933 C10.7617999,13.2386622 10.6797386,13.16502 10.587128,13.106086 L4.58,9.284 L19.783,4.217 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseSentIcon"
};
</script>

<style>
.sent-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
