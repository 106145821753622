<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="clock-icon"
      d="M12,2 C17.5228716,2 22,6.47714212 22,12 C22,17.5228847 17.5228847,22 12,22 C6.47714212,22 2,17.5228716 2,12 C2,6.47715525 6.47715525,2 12,2 Z M12,4 C7.58172475,4 4,7.58172475 4,12 C4,16.4183007 7.58171025,20 12,20 C16.4183153,20 20,16.4183153 20,12 C20,7.58171025 16.4183007,4 12,4 Z M12,7 C12.5128358,7 12.9355072,7.38604019 12.9932723,7.88337887 L13,8 L13,11.585 L15.7071068,14.2928932 C16.0675907,14.6533772 16.0953203,15.2206082 15.7902954,15.6128994 L15.7071068,15.7071068 C15.3466228,16.0675907 14.7793918,16.0953203 14.3871006,15.7902954 L14.2928932,15.7071068 L11.2928932,12.7071068 C11.1366129,12.5508265 11.0374017,12.3481451 11.0086724,12.131444 L11,12 L11,8 C11,7.44771525 11.4477153,7 12,7 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseClockIcon"
};
</script>

<style>
.clock-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
