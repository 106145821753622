// Import Capacitor App plugin so we can add an event listener to the appUrlOpen event on the Capacitor App (for deep links).
import { Plugins } from "@capacitor/core";
const { App } = Plugins;

import Vue from "vue";
import VueRouter from "vue-router";

import AmplifyAuth from "@aws-amplify/auth";

import Help from "../views/Help";
import Privacy from "../views/Privacy";
import Terms from "../views/Terms";
import Account from "../views/Account";
import Auth from "../views/Auth";
import Messages from "../views/Messages";
import Read from "../views/Read";
import Write from "../views/Write";
import Report from "../views/Report";
import Block from "../views/Block";
import Contact from "../views/Contact";

Vue.use(VueRouter);

// Configure universal links
App.addListener("appUrlOpen", function(data) {
  // Example url: https://www.stickercode.app/read/abc-123
  // slug = /read/abc-123
  const slug = data.url.split(".app").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug
    });
  }
});

// User must be signed in to view
async function requireSignedIn(to, from, next) {
  try {
    await AmplifyAuth.currentAuthenticatedUser();
    // console.log("User is authenticated");
    next();
  } catch (error) {
    next({
      path: "/auth"
    });
  }
}

// User must be signed out to view
async function requireSignedOut(to, from, next) {
  try {
    await !AmplifyAuth.currentAuthenticatedUser();
    // console.log("User is not authenticated");
    next();
  } catch (error) {
    next({
      path: "/"
    });
  }
}

const routes = [
  {
    // Redirect any other requests
    path: "*",
    redirect: "/"
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    beforeEnter: requireSignedIn
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    beforeEnter: requireSignedOut
  },
  {
    path: "/",
    name: "messages",
    component: Messages,
    beforeEnter: requireSignedIn
  },
  {
    path: "/write",
    name: "write",
    component: Write,
    props: true,
    beforeEnter: requireSignedIn
  },
  {
    path: "/read/:id",
    name: "read",
    component: Read,
    props: true
  },
  {
    path: "/report/:id",
    name: "report",
    component: Report,
    props: true
  },
  {
    path: "/block/:id",
    name: "block",
    component: Block,
    props: true,
    beforeEnter: requireSignedIn
  },
  {
    path: "/help",
    name: "help",
    component: Help
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
