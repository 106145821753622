<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
// import { Plugins, StatusBarStyle, StatusBarAnimation } from "@capacitor/core";
// const { SplashScreen, StatusBar } = Plugins;
export default {
  name: "App",
  created() {
    if (Capacitor.getPlatform() === "ios") {
      // do something
    }
  },
  methods: {
    // configureAppSplashScreen() {
    //   SplashScreen;
    // },
    // configureAppStatusBar() {
    //   StatusBar;
    // }
  }
};
</script>
