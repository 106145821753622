<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="messages-icon"
      d="M19,4 C20.5977025,4 21.9036624,5.24891202 21.9949074,6.82372636 L22,7 L22.0012679,7.89036786 C22.0131869,7.97710009 22.0138704,8.06538165 22.0019407,8.15288205 L22,17 C22,18.5977103 20.7511084,19.903663 19.1762758,19.9949074 L19,20 L5,20 C3.40231088,20 2.09633853,18.7511009 2.00509266,17.176275 L2,17 L2,7 C2,5.40231863 3.2489195,4.09633909 4.82372716,4.00509269 L5,4 L19,4 Z M20,8.237 L12.6086375,13.8077106 C12.4263421,13.94508 12.2122872,14.0106226 12.0003992,14.0091065 C11.7883881,14.0108292 11.5739454,13.9452967 11.3913625,13.8077106 L4,8.237 L4,17 C4,17.5128524 4.38602882,17.9355095 4.88337644,17.9932725 L5,18 L19,18 C19.5128642,18 19.9355112,17.6139861 19.9932727,17.1166268 L20,17 L20,8.237 Z M19,6 L5,6 C4.8222764,6 4.6553814,6.04636227 4.51074962,6.1276522 C4.54501109,6.1471744 4.57729374,6.16867018 4.60863747,6.19228937 L11.9997653,11.7618678 L19.3913625,6.19228937 L19.4892581,6.12764953 C19.3767298,6.06440442 19.2507237,6.02230217 19.1166244,6.00672754 L19,6 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseMessagesIcon"
};
</script>

<style>
.messages-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
