<template>
  <div class="page-view">
    <PageViewHeader>
      <template>
        <slot name="pageViewHeaderRight"></slot>
      </template>
    </PageViewHeader>
    <div class="page-view__body">
      <slot />
    </div>
  </div>
</template>

<script>
import PageViewHeader from "@/components/PageViewHeader";

export default {
  name: "PageView",
  components: {
    PageViewHeader
  }
};
</script>

<style lang="scss">
.page-view {
  margin-top: 3.25rem;
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include responsive(small) {
    padding: 1rem 2rem;
  }
}
.page-view__body {
  width: 100%;
}
</style>
