<template>
  <div class="message-received">
    <div class="message-received__message-decode-status">
      <div class="message-received__message-decode-status-badge">
        <span
          class="message-received__message-in-review-indicator"
          v-if="message.inReview"
          title="In review"
        />
        <BaseTickIcon v-else-if="message.decodedAt" />
        <span
          class="message-received__message-unread-indicator"
          v-else
          title="Unread"
        />
      </div>
    </div>
    <div class="message-received__message-details" @click="readMessage">
      <div class="message-received__primary-message-details">
        <div class="message-received__message-metadata">
          <div class="message-received__message-metadata-group">
            <div class="message-received__sender-name">
              {{ message.senderName }}
            </div>
            <div class="message-received__sender-email">
              {{ message.senderEmail }}
            </div>
          </div>
          <div class="message-received__message-sent-at">
            {{ dateTimeSent }}
          </div>
        </div>
        <div class="message-received__message-content">
          <MessagePreviewCharacters
            v-if="!message.decodedAt"
            :messagePreviewChars="messagePreviewChars"
            :cipher="cipher"
            :stickerPack="message.stickerPack"
          />
          <div
            class="message-received__message-decoded"
            v-if="message.decodedAt"
          >
            <div class="message-received__message-plaintext">
              {{ message.content }}
            </div>
          </div>
          <!-- <div class="message-received__message-expiry" v-if="expiresSoon">
            <div class="message-received__message-expiry-icon">
              <BaseClockIcon />
            </div>
            <div class="message-received__message-expiry-date">
              {{ dateTimeExpiry }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="message-received__message-actions">
      <MessageReceivedActions
        @readMessage="readMessage"
        @replyMessage="replyMessage"
        @reportMessage="reportMessage"
        @blockSender="blockSender"
      />
    </div>
  </div>
</template>

<script>
import MessagePreviewCharacters from "@/components/MessagePreviewCharacters";
import MessageReceivedActions from "@/components/MessageReceivedActions";
const { DateTime, Duration } = require("luxon");

export default {
  name: "MessageReceived",
  components: {
    MessagePreviewCharacters,
    MessageReceivedActions
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      messagePreviewChars: [],
      cipher: [
        { glyph: "a", code: 1 },
        { glyph: "b", code: 2 },
        { glyph: "c", code: 3 },
        { glyph: "d", code: 4 },
        { glyph: "e", code: 5 },
        { glyph: "f", code: 6 },
        { glyph: "g", code: 7 },
        { glyph: "h", code: 8 },
        { glyph: "i", code: 9 },
        { glyph: "j", code: 10 },
        { glyph: "k", code: 11 },
        { glyph: "l", code: 12 },
        { glyph: "m", code: 13 },
        { glyph: "n", code: 14 },
        { glyph: "o", code: 15 },
        { glyph: "p", code: 16 },
        { glyph: "q", code: 17 },
        { glyph: "r", code: 18 },
        { glyph: "s", code: 19 },
        { glyph: "t", code: 20 },
        { glyph: "u", code: 21 },
        { glyph: "v", code: 22 },
        { glyph: "w", code: 23 },
        { glyph: "x", code: 24 },
        { glyph: "y", code: 25 },
        { glyph: "z", code: 26 },
        { glyph: ".", code: 27 },
        { glyph: "?", code: 28 },
        { glyph: "!", code: 29 },
        { glyph: "_", code: 30 },
        { glyph: "0", code: 31 },
        { glyph: "1", code: 32 },
        { glyph: "2", code: 33 },
        { glyph: "3", code: 34 },
        { glyph: "4", code: 35 },
        { glyph: "5", code: 36 },
        { glyph: "6", code: 37 },
        { glyph: "7", code: 38 },
        { glyph: "8", code: 39 },
        { glyph: "9", code: 40 }
      ]
    };
  },
  computed: {
    dateTimeSent() {
      const dt = DateTime.fromISO(this.message.createdAt);
      return dt.toLocaleString();
    },
    durationSinceSent() {
      const dt = Duration.fromISO(this.message.createdAt);
      return dt;
    },
    dateTimeDecoded() {
      const dt = DateTime.fromISO(this.message.decodedAt);
      return dt.toLocaleString();
    },
    dateTimeExpiry() {
      const dt = DateTime.fromMillis(this.message.expiration);
      return dt.toLocaleString();
    }
  },
  created() {
    this.generateCipher();
    this.getMessagePreviewCharactersChars();
  },
  methods: {
    readMessage() {
      this.$router.push("/read/" + this.message.id);
    },
    replyMessage() {
      // Pass sender name and email as route params so that we can auto-populate name/email of recipient
      this.$router.push({
        name: "write",
        params: {
          senderName: this.message.senderName,
          senderEmail: this.message.senderEmail
        }
      });
    },
    reportMessage() {
      this.$router.push("/report/" + this.message.id);
    },
    blockSender() {
      this.$router.push("/block/" + this.message.id);
    },
    getMessagePreviewCharactersChars() {
      let lowercaseString = this.message.content.toLowerCase();
      // Replace all spaces, tabs, new lines and carriage returns with underscores
      let parsedString = lowercaseString.replace(/\s/g, "_");
      let length = 12;
      parsedString = parsedString.substring(0, length);
      let messagePreviewChars = [];
      // Use spread operator to create array of characters from message
      [...parsedString].forEach(glyph => {
        let character = {
          glyph: glyph
        };
        messagePreviewChars.push(character);
      });
      return (this.messagePreviewChars = messagePreviewChars);
    },
    // Update the cipher based on the secret key (if there is one)
    generateCipher() {
      const secretKey = this.message.secretKey;
      let offset = secretKey.length + 1;
      this.cipher.forEach((character, i) => {
        if (secretKey.includes(character.glyph)) {
          let index = secretKey.indexOf(character.glyph);
          this.cipher[i].code = index + 1;
        } else {
          this.cipher[i].code = offset;
          offset++;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.message-received {
  padding: 0.75rem 0;
  border-bottom: $base-border;
  display: grid;
  grid-template-columns: 2.5rem 1fr 3rem;
  @include responsive(small) {
    padding: 1rem 0;
    grid-template-columns: 3rem 1fr 3.5rem;
  }
}
.message-received:last-child {
  border-bottom: none;
}
.message-received__message-decode-status {
  display: flex;
  align-items: center;
}
.message-received__message-decode-status-badge {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border: 1px solid #dedfe3;
  border-radius: 1.5rem;
  color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive(small) {
    width: 1.75rem;
    height: 1.75rem;
  }
}
.message-received__message-in-review-indicator {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #f95959;
  border-radius: 4px;
}
.message-received__message-unread-indicator {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #0baaf3;
  border-radius: 4px;
}
.message-received__message-details {
  flex-grow: 1;
  overflow: hidden; // Prevent flex from breaking
  display: flex;
  flex-direction: column;
}
.message-received__message-details:hover {
  cursor: pointer;
}
.message-received__primary-message-details {
  // flex-grow: 1;
}
.message-received__message-metadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.message-received__message-metadata-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include responsive(small) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}
.message-received__sender-name {
  margin-right: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: capitalize;
  @include truncate;
  @include responsive(small) {
    margin-right: 0.75rem;
  }
}
.message-received__sender-email {
  margin-right: 0.5rem;
  font-size: 0.85rem;
  color: #8b8e9e;
  @include truncate;
  @include responsive(small) {
    margin-right: 0.75rem;
    // font-size: 1rem;
  }
}
.message-received__message-sent-at {
  font-size: 0.85rem;
  color: #8b8e9e;
}
.message-received__message-content {
  margin: 0.25rem 0 0;
  padding: 0;
  // @include truncate;
  @include responsive(small) {
    margin: 0.5rem 0 0.25rem;
  }
}
.message-received__message-decoded {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.message-received__message-plaintext {
  font-size: 1rem;
  // @include truncate;
}
.message-received__message-actions {
  width: auto;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
