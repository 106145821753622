<template>
  <div class="blocked-sender">
    <div class="blocked-senders__sender-details">
      <h4 class="blocked-senders__sender-name">
        {{ blockedSender.blockedUserName }}
      </h4>
      <div class="blocked-senders__sender-email">
        {{ blockedSender.blockedUserEmail }}
      </div>
    </div>
    <div class="blocked-senders__sender-actions">
      <BaseButton
        secondary
        pad
        small
        :loading="loading"
        @click.native="removeBlockedSender(blockedSender)"
      >
        Remove
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockedSender",
  props: {
    blockedSender: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    removeBlockedSender(blockedSender) {
      this.loading = true;
      this.$emit("remove", blockedSender);
    }
  }
};
</script>

<style lang="scss">
.blocked-sender {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include responsive(small) {
  }
}
.blocked-sender__details {
  flex-grow: 1;
  margin-right: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: $base-border;
  overflow: hidden; // Prevent flex from breaking
  display: flex;
  flex-direction: column;
  @include responsive(small) {
    margin-right: 1rem;
    flex-direction: row;
  }
}
.blocked-sender__actions {
  width: auto;
  padding: 0;
  @include responsive(small) {
  }
}
.blocked-sender__name {
  margin: 0;
  padding: 0;
  font-weight: 600;
  @include truncate;
  @include responsive(small) {
    margin-right: 1rem;
  }
}
.blocked-sender__email {
  color: #74778a;
  @include truncate;
}
</style>
