<template>
  <div class="block-user-success">
    <BaseSuccessMessage>
      <template #title>Sender blocked</template>
      <template #description>
        <p>
          If you change your mind, you can visit your Account to undo this
          action.
        </p>
      </template>
    </BaseSuccessMessage>
    <BaseButtonGroup>
      <BaseButton primary pad @click.native="done">Done</BaseButton>
    </BaseButtonGroup>
  </div>
</template>

<script>
export default {
  name: "BlockUserSuccess",
  methods: {
    done() {
      this.$emit("done");
    }
  }
};
</script>
