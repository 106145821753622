<template>
  <div class="onboard">
    <div class="onboard__feature-illustration">
      <img
        class="onboard__logo"
        src="https://res.cloudinary.com/dv5har4fh/image/upload/v1611052401/StickerCode/beta-logo-large.png"
      />
    </div>
    <div class="onboard__feature-description">
      <p class="onboard__primary-paragraph">
        Welcome to the public beta.
      </p>
      <p class="onboard__secondary-paragraph">
        All messages will be deleted at the end of this beta phase.
      </p>
      <!-- <p>Swap secret messages with your friends.</p> -->
    </div>
    <div class="onboard__call-to-action-button">
      <BaseButton primary pad feature @click.native="startMessaging">
        Start messaging
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "Onboard",
  methods: {
    startMessaging() {
      localStorage.setItem("StickerCodeOnboarded", true);
      this.$emit("startMessaging");
    }
  }
};
</script>

<style lang="scss">
.onboard {
  width: 24rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.onboard__feature {
  width: 24rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.onboard__feature-illustration {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onboard__logo {
  width: 249px;
  height: 60px;
}
.onboard__feature-description {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.onboard__primary-paragraph {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.onboard__secondary-paragraph {
  max-width: 16rem;
  margin-bottom: 0.75rem;
  font-size: 1.125rem;
  color: #f95959;
  text-align: center;
}
.onboard__call-to-action-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
