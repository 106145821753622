<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="view-disabled-icon"
      d="M3.51471863,2.10050506 L7.04683501,5.63153129 C8.43497447,4.56874592 10.1423043,3.98370675 11.91,3.98370675 C14.033274,3.98370675 16.0694539,4.82778366 17.57,6.33 L22.52,11.28 C22.7093127,11.4677666 22.8157983,11.7233625 22.8157983,11.99 C22.8157983,12.2566375 22.7093127,12.5122334 22.52,12.7 L18.3172017,16.9026118 L21.8994949,20.4852814 C22.2900192,20.8758057 22.2900192,21.5089706 21.8994949,21.8994949 C21.5089706,22.2900192 20.8758057,22.2900192 20.4852814,21.8994949 L16.8646696,18.2794518 C15.458694,19.39222 13.7110278,20.0062933 11.9,20.0062933 C9.77672602,20.0062933 7.74054606,19.1622163 6.24,17.66 L1.3,12.7 C1.11068735,12.5122334 1.00420168,12.2566375 1.00420168,11.99 C1.00420168,11.7233625 1.11068735,11.4677666 1.3,11.28 L5.58220168,6.99661184 L2.10050506,3.51471863 C1.70998077,3.12419433 1.70998077,2.49102936 2.10050506,2.10050506 C2.49102936,1.70998077 3.12419433,1.70998077 3.51471863,2.10050506 Z M7.00120168,8.41461184 L3.4,12 L7.65,16.24 L7.66,16.24 C9.77892326,18.356288 13.0861742,18.5583073 15.4319712,16.8460577 L13.9945698,15.4084126 C13.385367,15.783584 12.6679568,16 11.9,16 C9.690861,16 7.9,14.209139 7.9,12 C7.9,11.2320432 8.11641603,10.514633 8.49158738,9.9054302 L7.00120168,8.41461184 Z M8.48524799,7.07052285 L9.93161426,8.51703276 C10.512761,8.1878928 11.1844382,8 11.9,8 C14.109139,8 15.9,9.790861 15.9,12 C15.9,12.7155618 15.7121072,13.387239 15.3829672,13.9683857 L16.8972017,15.4826118 L20.38,12 L16.14,7.76 C14.1192834,5.74179652 11.0178384,5.46453091 8.70017573,6.92820317 L8.48524799,7.07052285 Z M9.9,12 C9.9,13.1045695 10.7954305,14 11.9,14 C12.1075252,14 12.3076681,13.9683927 12.4958773,13.9097295 L9.9899554,11.4051347 C9.93149411,11.5930493 9.9,11.7928467 9.9,12 Z M11.9,10 C11.749728,10 11.6033268,10.016573 11.4625246,10.047991 L13.852009,12.4374754 C13.883427,12.2966732 13.9,12.150272 13.9,12 C13.9,10.8954305 13.0045695,10 11.9,10 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseViewDisabledIcon"
};
</script>

<style>
.view-disabled-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
