<template>
  <div>
    <keep-alive>
      <Editor
        v-if="activeComponent === 'Editor'"
        :messageData="messageData"
        @requestSharingOptions="handleSendRequest"
      />
    </keep-alive>
    <Send
      v-if="activeComponent === 'Send'"
      :messageData="messageData"
      @cancel="cancelSend"
      @sendSuccess="sendSuccess"
      @saveError="saveError"
      @sendError="sendError"
      @sendBlocked="sendBlocked"
    />
    <SendSuccess
      v-if="activeComponent === 'SendSuccess'"
      :recipientData="recipientData"
      @done="done"
    />
    <SendError
      v-if="activeComponent === 'SendError'"
      :recipientData="recipientData"
      @done="done"
    />
    <SendBlocked
      v-if="activeComponent === 'SendBlocked'"
      :recipientData="recipientData"
      @tryAgain="tryAgain"
    />
    <SaveError
      v-if="activeComponent === 'SaveError'"
      :recipientData="recipientData"
      @tryAgain="tryAgain"
    />
  </div>
</template>

<script>
import Editor from "@/components/Editor.vue";
import Send from "@/components/Send";
import SendSuccess from "@/components/SendSuccess";
import SendError from "@/components/SendError";
import SendBlocked from "@/components/SendBlocked";
import SaveError from "@/components/SaveError";
export default {
  name: "Write",
  components: {
    Editor,
    Send,
    SendSuccess,
    SendError,
    SendBlocked,
    SaveError
  },
  data() {
    return {
      activeComponent: "Editor",
      messageData: null,
      recipientData: null
    };
  },
  methods: {
    done() {
      this.$router.push("/");
    },
    cancelSend() {
      this.activeComponent = "Editor";
    },
    sendSuccess(recipientData) {
      this.recipientData = recipientData;
      this.activeComponent = "SendSuccess";
    },
    sendError(recipientData) {
      this.recipientData = recipientData;
      this.activeComponent = "SendError";
    },
    sendBlocked(recipientData) {
      this.recipientData = recipientData;
      this.activeComponent = "SendBlocked";
    },
    saveError(recipientData) {
      this.recipientData = recipientData;
      this.activeComponent = "SaveError";
    },
    tryAgain() {
      this.activeComponent = "Editor";
    },
    handleSendRequest(messageData) {
      this.messageData = messageData;
      this.activeComponent = "Send";
    }
  }
};
</script>
