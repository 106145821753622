<template>
  <BaseCard>
    <template #title>Download your content</template>
    <template #description>
      Download an archive of all the messages you've sent (in plain text
      format).
    </template>
    <BaseButton primary pad @click.native="downloadContent">
      Download
    </BaseButton>
  </BaseCard>
</template>

<script>
export default {
  name: "DownloadContent",
  methods: {
    downloadContent() {
      this.$emit("downloadContent");
    }
  }
};
</script>
