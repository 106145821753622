<template>
  <div class="primary-nav">
    <BaseDropdown title="Menu" align="right" secondary icon-only>
      <template v-slot:dropdownButtonLabel>
        <BaseMenuIcon />
      </template>
      <template v-slot:dropdownContent>
        <ul class="primary-nav__menu">
          <li
            class="primary-nav__menu-option primary-nav__menu-option--primary"
            @click="newMessage"
          >
            <BaseComposeIcon />
            <span>New message</span>
          </li>
          <hr class="primary-nav__menu-divider" />
          <li>
            <router-link
              class="primary-nav__menu-option"
              :to="{ name: 'messages' }"
            >
              <BaseMessagesIcon />
              <span>Messages</span>
            </router-link>
          </li>
          <hr class="primary-nav__menu-divider" />
          <!-- <li>
            <a
              class="primary-nav__menu-option"
              href="https://get.stickercode.app"
            >
              <BaseMobileIcon />
              <span>Get the app</span>
            </a>
          </li> -->
          <li>
            <router-link
              class="primary-nav__menu-option"
              :to="{ name: 'help' }"
            >
              <BaseHelpIcon />
              <span>Help</span>
            </router-link>
          </li>
          <li>
            <router-link
              class="primary-nav__menu-option"
              :to="{ name: 'privacy' }"
            >
              <BaseLockClosedIcon />
              <span>Privacy</span>
            </router-link>
          </li>
          <li>
            <router-link
              class="primary-nav__menu-option"
              :to="{ name: 'terms' }"
            >
              <BaseDocumentIcon />
              <span>Terms</span>
            </router-link>
          </li>
          <hr class="primary-nav__menu-divider" />
          <li v-if="userIsAuthenticated">
            <a class="primary-nav__menu-option" @click="viewAccount">
              <BaseAccountIcon />
              <span>Account</span>
            </a>
          </li>
          <li v-if="!userIsAuthenticated">
            <a class="primary-nav__menu-option" @click="signIn">
              <BaseAccountIcon />
              <span>Sign In</span>
            </a>
          </li>
        </ul>
      </template>
    </BaseDropdown>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
export default {
  name: "PrimaryNav",
  data() {
    return {
      userIsAuthenticated: false
    };
  },
  created() {
    this.checkAuthStatus();
  },
  methods: {
    async checkAuthStatus() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.userIsAuthenticated = true;
        return user;
      } catch (error) {
        this.userIsAuthenticated = false;
        return error;
      }
    },
    newMessage() {
      // Push to write view if we aren't already there
      // console.log(this.$router.currentRoute);
      if (this.$router.currentRoute.name !== "write") {
        this.$router.push("/write");
      } else {
        this.$emit("newMessage");
      }
    },
    viewAccount() {
      this.$router.push("/account");
    },
    signIn() {
      this.$router.push("/auth");
    }
  }
};
</script>

<style lang="scss">
.primary-nav {
}
.primary-nav__menu {
  min-width: 12rem;
  padding: 0.5rem;
}
.primary-nav__menu-option {
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  text-decoration: none;
  color: $base-text-color;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) {
  .primary-nav__menu-option:hover {
    background: #f7f8fc;
  }
}
.primary-nav__menu-option--primary {
  background: #353741;
  font-weight: 600;
  color: #f7f8fc;
}
@media (hover: hover) {
  .primary-nav__menu-option--primary:hover {
    background-color: #202127;
  }
}
.primary-nav__menu-option > svg {
  margin-right: 0.75rem;
}
.primary-nav__menu-divider {
  border: 0;
  height: 0;
  border-top: $base-border;
  border-bottom: 1px solid transparent;
}
</style>
