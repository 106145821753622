<template>
  <BaseDropdown title="Options" align="right" secondary icon-only small-button>
    <template v-slot:dropdownButtonIcon>
      <BaseMoreIcon />
    </template>
    <template v-slot:dropdownContent>
      <ul class="message-received-actions">
        <li class="message-received-action" @click="readMessage()">
          <BaseViewIcon />
          <span>Read</span>
        </li>
        <li class="message-received-action" @click="replyMessage()">
          <BaseReplyIcon />
          <span>Reply</span>
        </li>
        <li class="message-received-action" @click="reportMessage()">
          <BaseFlagIcon />
          <span>Report</span>
        </li>
        <li class="message-received-action" @click="blockSender()">
          <BaseBlockIcon />
          <span>Block</span>
        </li>
      </ul>
    </template>
  </BaseDropdown>
</template>

<script>
export default {
  name: "MessageReceivedActions",

  methods: {
    readMessage() {
      this.$emit("readMessage");
    },
    replyMessage() {
      this.$emit("replyMessage");
    },
    reportMessage() {
      this.$emit("reportMessage");
    },
    blockSender() {
      this.$emit("blockSender");
    }
  }
};
</script>

<style lang="scss">
.message-received-actions {
  min-width: 12rem;
  padding: 0.5rem;
  @include responsive(small) {
    min-width: 12rem;
  }
}
.message-received-action {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) {
  .message-received-action:hover {
    background: #f7f8fc;
    border-radius: 0.5rem;
  }
}
.message-received-action > svg {
  margin-right: 0.75rem;
}
</style>
