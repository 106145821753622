<template>
  <ModalView close @close="done">
    <template #title> {{ message.senderName }} says... </template>
    <div class="message-deciphered">
      <div class="message-deciphered__content">
        {{ message.content }}
      </div>
    </div>
    <template #footer>
      <div class="message-deciphered__actions">
        <BaseButton secondary pad @click.native="done">Done</BaseButton>
        <BaseButton primary pad @click.native="reply">Reply</BaseButton>
      </div>
    </template>
  </ModalView>
</template>

<script>
import ModalView from "@/components/ModalView";
export default {
  name: "MessageDeciphered",
  components: {
    ModalView
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  methods: {
    done() {
      this.$router.push("/");
    },
    reply() {
      this.$emit("reply");
    }
  }
};
</script>

<style lang="scss">
.message-deciphered {
}
.message-deciphered__content {
  margin: 1.5rem 0 2rem;
  font-size: 2rem;
  font-weight: 600;
}
// Make sure first letter of user-generated content is capitalised
.message-deciphered__content::first-letter {
  text-transform: capitalize;
}
.message-deciphered__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.message-deciphered__actions .button {
  margin-left: 0.5rem;
}
</style>
