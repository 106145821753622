<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      class="large-tick-circle-icon"
      d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M24,7 C14.6111593,7 7,14.6111593 7,24 C7,33.3888407 14.6111593,41 24,41 C33.3888407,41 41,33.3888407 41,24 C41,14.6111593 33.3888407,7 24,7 Z M31.9498567,16.8390641 C32.550951,17.3308685 32.671579,18.1922026 32.2520595,18.8262537 L32.1609359,18.9498567 L22.1113561,31.2326765 L15.9393398,25.0606602 C15.3535534,24.4748737 15.3535534,23.5251263 15.9393398,22.9393398 C16.4885146,22.390165 17.357577,22.3558416 17.9467378,22.8363696 L18.0606602,22.9393398 L21.888,26.767 L29.8390641,17.0501433 C30.3636554,16.4089761 31.3086895,16.3144727 31.9498567,16.8390641 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseLargeTickCircleIcon"
};
</script>

<style>
.large-tick-circle-icon {
  max-width: 48px;
  fill: currentColor;
}
</style>
