<template>
  <div class="report">
    <ReportMessage
      v-if="activeComponent === 'ReportMessage'"
      :message="message"
      @reportSuccess="reportSuccess"
      @reportError="reportError"
      @close="close"
    />
    <ReportMessageSuccess
      v-else-if="activeComponent === 'ReportSuccess'"
      @done="done"
    />
    <ReportMessageError
      v-else-if="activeComponent === 'ReportError'"
      @close="close"
      @tryAgain="tryAgain"
    />
    <MessageLoadingError
      v-else-if="activeComponent === 'MessageLoadingError'"
    />
    <MessageInReview v-else-if="activeComponent === 'MessageInReview'" />
    <Loading v-else />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import ReportMessage from "@/components/ReportMessage";
import ReportMessageSuccess from "@/components/ReportMessageSuccess";
import ReportMessageError from "@/components/ReportMessageError";
import MessageInReview from "@/components/MessageInReview";
import MessageLoadingError from "@/components/MessageLoadingError";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getMessage } from "@/graphql/queries";

export default {
  name: "Report",
  components: {
    Loading,
    ReportMessage,
    ReportMessageSuccess,
    ReportMessageError,
    MessageInReview,
    MessageLoadingError
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeComponent: "Loading",
      message: {}
    };
  },
  async created() {
    await this.getMessage();
  },
  methods: {
    close() {
      this.$router.push("/read/" + this.id);
    },
    done() {
      this.$router.push("/");
    },
    tryAgain() {
      this.activeComponent = "ReportMessage";
    },
    reportSuccess() {
      this.activeComponent = "ReportSuccess";
    },
    reportError() {
      this.activeComponent = "ReportError";
    },
    async getMessage() {
      this.activeComponent = "Loading";
      const message = await API.graphql(
        graphqlOperation(getMessage, { id: this.id })
      );
      this.message = message.data.getMessage;
      if (this.message == null) {
        this.activeComponent = "MessageLoadingError";
      } else if (this.message.inReview === true) {
        this.activeComponent = "MessageInReview";
      } else {
        this.activeComponent = "ReportMessage";
      }
    }
  }
};
</script>

<style lang="scss">
.report {
  padding: 1.25rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.report__wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.report__actions {
  margin: 1rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.report__actions .button {
  margin-left: 0.5rem;
}
</style>
