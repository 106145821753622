<template>
  <ModalView back @back="back">
    <template #title>{{ stickerPack.name }}</template>
    <div class="sticker-pack-preview">
      <div class="sticker-pack-preview__actions">
        <div class="sticker-pack-preview__pack-price">
          {{ stickerPack.price }}
        </div>
        <BaseButton primary small @click.native="buyPack(stickerPack)">
          Buy
        </BaseButton>
      </div>
      <div class="sticker-pack-preview__stickers">
        <BaseSticker1 :stickerPack="stickerPack.id" />
        <BaseSticker2 :stickerPack="stickerPack.id" />
        <BaseSticker3 :stickerPack="stickerPack.id" />
        <BaseSticker4 :stickerPack="stickerPack.id" />
        <BaseSticker5 :stickerPack="stickerPack.id" />
        <BaseSticker6 :stickerPack="stickerPack.id" />
        <BaseSticker7 :stickerPack="stickerPack.id" />
        <BaseSticker8 :stickerPack="stickerPack.id" />
        <BaseSticker9 :stickerPack="stickerPack.id" />
        <BaseSticker10 :stickerPack="stickerPack.id" />
        <BaseSticker11 :stickerPack="stickerPack.id" />
        <BaseSticker12 :stickerPack="stickerPack.id" />
        <BaseSticker13 :stickerPack="stickerPack.id" />
        <BaseSticker14 :stickerPack="stickerPack.id" />
        <BaseSticker15 :stickerPack="stickerPack.id" />
        <BaseSticker16 :stickerPack="stickerPack.id" />
        <BaseSticker17 :stickerPack="stickerPack.id" />
        <BaseSticker18 :stickerPack="stickerPack.id" />
        <BaseSticker19 :stickerPack="stickerPack.id" />
        <BaseSticker20 :stickerPack="stickerPack.id" />
        <BaseSticker21 :stickerPack="stickerPack.id" />
        <BaseSticker22 :stickerPack="stickerPack.id" />
        <BaseSticker23 :stickerPack="stickerPack.id" />
        <BaseSticker24 :stickerPack="stickerPack.id" />
        <BaseSticker25 :stickerPack="stickerPack.id" />
        <BaseSticker26 :stickerPack="stickerPack.id" />
        <BaseSticker27 :stickerPack="stickerPack.id" />
        <BaseSticker28 :stickerPack="stickerPack.id" />
        <BaseSticker29 :stickerPack="stickerPack.id" />
        <BaseSticker30 :stickerPack="stickerPack.id" />
        <BaseSticker31 :stickerPack="stickerPack.id" />
        <BaseSticker32 :stickerPack="stickerPack.id" />
        <BaseSticker33 :stickerPack="stickerPack.id" />
        <BaseSticker34 :stickerPack="stickerPack.id" />
        <BaseSticker35 :stickerPack="stickerPack.id" />
        <BaseSticker36 :stickerPack="stickerPack.id" />
        <BaseSticker37 :stickerPack="stickerPack.id" />
        <BaseSticker38 :stickerPack="stickerPack.id" />
        <BaseSticker39 :stickerPack="stickerPack.id" />
        <BaseSticker40 :stickerPack="stickerPack.id" />
      </div>
    </div>
  </ModalView>
</template>

<script>
import ModalView from "@/components/ModalView";
export default {
  name: "StickerPackPreview",
  components: {
    ModalView
  },
  props: {
    stickerPack: {
      type: Object,
      required: true
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    buyPack(pack) {
      this.$emit("buyPack", pack);
    }
  }
};
</script>

<style lang="scss">
.sticker-pack-preview {
}
.sticker-pack-preview__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sticker-pack-preview__pack-price {
  margin-right: 1rem;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #74778a;
}
.sticker-pack-preview__stickers {
  margin: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @include responsive(small) {
  }
}
.sticker-pack-preview__stickers > svg {
  width: 3.5rem;
  height: 3.5rem;
  margin: 0.125rem;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
  @include responsive(small) {
    width: 3rem;
    height: 3rem;
    margin: 0.25rem;
  }
  @include responsive(medium) {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0.25rem;
  }
}
</style>
