<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="lock-closed-icon"
      d="M7,10 L7,7 C7,4.23857625 9.23857625,2 12,2 C14.7614237,2 17,4.23857625 17,7 L17,10 L19,10 C20.1045695,10 21,10.8954305 21,12 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,12 C3,10.9 3.9,10 5,10 L7,10 Z M9,10 L15,10 L15,7 C15,5.34314575 13.6568542,4 12,4 C10.3431458,4 9,5.34314575 9,7 L9,10 Z M5,12 L5,20 L19,20 L19,12 L5,12 Z M12,14 C12.5522847,14 13,14.4477153 13,15 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,15 C11,14.4477153 11.4477153,14 12,14 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseLockClosedIcon"
};
</script>

<style>
.lock-closed-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
