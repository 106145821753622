<template>
  <div class="save-error">
    <BaseCard>
      <BaseErrorMessage>
        <template #title>
          Save error
        </template>
        <template #description>
          Your message to {{ recipientData.recipientName }} could not be sent.
          Click the button below and then try sending again.
        </template>
      </BaseErrorMessage>
      <BaseButtonGroup>
        <BaseButton @click.native="tryAgain" primary pad>
          Try again
        </BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "SaveError",
  props: {
    recipientData: {
      type: Object,
      required: true
    }
  },
  methods: {
    tryAgain() {
      this.$emit("tryAgain");
    }
  }
};
</script>

<style lang="scss">
.save-error {
  height: 100vh;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
