<template>
  <div class="view-loading">
    <div class="view-loading__content">
      <div class="view-loading__label">
        Loading
      </div>
      <div class="view-loading__spinner">
        <BaseSpinner />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewLoading"
};
</script>

<style lang="scss">
.view-loading {
  // background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.view-loading__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-loading__label {
  font-size: 1rem;
  color: #8b8e9e;
}
.view-loading__spinner {
  width: 24px;
  height: 24px;
  margin-left: 1rem;
  color: #8b8e9e;
}
</style>
