<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="spinner">
    <circle class="spinner__circle" cx="50" cy="50" r="45" />
  </svg>
</template>

<script>
export default {
  name: "BaseSpinner"
};
</script>

<style lang="scss">
.spinner {
  max-width: 100px;
  animation: 2s linear infinite svg-animation;
}
.spinner__circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
</style>
