<template>
  <div class="block-user-error">
    <BaseErrorMessage>
      <template #title>Block sender error</template>
      <template #description>
        An error occured and the sender has not been blocked.
      </template>
      <p>
        If the problem persists, please
        <router-link :to="{ name: 'contact' }">
          contact us
        </router-link>
        for support.
      </p>
    </BaseErrorMessage>
    <BaseButtonGroup>
      <BaseButton secondary pad @click.native="cancel">Cancel</BaseButton>
      <BaseButton primary pad @click.native="tryAgain">Try again</BaseButton>
    </BaseButtonGroup>
  </div>
</template>

<script>
export default {
  name: "BlockUserError",
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    tryAgain() {
      this.$emit("tryAgain");
    }
  }
};
</script>
