<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="sign-out-icon"
      d="M10,3 C12.1422255,3 13.8910807,4.68395865 13.9951048,6.80035876 L14,7 L14,8 C14,8.55228475 13.5522847,9 13,9 C12.4871642,9 12.0644928,8.61395981 12.0067277,8.11662113 L12,8 L12,7 C12,5.94562332 11.1841458,5.08183352 10.1492656,5.00548564 L10,5 L6,5 C4.94564226,5 4.08183525,5.81588166 4.00548576,6.85073818 L4,7 L4,17 C4,18.0543865 4.81586358,18.9181674 5.85073572,18.9945144 L6,19 L10,19 C11.0544055,19 11.9181691,18.1841639 11.9945145,17.149268 L12,17 L12,16 C12,15.4477153 12.4477153,15 13,15 C13.5128358,15 13.9355072,15.3860402 13.9932723,15.8833789 L14,16 L14,17 C14,19.1422398 12.3160745,20.8910816 10.1996443,20.9951048 L10,21 L6,21 C3.85779448,21 2.10892051,19.3160607 2.00489528,17.199643 L2,17 L2,7 C2,4.85780873 3.68397247,3.10892137 5.80036002,3.00489532 L6,3 L10,3 Z M17.6128994,7.20970461 L17.7071068,7.29289322 L21.7071068,11.2928932 C21.7425008,11.3282873 21.774687,11.3656744 21.8036654,11.4046934 L21.8753288,11.5159379 L21.9287745,11.628664 L21.9641549,11.734007 L21.9930928,11.8819045 L22,12 L21.9972121,12.0752385 L21.9797599,12.2007258 L21.9502619,12.3121425 L21.9063266,12.4232215 L21.8540045,12.5207088 L21.7803112,12.625449 L21.7071068,12.7071068 L17.7071068,16.7071068 C17.3165825,17.0976311 16.6834175,17.0976311 16.2928932,16.7071068 C15.9324093,16.3466228 15.9046797,15.7793918 16.2097046,15.3871006 L16.2928932,15.2928932 L18.585,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4871642 6.38604019,11.0644928 6.88337887,11.0067277 L7,11 L18.585,11 L16.2928932,8.70710678 C15.9023689,8.31658249 15.9023689,7.68341751 16.2928932,7.29289322 C16.6233369,6.96244959 17.1275055,6.91161211 17.5114029,7.14038077 L17.6128994,7.20970461 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseSignOutIcon"
};
</script>

<style>
.sign-out-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
