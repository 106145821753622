<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="flag-icon"
      d="M22,15.0535156 C22,15.5869794 21.5812883,16.0265206 21.048451,16.0523668 C18.5410216,16.173994 16.1915379,16.1565384 14,16 C10.3001195,15.7357228 11,14 8,14 C6.66672854,14 5.33345707,14.2962688 4.00018561,14.8888064 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 C3.51587037,2 3.94050603,2.39062223 3.9942593,2.89221901 C5.32986744,2.29706699 6.66493372,2 8,2 C11,2 10,4 14,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,15.0535156 Z M8,4 C6.66672854,4 5.33345707,4.2962688 4.00018561,4.8888064 L4.00080446,12.8885314 C5.33386964,12.2961771 6.66693482,12 8,12 C11,12 10.6998805,13.7642772 14,14 C15.7158953,14.122564 17.715607,14.1495373 19.9991351,14.0809201 L20,6 L14,6 C10,6 11,4 8,4 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseFlagIcon"
};
</script>

<style>
.flag-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
