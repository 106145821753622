<template>
  <div class="page-view-header">
    <div class="page-view-header__left">
      <slot />
    </div>
    <div class="page-view-header__center">
      <router-link :to="{ path: '/' }">
        <AppLogo />
      </router-link>
    </div>
    <div class="page-view-header__right">
      <slot name="right" />
      <PrimaryNav />
    </div>
  </div>
</template>

<script>
import AppLogo from "@/components/AppLogo";
import PrimaryNav from "@/components/PrimaryNav";

export default {
  name: "PageViewHeader",
  components: {
    AppLogo,
    PrimaryNav
  }
};
</script>

<style lang="scss">
.page-view-header {
  height: 72px;
  padding: 1rem 1rem 1rem;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "left center right";
  gap: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  @include responsive(small) {
    height: 84px;
    padding: 1.5rem 1.5rem 1rem;
  }
}
@supports (padding: max(0px)) {
  .page-view-header {
    padding-top: max(1rem, calc(env(safe-area-inset-top) + 1rem));
  }
}
.page-view-header__left {
  grid-area: left;
  align-self: start;
  justify-self: start;
}
.page-view-header__center {
  grid-area: center;
  align-self: center;
  justify-self: center;
}
.page-view-header__right {
  grid-area: right;
  align-self: start;
  justify-self: end;
}
.page-view-header__right .button {
  margin-left: 0.75rem;
  @include responsive(small) {
    margin-left: 1.5rem;
  }
}

@media print {
  .page-view-header {
    visibility: hidden;
  }
}
</style>
