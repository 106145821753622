<template>
  <BaseCard>
    <template #title>Delete account</template>
    <template #description>
      Once you delete your account, there's no going back. Please be certain.
    </template>
    Delete account button (red with confirmation dialog)
    <BaseButton primary danger pad @click.native="deleteAccount">
      Delete account
    </BaseButton>
  </BaseCard>
</template>

<script>
export default {
  name: "DeleteAccount",
  methods: {
    deleteAccount() {
      this.$emit("deleteAccount");
    }
  }
};
</script>
