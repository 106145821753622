<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="left-chevron-icon"
      d="M16.3137085,19.3137085 C16.7042328,18.9231842 16.7042328,18.2900192 16.3137085,17.8994949 L9.94974747,11.5355339 L16.3137085,5.17157288 C16.7042328,4.78104858 16.7042328,4.1478836 16.3137085,3.75735931 C15.9231842,3.36683502 15.2900192,3.36683502 14.8994949,3.75735931 L7.82842712,10.8284271 C7.43790283,11.2189514 7.43790283,11.8521164 7.82842712,12.2426407 L14.8994949,19.3137085 C15.2900192,19.7042328 15.9231842,19.7042328 16.3137085,19.3137085 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseLeftChevronIcon"
};
</script>

<style>
.left-chevron-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
