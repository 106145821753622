<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="tick-circle-icon"
      d="M12,2 C17.5228716,2 22,6.47714212 22,12 C22,17.5228847 17.5228847,22 12,22 C6.47714212,22 2,17.5228716 2,12 C2,6.47715525 6.47715525,2 12,2 Z M12,4 C7.58172475,4 4,7.58172475 4,12 C4,16.4183007 7.58171025,20 12,20 C16.4183153,20 20,16.4183153 20,12 C20,7.58171025 16.4183007,4 12,4 Z M15.7071068,9.29289322 C16.0675907,9.65337718 16.0953203,10.2206082 15.7902954,10.6128994 L15.7071068,10.7071068 L11,15.4142136 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L14.2928932,9.29289322 C14.6834175,8.90236893 15.3165825,8.90236893 15.7071068,9.29289322 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseTickCircleIcon"
};
</script>

<style>
.tick-circle-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
