<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="add-icon"
      d="M12,5 C12.5128358,5 12.9355072,5.38604019 12.9932723,5.88337887 L13,6 L13,11 L18,11 C18.5522847,11 19,11.4477153 19,12 C19,12.5128358 18.6139598,12.9355072 18.1166211,12.9932723 L18,13 L13,13 L13,18 C13,18.5522847 12.5522847,19 12,19 C11.4871642,19 11.0644928,18.6139598 11.0067277,18.1166211 L11,18 L11,13 L6,13 C5.44771525,13 5,12.5522847 5,12 C5,11.4871642 5.38604019,11.0644928 5.88337887,11.0067277 L6,11 L11,11 L11,6 C11,5.44771525 11.4477153,5 12,5 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseAddIcon"
};
</script>

<style>
.add-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
