<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--position-above': position === 'above',
      'dropdown--align-right': align === 'right'
    }"
    v-on-clickaway="closeDropdown"
  >
    <BaseButton
      :primary="primary"
      :secondary="secondary"
      :bare="bare"
      :title="title"
      :aria-label="ariaLabel"
      :disabled="disabled"
      :icon-only="iconOnly"
      :small="smallButton"
      :rotateIcon180="showDropdownPanel"
      @click.native="toggleDropdown()"
    >
      <slot name="dropdownButtonLabel" />
      <template #iconRight>
        <slot name="dropdownButtonIcon" />
      </template>
    </BaseButton>
    <transition name="fade">
      <div
        class="dropdown__panel"
        v-if="showDropdownPanel"
        @click="closeDropdown"
      >
        <slot name="dropdownContent" />
      </div>
    </transition>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  name: "BaseDropdown",
  mixins: [clickaway],
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    position: {
      type: String,
      required: false,
      default: "below"
    },
    align: {
      type: String,
      required: false,
      default: "left"
    },
    primary: {
      type: Boolean,
      required: false,
      default: false
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false
    },
    bare: {
      type: Boolean,
      required: false,
      default: false
    },
    ariaLabel: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    smallButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showDropdownPanel: false
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdownPanel = !this.showDropdownPanel;
    },
    closeDropdown() {
      this.showDropdownPanel = false;
    }
  }
};
</script>
<style lang="scss">
.dropdown {
  position: relative;
}
.dropdown__panel {
  // min-width: 16rem;
  background: #fff;
  box-shadow: $base-box-shadow;
  border-radius: 1rem;
  position: absolute;
  top: 3rem;
  bottom: auto;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 50;
  @include responsive(small) {
    // min-width: 20rem;
    top: 4rem;
  }
}
.dropdown--position-above .dropdown__panel {
  top: auto;
  bottom: 3rem;
  @include responsive(small) {
    bottom: 4rem;
  }
}
.dropdown--align-right .dropdown__panel {
  left: auto;
  right: 0;
}
</style>
