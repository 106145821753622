<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseButtonGroup"
};
</script>

<style lang="scss">
.button-group {
  // margin: 1rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button-group .button {
  margin-left: 0.5rem;
}
</style>
