<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      class="large-exclamation-icon"
      d="M23.6376943,31.000005 L23.6376943,31.000005 M23.6376943,19.000005 L23.6376943,26.000005 M10.14366,40.000005 L37.8564,40.000005 C40.9356,40.000005 42.8602,36.6666 41.3206,34.000005 L27.4642,10.000005 C25.9246,7.33334 22.0756,7.33334 20.536,10.000005 L6.67956,34.000005 C5.13996,36.6666 7.06446,40.000005 10.14366,40.000005 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseLargeExclamationIcon"
};
</script>

<style>
.large-exclamation-icon {
  max-width: 48px;
  fill: currentColor;
}
</style>
