<template>
  <PageView>
    <BaseCard>
      <template #title>Privacy</template>
      <template #description>Last updated: 30/01/2021</template>
      <p>
        This policy applies to all information collected or submitted on the
        StickerCode web app and the native apps for iOS and Android.
      </p>

      <p>
        We take your privacy very seriously and collect the minimum amount of
        data required to facilitate the functionality of the apps.
      </p>

      <p>
        You are required to sign in to use a StickerCode app. This is necessary
        in order to secure the messages that you may choose to share with other
        users.
      </p>

      <p>
        When a user signs in, we store their name, email address and (if
        required) a password. Cookies are used to store tokens on your device as
        part of the authentication system.
      </p>

      <p>
        When you send a StickerCode message to another user, we store your name
        and email address and those of the recipient, along with the plaintext
        version of the message. This information is required in order to fulfil
        the functionality of the apps. We also store the time that a StickerCode
        message was sent and the time when a message was read.
      </p>

      <p>
        When you send a StickerCode message to another user, that user will have
        access to the name and email address you provided when you signed in.
        This enables them to reply to your message.
      </p>

      <p>
        Your StickerCode messages are stored, for a limited time, in plaintext
        in our database. Your messages are automatically deleted from our
        database 28 days after you sent them.
      </p>

      <p>
        If you choose to report a message, we store your name and email address,
        along with those of the sender, and the message plaintext, for up to 12
        months. This enables us to identify any ongoing abuses of our terms.
      </p>

      <p>
        If you choose to block a user from sending you messages, we indefinitely
        store your name and email address, along with those of the sender, in
        order to maintain this blocking functionality.
      </p>

      <p>
        If you wish to export your personal data (name and email address) and
        your currently saved messages you can do so via your Account page.
      </p>

      <p>
        You can delete your account and all your StickerCode messages at any
        time, should you wish.
      </p>

      <p>
        We do not share personal information with outside parties except to the
        extent necessary to facilitate StickerCode’s functionality.
      </p>

      <p>
        If you complete an in-app purchase in the iOS or Android apps,
        additional non-personally identifiable data will be stored for the
        purposes of granting continued access to your purchase. Your billing
        information, address, and name are all maintained by the respective app
        store and are not visible to us.
      </p>

      <p>
        We may disclose your information in response to subpoenas, court orders,
        or other legal requirements; to exercise our legal rights or defend
        against legal claims; to investigate, prevent, or take action regarding
        illegal activities, suspected fraud or abuse, violations of our
        policies; or to protect our rights and property.
      </p>

      <p>
        By using the StickerCode web or native apps, you consent to our privacy
        policy.
      </p>

      <p>
        If you have questions regarding this privacy policy, you may email
        <a href="mailto:privacy@overcast.fm">privacy@stickercode.app</a>.
      </p>

      <p>
        If we decide to change our privacy policy, we will post those changes on
        this page.
      </p>
    </BaseCard>
  </PageView>
</template>

<script>
import PageView from "@/components/PageView";

export default {
  name: "Privacy",
  components: {
    PageView
  }
};
</script>
