<template>
  <BaseCard>
    <template #title>Notification settings</template>
    <template #description>
      Choose how you want to be notified about activity.
    </template>
    Notification options: read messages (email/push), message replies
    (email/push).
  </BaseCard>
</template>

<script>
export default {
  name: "NotificationSettings"
};
</script>
