<template>
  <div class="auth">
    <Onboard
      v-if="activeComponent === 'Onboard'"
      @startMessaging="startMessaging"
    />
    <IdentityProviderAuth
      v-if="activeComponent === 'IdentityProviderAuth'"
      @signInWithEmail="signInWithEmail"
    />
    <SignIn
      v-else-if="activeComponent === 'SignIn'"
      @signUpWithEmail="signUpWithEmail"
    />
    <SignUp
      v-else-if="activeComponent === 'SignUp'"
      @confirmEmail="confirmEmail"
    />
    <Confirm v-else-if="activeComponent === 'Confirm'" />
  </div>
</template>

<script>
import Onboard from "@/components/auth/Onboard";
import IdentityProviderAuth from "@/components/auth/IdentityProviderAuth";
import SignIn from "@/components/auth/SignIn";
import SignUp from "@/components/auth/SignUp";
import Confirm from "@/components/auth/Confirm";
export default {
  name: "Auth",
  components: {
    Onboard,
    IdentityProviderAuth,
    SignIn,
    SignUp,
    Confirm
  },
  data() {
    return {
      activeComponent: "Onboard"
    };
  },
  created() {
    this.checkOnboardingStatus();
  },
  methods: {
    checkOnboardingStatus() {
      var onboarded = localStorage.getItem("StickerCodeOnboarded");
      // If a user has already clicked past the onboarding then don't show it again
      if (onboarded) {
        this.activeComponent = "IdentityProviderAuth";
      }
    },
    startMessaging() {
      this.activeComponent = "IdentityProviderAuth";
    },
    signInWithEmail() {
      this.activeComponent = "SignIn";
    },
    signUpWithEmail() {
      this.activeComponent = "SignUp";
    },
    confirmEmail() {
      this.activeComponent = "confirmEmail";
    }
  }
};
</script>

<style lang="scss">
.auth {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
