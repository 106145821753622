<template>
  <BaseCard large stickyHeader>
    <template #title>
      Sent
      <span
        class="messages-sent__unread-count"
        v-if="unreadCount > 0"
        title="Unread"
      >
        {{ unreadCount }}
      </span>
      <span
        class="messages-sent__in-review-count"
        v-if="inReviewCount > 0"
        title="In review"
      >
        {{ inReviewCount }}
      </span>
    </template>
    <template #subtitle>Sent in last 28 days</template>
    <div class="messages-sent__table">
      <Loading v-if="!messages" />
      <BaseErrorMessage v-else-if="gettingMessagesSentFailed">
        Unable to fetch your sent messages.
      </BaseErrorMessage>
      <BaseNeutralMessage v-else-if="messages.length < 1">
        <template #description>
          You haven't sent any messages in the last 28 days.
          <router-link :to="{ name: 'write' }">Send one now</router-link>.
        </template>
      </BaseNeutralMessage>
      <MessageSent
        v-else
        v-for="message in messages"
        :key="message.Id"
        :message="message"
        @delete="deleteMessage"
      />
    </div>
  </BaseCard>
</template>

<script>
import Loading from "@/components/Loading";
import MessageSent from "@/components/MessageSent";
import API, { graphqlOperation } from "@aws-amplify/api";
import { messagesBySenderEmail } from "@/graphql/queries";
import { deleteMessage } from "@/graphql/mutations";
import { onCreateMessageBySenderEmail } from "@/graphql/subscriptions";
import { onUpdateMessageBySenderEmail } from "@/graphql/subscriptions";
import { onDeleteMessageBySenderEmail } from "@/graphql/subscriptions";
export default {
  name: "MessagesSent",
  components: {
    Loading,
    MessageSent
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      messages: null,
      gettingMessagesSentFailed: false
    };
  },
  created() {
    this.getMessagesSent();
    this.subscribeToOnCreateMessage();
    this.subscribeToOnUpdateMessage();
    this.subscribeToOnDeleteMessage();
  },
  computed: {
    unreadCount() {
      if (this.messages) {
        const unreadMessages = this.messages.filter(
          message => !message.decodedAt
        );
        const unreadCount = unreadMessages.length;
        return unreadCount;
      } else {
        return 0;
      }
    },
    inReviewCount() {
      if (this.messages) {
        const inReviewMessages = this.messages.filter(
          message => message.inReview
        );
        const inReviewCount = inReviewMessages.length;
        return inReviewCount;
      } else {
        return 0;
      }
    }
  },
  methods: {
    async getMessagesSent() {
      try {
        // Attempt to get messages received from backend.
        const result = await API.graphql(
          graphqlOperation(messagesBySenderEmail, {
            senderEmail: this.user.attributes.email,
            limit: 10,
            sortDirection: "DESC"
          })
        );
        // console.log(result.data.messagesBySenderEmail.items);
        return (this.messages = result.data.messagesBySenderEmail.items);
      } catch (error) {
        this.gettingMessagesSentFailed = true;
        return error;
      }
    },
    async deleteMessage(message) {
      try {
        // Attempt to delete message from backend.
        await API.graphql(
          graphqlOperation(deleteMessage, {
            input: {
              id: message.id
            }
          })
        );
      } catch (error) {
        return error;
      }
    },
    async subscribeToOnCreateMessage() {
      const subscription = API.graphql(
        graphqlOperation(onCreateMessageBySenderEmail, {
          senderEmail: this.user.attributes.email
        })
      ).subscribe({
        next: event => {
          // Add the new message to the front of the messages array
          this.messages.unshift(event.value.data.onCreateMessageBySenderEmail);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    },
    async subscribeToOnUpdateMessage() {
      const subscription = API.graphql(
        graphqlOperation(onUpdateMessageBySenderEmail, {
          senderEmail: this.user.attributes.email
        })
      ).subscribe({
        next: event => {
          // Replace the old message with the new version
          const message = this.messages.find(
            message =>
              message.id === event.value.data.onUpdateMessageBySenderEmail.id
          );
          const index = this.messages.indexOf(message);
          // alert(index);
          this.messages.splice(
            index,
            1,
            event.value.data.onUpdateMessageBySenderEmail
          );
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    },
    async subscribeToOnDeleteMessage() {
      const subscription = API.graphql(
        graphqlOperation(onDeleteMessageBySenderEmail, {
          senderEmail: this.user.attributes.email
        })
      ).subscribe({
        next: event => {
          // Delete this message from the messages array
          const message = this.messages.find(
            message =>
              message.id === event.value.data.onDeleteMessageBySenderEmail.id
          );
          const index = this.messages.indexOf(message);
          // alert(index);
          this.messages.splice(index, 1);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }
};
</script>

<style lang="scss">
.messages-sent__unread-count {
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  background-color: #0baaf3;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  user-select: none;
}
.messages-sent__in-review-count {
  margin-left: 0.25rem;
  padding: 0 0.5rem;
  background-color: #f95959;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  user-select: none;
}
</style>
