<template>
  <Unicorn9
    ref="sticker"
    class="sticker"
    :class="{ 'sticker--small': small }"
    v-if="stickerPack === 'unicorn'"
  />
  <Robot9
    ref="sticker"
    class="sticker"
    :class="{ 'sticker--small': small }"
    v-else-if="stickerPack === 'robot'"
  />
  <Emoji9
    ref="sticker"
    class="sticker"
    :class="{ 'sticker--small': small }"
    v-else
  />
</template>

<script>
import Emoji9 from "@/assets/svg/sticker-packs/emoji/9.svg";
import Unicorn9 from "@/assets/svg/sticker-packs/unicorn/9.svg";
import Robot9 from "@/assets/svg/sticker-packs/robot/9.svg";

export default {
  name: "BaseSticker9",
  components: {
    Emoji9,
    Unicorn9,
    Robot9
  },
  props: {
    stickerPack: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.setRandomStickerPlacement();
  },
  methods: {
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    setRandomStickerPlacement() {
      const sticker = this.$refs["sticker"];
      const rotation = "rotate(" + this.randomNumber(-7, 7) + "deg)";
      const offsetX = "translateX(" + this.randomNumber(-2, 2) + "px)";
      const offsetY = "translateY(" + this.randomNumber(-2, 2) + "px)";
      sticker.style.transform = rotation + offsetX + offsetY;
    }
  }
};
</script>

<style lang="scss">
.sticker {
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
}
.sticker.sticker--small {
  width: 2.5rem;
  height: 2.5rem;
}
</style>
