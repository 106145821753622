/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendNotificationEmail = /* GraphQL */ `
  query SendNotificationEmail(
    $senderName: String!
    $senderEmail: String!
    $recipientName: String!
    $recipientEmail: String!
    $messageUrl: String!
    $reportUrl: String!
    $secretKey: String
  ) {
    sendNotificationEmail(
      senderName: $senderName
      senderEmail: $senderEmail
      recipientName: $recipientName
      recipientEmail: $recipientEmail
      messageUrl: $messageUrl
      reportUrl: $reportUrl
      secretKey: $secretKey
    )
  }
`;
export const sendMessageReportedEmail = /* GraphQL */ `
  query SendMessageReportedEmail(
    $messageId: String!
    $senderId: String!
    $senderName: String!
    $senderEmail: String!
    $plainText: String!
    $reason: String!
    $other: String
    $messageUrl: String!
    $secretKey: String
  ) {
    sendMessageReportedEmail(
      messageId: $messageId
      senderId: $senderId
      senderName: $senderName
      senderEmail: $senderEmail
      plainText: $plainText
      reason: $reason
      other: $other
      messageUrl: $messageUrl
      secretKey: $secretKey
    )
  }
`;
export const setInReviewStatusOfMessage = /* GraphQL */ `
  query SetInReviewStatusOfMessage($messageId: String!) {
    setInReviewStatusOfMessage(messageId: $messageId)
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $id: ID
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        senderId
        senderName
        senderEmail
        recipientName
        recipientEmail
        stickerPack
        content
        secretKey
        decodedAt
        inReview
        expiration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageReport = /* GraphQL */ `
  query GetMessageReport($id: ID!) {
    getMessageReport(id: $id) {
      id
      messageId
      senderId
      senderName
      senderEmail
      plainText
      reason
      other
      messageUrl
      secretKey
      reviewerName
      reviewResult
      createdAt
      updatedAt
    }
  }
`;
export const listMessageReports = /* GraphQL */ `
  query ListMessageReports(
    $filter: ModelMessageReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageId
        senderId
        senderName
        senderEmail
        plainText
        reason
        other
        messageUrl
        secretKey
        reviewerName
        reviewResult
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlockedUser = /* GraphQL */ `
  query GetBlockedUser($blockingUserEmail: String!, $blockedUserId: String!) {
    getBlockedUser(
      blockingUserEmail: $blockingUserEmail
      blockedUserId: $blockedUserId
    ) {
      id
      blockedUserId
      blockedUserName
      blockedUserEmail
      blockingUserId
      blockingUserEmail
      createdAt
      updatedAt
    }
  }
`;
export const listBlockedUsers = /* GraphQL */ `
  query ListBlockedUsers(
    $blockingUserEmail: String
    $blockedUserId: ModelStringKeyConditionInput
    $filter: ModelBlockedUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBlockedUsers(
      blockingUserEmail: $blockingUserEmail
      blockedUserId: $blockedUserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        blockedUserId
        blockedUserName
        blockedUserEmail
        blockingUserId
        blockingUserEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByRecipientEmail = /* GraphQL */ `
  query MessagesByRecipientEmail(
    $recipientEmail: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByRecipientEmail(
      recipientEmail: $recipientEmail
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        senderName
        senderEmail
        recipientName
        recipientEmail
        stickerPack
        content
        secretKey
        decodedAt
        inReview
        expiration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesBySenderId = /* GraphQL */ `
  query MessagesBySenderId(
    $senderId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesBySenderId(
      senderId: $senderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        senderName
        senderEmail
        recipientName
        recipientEmail
        stickerPack
        content
        secretKey
        decodedAt
        inReview
        expiration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesBySenderEmail = /* GraphQL */ `
  query MessagesBySenderEmail(
    $senderEmail: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesBySenderEmail(
      senderEmail: $senderEmail
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        senderName
        senderEmail
        recipientName
        recipientEmail
        stickerPack
        content
        secretKey
        decodedAt
        inReview
        expiration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const blockedUsersByBlockingUserId = /* GraphQL */ `
  query BlockedUsersByBlockingUserId(
    $blockingUserId: String
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedUsersByBlockingUserId(
      blockingUserId: $blockingUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blockedUserId
        blockedUserName
        blockedUserEmail
        blockingUserId
        blockingUserEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
