<template>
  <BaseCard>
    <template #title>Report message</template>
    <form @submit.prevent="reportMessage">
      <div class="form-group">
        <BaseSelect
          id="reason"
          v-model="$v.form.reason.$model"
          :options="reasons"
          label="Why do you want to report this message?"
        />
      </div>
      <div class="form-group" v-if="form.reason === 'OTHER'">
        <BaseTextarea
          id="other"
          v-model.trim="$v.form.other.$model"
          label="Please explain your reason"
          rows="4"
          placeholder=""
        />
      </div>
      <BaseButtonGroup>
        <BaseButton secondary pad @click.native="close">Cancel</BaseButton>
        <BaseButton primary pad :loading="loading" type="submit">
          Report
        </BaseButton>
      </BaseButtonGroup>
    </form>
  </BaseCard>
</template>

<script>
import API, { graphqlOperation } from "@aws-amplify/api";
import { createMessageReport } from "@/graphql/mutations";
import { sendMessageReportedEmail } from "@/graphql/queries";
import { setInReviewStatusOfMessage } from "@/graphql/queries";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ReportMessage",
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      reasons: [
        { name: "Please select a reason", value: "", disabled: true },
        { name: "I don't know the sender", value: "SENDER UNKNOWN" },
        { name: "I don't like the message", value: "MESSAGE CONTENT" },
        { name: "Other reason", value: "OTHER" }
      ],
      form: {
        reason: "SENDER UNKNOWN",
        other: ""
      }
    };
  },
  validations: {
    form: {
      reason: {
        required
      },
      other: {}
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getMessageUrl() {
      return process.env.VUE_APP_ROOT_URL + "read/" + this.message.id;
    },
    async reportMessage() {
      this.loading = true;
      // NB: Reporting a message is an anonymous process that does not require a user to be authenticated.
      const messageReport = {
        messageId: this.message.id,
        senderId: this.message.senderId,
        senderName: this.message.senderName,
        senderEmail: this.message.senderEmail,
        plainText: this.message.content,
        reason: this.form.reason,
        other: this.form.other,
        messageUrl: this.getMessageUrl(),
        secretKey: this.message.secretKey
      };
      try {
        // Attempt to create a message report.
        await API.graphql(
          graphqlOperation(createMessageReport, { input: messageReport })
        );
      } catch (error) {
        this.$emit("reportError");
        // console.log("Unable to create message report");
        return error;
      }
      // Attempt to send message reported notification email to admin.
      try {
        await API.graphql(
          graphqlOperation(sendMessageReportedEmail, messageReport)
        );
      } catch (error) {
        this.$emit("reportError");
        // console.log("Unable to send report notification email");
        return error;
      }
      // Set message.inReview to true.
      const messageData = {
        messageId: this.message.id
      };
      try {
        await API.graphql(
          graphqlOperation(setInReviewStatusOfMessage, messageData)
        );
      } catch (error) {
        this.$emit("reportError");
        // console.log("Unable to set message to in review", error);
        return error;
      }
      this.loading = false;
      this.$emit("reportSuccess");
      return;
    }
  }
};
</script>
