<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="block-icon"
      d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M4.92893322,4.92893322 C5.28941718,4.56844926 5.85664824,4.54071972 6.24893944,4.84574461 L6.34314678,4.92893322 L19.0711068,17.6568932 C19.4616311,18.0474175 19.4616311,18.6805825 19.0711068,19.0711068 C18.7106228,19.4315907 18.1433918,19.4593203 17.7511006,19.1542954 L17.6568932,19.0711068 L4.92893322,6.34314678 C4.53840893,5.95262249 4.53840893,5.31945751 4.92893322,4.92893322 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseBlockIcon"
};
</script>

<style>
.block-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
