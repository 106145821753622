<template>
  <div class="report-message-error">
    <BaseErrorMessage>
      <template #title>Error</template>
      <template #description>
        <p>
          An error occured and the message has not been reported.
        </p>
        <p>
          If this problem persists, please
          <router-link :to="{ name: 'contact' }">contact us</router-link>
          for support.
        </p>
      </template>
    </BaseErrorMessage>
    <BaseButtonGroup>
      <BaseButton secondary pad @click.native="close">Close</BaseButton>
      <BaseButton primary pad @click.native="tryAgain">Try again</BaseButton>
    </BaseButtonGroup>
  </div>
</template>

<script>
export default {
  name: "ReportMessageError",
  methods: {
    close() {
      this.$emit("close");
    },
    tryAgain() {
      this.$emit("tryAgain");
    }
  }
};
</script>
