<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="help-icon"
      d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M10.59,8.59 C10.3363414,8.84365859 9.96662601,8.94272355 9.62012195,8.84987807 C9.27361789,8.75703259 9.00296741,8.48638211 8.91012193,8.13987805 C8.81727645,7.79337399 8.91634141,7.42365857 9.17,7.17 C10.7329658,5.60703416 13.2670342,5.60703416 14.83,7.17 C16.3929658,8.73296584 16.3929658,11.2670342 14.83,12.83 L12.71,14.95 C12.4563414,15.2036586 12.086626,15.3027235 11.740122,15.209878 C11.3936179,15.1170326 11.1229674,14.8463821 11.030122,14.499878 C10.9372765,14.153374 11.0363414,13.7836586 11.29,13.53 L13.41,11.41 C14.0421058,10.6140859 13.9767382,9.47064626 13.2580459,8.75195405 C12.5393537,8.03326184 11.3959141,7.96789416 10.6,8.6 L10.59,8.59 Z M12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseHelpIcon"
};
</script>

<style>
.help-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
