<template>
  <ModalView close @close="close">
    <template #title>Get more stickers</template>
    <div class="sticker-pack-list">
      <div class="sticker-pack-list__teaser">
        You'll be able to purchase more sticker packs when the app is ready to
        launch.
      </div>
      <div
        class="sticker-pack-list__sticker-pack"
        v-for="stickerPack in stickerPacks"
        :key="stickerPack.id"
      >
        <div class="sticker-pack-list__pack-details">
          <div class="sticker-pack-list__pack-name">
            {{ stickerPack.name }}
          </div>
          <div class="sticker-pack-list__pack-sample">
            <BaseSticker1 :stickerPack="stickerPack.id" />
            <BaseSticker2 :stickerPack="stickerPack.id" />
            <BaseSticker3 :stickerPack="stickerPack.id" />
            <BaseSticker4 :stickerPack="stickerPack.id" />
            <BaseSticker5 :stickerPack="stickerPack.id" />
          </div>
        </div>
        <div class="sticker-pack-list__pack-actions">
          <BaseButton
            secondary
            small
            full-width
            @click.native="previewPack(stickerPack)"
          >
            Preview
          </BaseButton>
          <BaseButton
            primary
            small
            full-width
            @click.native="buyPack(stickerPack)"
          >
            Buy
          </BaseButton>
          <div class="sticker-pack-list__pack-price">
            {{ stickerPack.price }}
          </div>
        </div>
      </div>
    </div>
  </ModalView>
</template>

<script>
import ModalView from "@/components/ModalView";
export default {
  name: "StickerPackList",
  components: {
    ModalView
  },
  data() {
    return {
      stickerPacks: [
        // {
        //   name: "Unicode Pack",
        //   id: "unicorn",
        //   price: "£1.49"
        // },
        // {
        //   name: "Robots Pack",
        //   id: "robot",
        //   price: "£1.49"
        // },
        // {
        //   name: "Morse Code Pack",
        //   id: "morse",
        //   price: "£1.49"
        // },
        // {
        //   name: "Halloween Pack",
        //   id: "halloween",
        //   price: "£1.49"
        // },
        // {
        //   name: "Christmas Pack",
        //   id: "christmas",
        //   price: "£1.49"
        // }
      ]
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    previewPack(pack) {
      this.$emit("close");
      this.$emit("previewPack", pack);
    },
    buyPack(pack) {
      this.$emit("buyPack", pack);
    }
  }
};
</script>

<style lang="scss">
.sticker-pack-list {
}
.sticker-pack-list__teaser {
  font-size: 1.125rem;
  color: #f95959;
  text-align: center;
}
.sticker-pack-list__sticker-pack {
  padding: 1rem 0;
  border-bottom: 1px solid #ebecef;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.sticker-pack-list__pack-name {
  margin: 0.25rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
}
.sticker-pack-list__pack-sample > svg {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.25rem;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
  @include responsive(small) {
    width: 3.5rem;
    height: 3.5rem;
  }
}
.sticker-pack-list__pack-actions {
  height: 6rem;
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sticker-pack-list__pack-price {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #74778a;
}
</style>
