/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://rzkr36wadbbmzl3yvqokpurnka.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-57h4ja3t3rdnrj5xs5fmfmamzy",
    "aws_cognito_identity_pool_id": "eu-west-2:60ff1ed6-0289-4ef3-ada3-f402a7d0cf10",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_IBk0O0fTB",
    "aws_user_pools_web_client_id": "5qmntpfsiddqo6j6r0f88rcsrm",
    "oauth": {
        "domain": "stickercode-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://stickercode.app/",
        "redirectSignOut": "https://stickercode.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
