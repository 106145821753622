<template>
  <div class="card" :class="{ 'card--small': small, 'card--large': large }">
    <div
      class="card__header"
      :class="{ 'card__header--is-sticky': stickyHeader }"
      v-if="$slots.title || $slots.subtitle || $slots.description"
    >
      <div class="card__header-items">
        <h3 class="card__title" v-if="$slots.title"><slot name="title" /></h3>
        <h4 class="card__subtitle" v-if="$slots.subtitle">
          <slot name="subtitle" />
        </h4>
      </div>
      <div class="card__description" v-if="$slots.description">
        <slot name="description" />
      </div>
    </div>
    <div class="card__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    stickyHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss">
.card {
  width: 100%;
  margin: 0;
  position: relative; // Enables us to position content relative to card boundaries
  display: flex;
  flex-direction: column;
  @include responsive(small) {
    max-width: 34rem;
    min-width: 28rem;
    margin: 0.75rem auto;
  }
  @include responsive(medium) {
    min-width: 34rem;
    margin: 1rem auto;
  }
}
.card:not(:last-child) {
  margin-bottom: 1rem;
  @include responsive(small) {
    margin-bottom: 1.5rem;
  }
  @include responsive(medium) {
    margin-bottom: 1.75rem;
  }
  @include responsive(large) {
    margin-bottom: 2rem;
  }
}
.card--small {
  @include responsive(small) {
    max-width: 24rem;
    min-width: 20rem;
    margin: 0.75rem auto;
  }
  @include responsive(medium) {
    margin: 1rem auto;
  }
}
.card--large {
  @include responsive(small) {
    max-width: 44rem;
    min-width: 28rem;
    margin: 0.75rem auto;
  }
  @include responsive(medium) {
    margin: 1rem auto;
  }
}
.card__header {
  margin-bottom: 0.5rem;
  border-bottom: $base-border;
  @include responsive(small) {
    margin-bottom: 0.75rem;
  }
  @include responsive(medium) {
    margin-bottom: 1rem;
  }
}
.card__header--is-sticky {
  background-color: rgba(#fff, 1);
  // -webkit-backdrop-filter: $backdrop-blur-subtle;
  // backdrop-filter: $backdrop-blur-subtle;
  position: sticky;
  top: 4.5rem;
  z-index: 20;
  @include responsive(small) {
    top: 4.75rem;
  }
  @include responsive(medium) {
    top: 5rem;
  }
  @include responsive(large) {
    top: 5rem;
  }
}
.card__header-items {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.card__title {
  margin: 0;
  margin-bottom: 0.25rem;
  padding: 0;
  font-size: 1.375rem;
  font-weight: 600;
  display: flex; // Align unread/in review counts
  align-items: center; // Align unread/in review counts
  @include responsive(small) {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
}
.card__subtitle {
  margin: 0 0 0 1rem;
  padding: 0;
  font-size: 0.85rem;
  font-weight: 600;
  color: #74778a;
}
.card__description {
  padding: 0 0 0.75rem;
  color: #74778a;
}
.card__body {
  width: 100%;
}
.card__body > p {
  margin: 0.75rem 0;
  @include responsive(small) {
    margin: 1rem 0;
  }
}
</style>
