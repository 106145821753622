<template>
  <div class="error-message">
    <div class="error-message__icon">
      <BaseLargeExclamationIcon />
    </div>
    <div class="error-message__message">
      <h3 class="error-message__title" v-if="$slots.title">
        <slot name="title" />
      </h3>
      <div class="error-message__description" v-if="$slots.description">
        <slot name="description" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseErrorMessage"
};
</script>

<style lang="scss">
.error-message {
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.error-message__icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
  margin-left: -0.5rem;
  color: #0baaf3;
}
.error-message__message {
}
.error-message__title {
  font-size: 1.5rem;
  font-weight: 600;
}
.error-message__description {
  color: #74778a;
}
.error-message__description p {
  margin: 0.5rem 0;
}
</style>
