<template>
  <div class="message-loading-error">
    <BaseCard>
      <BaseErrorMessage>
        <template #title>
          Message not found
        </template>
        <template #description>
          The message you're looking for might have been deleted.
        </template>
      </BaseErrorMessage>
      <div class="message-loading-error__actions">
        <BaseButton primary pad @click.native="done">Done</BaseButton>
      </div>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "MessageLoadingError",
  methods: {
    done() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
.message-loading-error {
  height: 100vh;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-loading-error__actions {
  margin: 1rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.message-loading-error__actions .button {
  margin-left: 0.5rem;
}
</style>
