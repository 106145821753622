<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="document-icon"
      d="M12.5858,2 L7,2 C5.34314525,2 4,3.34314525 4,5 L4,19 C4,20.6568767 5.34313721,22 7,22 L17,22 C18.6568847,22 20,20.6568847 20,19 L20,9.41421 C20,8.88381381 19.789267,8.3751234 19.4142633,8.00005979 L14,2.58578452 C13.6250184,2.21073484 13.1162386,2 12.5858,2 Z M7,4 L12.5858,4 L18,9.41421 L18,19 C18,19.5523153 17.5523153,20 17,20 L7,20 C6.44770207,20 6,19.5523026 6,19 L6,5 C6,4.44771475 6.44771475,4 7,4 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseDocumentIcon"
};
</script>

<style>
.document-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
