<template>
  <transition name="fade">
    <div class="cipher">
      <h2 class="cipher__heading">Cipher</h2>
      <div class="cipher__inner-wrapper">
        <button
          ref="scrollLeftControl"
          class="cipher__scroll-left"
          @click="scrollCipher(true)"
        >
          <BaseLeftChevronIcon />
        </button>
        <div ref="scrollContainer" class="cipher__scroll-wrapper">
          <ul class="cipher__characters">
            <CipherCharacter
              v-for="(character, index) in cipher"
              :key="index"
              :character="character"
              :stickerPack="stickerPack"
              @click.native="selectCipherCharacter(index)"
            />
          </ul>
        </div>
        <button
          ref="scrollRightControl"
          class="cipher__scroll-right"
          @click="scrollCipher(false)"
        >
          <BaseRightChevronIcon />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import CipherCharacter from "@/components/CipherCharacter";
export default {
  name: "Cipher",
  components: {
    CipherCharacter
  },
  props: {
    cipher: {
      type: Array,
      required: true
    },
    stickerPack: {
      type: String,
      required: true
    }
  },
  methods: {
    selectCipherCharacter(charIndex) {
      this.$emit("selectCipherCharacter", charIndex);
    },
    scrollCipher(reverse) {
      let step =
        this.$refs.scrollContainer.clientWidth -
        (this.$refs.scrollLeftControl.clientWidth +
          this.$refs.scrollRightControl.clientWidth);
      if (reverse === true) {
        step = -Math.abs(step);
      }
      this.$refs.scrollContainer.scrollLeft += step;
    }
  }
};
</script>

<style lang="scss">
.cipher {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  @include responsive(small) {
    // margin: 0.5rem 0;
    overflow: hidden;
  }
}
.cipher__heading {
  display: none;
}
.cipher__inner-wrapper {
  position: relative;
  // border: 1px solid red;
}
.cipher__scroll-wrapper {
  width: 100%;
  // margin-top: 1rem;
  padding: 0;
  padding-top: 1rem; // Needed to prevent clipping of stickers on hover
  display: flex;
  justify-content: flex-start;
  background: transparent;
  // border: 1px solid blue;
  overflow-x: scroll; // NB: We can't combine overflow-x: scroll with overflow-y: visible (https://exceptionshub.com/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue.html)
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Add momentum scrolling in iOS */
  display: flex;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @include responsive(small) {
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.cipher__scroll-wrapper::-webkit-scrollbar {
  display: none;
}
.cipher__scroll-left,
.cipher__scroll-right {
  display: none;
  @include responsive(small) {
    height: 2.5rem;
    width: 2.5rem;
    margin: 1rem 1.5rem 2.5rem;
    background-color: #353741;
    border-radius: 1.25rem;
    border: none;
    outline: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    z-index: 10;
  }
}

@media (hover: hover) {
  .cipher__scroll-left:hover,
  .cipher__scroll-right:hover {
    background: #202127;
    box-shadow: $base-box-shadow;
    cursor: pointer;
  }
}
.cipher__scroll-left {
  left: 0;
}
.cipher__scroll-right {
  right: 0;
}
.cipher__characters {
  margin: 0;
  padding: 0 0.5rem;
  background: rgba(#fff, 0.75);
  -webkit-backdrop-filter: $backdrop-blur;
  backdrop-filter: $backdrop-blur;
  display: flex;
  justify-content: center;
  // border: 1px solid red;
  @include responsive(small) {
    margin: 0 0.5rem;
    padding: 0 3.5rem;
  }
}
@media print {
  .cipher {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .cipher__heading {
    margin: 1rem 0;
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
  }
  .cipher__scroll-wrapper {
    margin: 0;
    padding: 0;
    // background-color: transparent;
    border-radius: 0;
    border: none;
    overflow: auto;
  }
  .cipher__characters {
    margin: 0 2rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
    padding-bottom: 2rem;
    background: none;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
