<template>
  <BaseCard>
    <template #title>Personal information</template>
    <dl class="account-essentials__definition-list">
      <div class="account-essentials__item">
        <dt class="account-essentials__term">Name:</dt>
        <dd class="account-essentials__definition">
          {{ user.attributes.name }}
        </dd>
      </div>
      <div class="account-essentials__item">
        <dt class="account-essentials__term">Email:</dt>
        <dd class="account-essentials__definition">
          {{ user.attributes.email }}
        </dd>
      </div>
    </dl>
    <div class="account-essentials__actions">
      <!-- Only for non-federated identities -->
      <BaseButton secondary pad small @click.native="changePassword">
        Change Password
      </BaseButton>
      <!-- Sign out user from this device -->
      <BaseButton primary pad small @click.native="signOut">
        <template #icon>
          <BaseSignOutIcon />
        </template>
        Sign out
      </BaseButton>
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: "AccountEssentials",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    signOut() {
      this.$emit("signOut");
    }
  }
};
</script>

<style lang="scss">
.account-essentials__definition-list {
  margin: 1rem 0;
}
.account-essentials__item {
  margin: 0.5rem 0;
  display: flex;
}
.account-essentials__term {
  min-width: 6rem;
}
.account-essentials__definition {
  font-weight: 500;
}
.account-essentials__actions {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @include responsive(small) {
    flex-direction: row;
  }
}
.account-essentials__actions .button {
  width: 100%;
  margin: 0.5rem 0;
  @include responsive(small) {
    width: auto;
    margin-left: 0.5rem;
  }
}
</style>
