<template>
  <BaseDropdown title="More options" align="left" secondary icon-only>
    <template v-slot:dropdownButtonIcon>
      <BaseMoreIcon />
    </template>
    <template v-slot:dropdownContent>
      <ul class="message-actions">
        <li class="message-action" @click="print()">
          <BasePrintIcon />
          <span>Print</span>
        </li>
        <li class="message-action" @click="reportMessage()">
          <BaseFlagIcon />
          <span>Report message</span>
        </li>
        <li
          v-if="userIsAuthenticated"
          class="message-action"
          @click="blockSender()"
        >
          <BaseBlockIcon />
          <span>Block sender</span>
        </li>
      </ul>
    </template>
  </BaseDropdown>
</template>

<script>
import Auth from "@aws-amplify/auth";

export default {
  name: "MessageActions",
  data() {
    return {
      userIsAuthenticated: false
    };
  },
  created() {
    this.checkAuthStatus();
  },
  methods: {
    async checkAuthStatus() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.userIsAuthenticated = true;
        return user;
      } catch (error) {
        this.userIsAuthenticated = false;
        return error;
      }
    },
    print() {
      window.print();
    },
    reportMessage() {
      this.$emit("reportMessage");
    },
    blockSender() {
      this.$emit("blockSender");
    }
  }
};
</script>

<style lang="scss">
.message-actions {
  min-width: 12rem;
  padding: 0.5rem;
  @include responsive(small) {
    min-width: 12rem;
  }
}
.message-action {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) {
  .message-action:hover {
    background: #f7f8fc;
    border-radius: 0.5rem;
  }
}
.message-action > svg {
  margin-right: 0.75rem;
}
</style>
