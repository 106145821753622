var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",class:{
    'button--primary': _vm.primary,
    'button--secondary': _vm.secondary,
    'button--is-loading': _vm.loading,
    'button--is-disabled': _vm.disabled,
    'button--pad': _vm.pad,
    'button--full-width': _vm.fullWidth,
    'button--feature': _vm.feature,
    'button--icon-only': _vm.iconOnly,
    'button--small': _vm.small,
    'button--rotate-icon-180': _vm.rotateIcon180
  },attrs:{"type":_vm.type,"role":"button","disabled":_vm.disabled || _vm.loading}},[(_vm.$slots.icon)?_c('span',{staticClass:"button__icon"},[_vm._t("icon")],2):_vm._e(),_vm._t("default"),(_vm.$slots.iconRight)?_c('span',{staticClass:"button__icon-right"},[_vm._t("iconRight")],2):_vm._e(),(_vm.loading)?_c('span',{staticClass:"button__loading"},[_c('BaseSpinner')],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }