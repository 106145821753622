<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="down-chevron-icon"
      d="M4.29289322,7.29289322 C4.68341751,6.90236893 5.31658249,6.90236893 5.70710678,7.29289322 L12.0710678,13.6568542 L18.4350288,7.29289322 C18.8255531,6.90236893 19.4587181,6.90236893 19.8492424,7.29289322 C20.2397667,7.68341751 20.2397667,8.31658249 19.8492424,8.70710678 L12.7781746,15.7781746 C12.3876503,16.1686989 11.7544853,16.1686989 11.363961,15.7781746 L4.29289322,8.70710678 C3.90236893,8.31658249 3.90236893,7.68341751 4.29289322,7.29289322 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseDownChevronIcon"
};
</script>

<style>
.down-chevron-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
