<template>
  <BaseCard>
    <template #title>Blocked</template>
    <template #description>
      These users will be blocked from sending you messages.
    </template>
    <div class="blocked-senders__table">
      <BaseErrorMessage v-if="gettingBlockedUsersFailed">
        <template #description>
          Unable to fetch your blocked senders.
        </template>
      </BaseErrorMessage>
      <BaseNeutralMessage v-else-if="blockedSenders.length < 1">
        <template #description>
          You have not blocked anyone.
        </template>
      </BaseNeutralMessage>
      <BlockedSender
        v-for="blockedSender in blockedSenders"
        :key="blockedSender.Id"
        :blockedSender="blockedSender"
        @remove="removeBlockedSender"
      />
    </div>
  </BaseCard>
</template>

<script>
import BlockedSender from "@/components/account/BlockedSender";
import API, { graphqlOperation } from "@aws-amplify/api";
import { blockedUsersByBlockingUserId } from "@/graphql/queries";
import { deleteBlockedUser } from "@/graphql/mutations";
export default {
  name: "BlockedSenders",
  components: {
    BlockedSender
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      blockedSenders: [],
      gettingBlockedUsersFailed: false
    };
  },
  created() {
    this.getBlockedUsers();
  },
  methods: {
    async getBlockedUsers() {
      try {
        // Attempt to get blocked users from backend.
        const result = await API.graphql(
          graphqlOperation(blockedUsersByBlockingUserId, {
            blockingUserId: this.user.attributes.sub
          })
        );
        // console.log(result.data.blockedUsersByBlockingUserId.items);
        return (this.blockedSenders =
          result.data.blockedUsersByBlockingUserId.items);
      } catch (error) {
        this.gettingBlockedUsersFailed = true;
        return error;
      }
    },
    async removeBlockedSender(blockedSender) {
      try {
        // Attempt to delete blocked user from backend.
        await API.graphql(
          graphqlOperation(deleteBlockedUser, {
            input: {
              blockingUserEmail: this.user.attributes.email,
              blockedUserId: blockedSender.blockedUserId
            }
          })
        );
        // Remove sender from the blockedSenders array.
        let index = this.blockedSenders.indexOf(blockedSender);
        this.blockedSenders.splice(index, 1);
        return;
      } catch (error) {
        return error;
      }
    }
  }
};
</script>
