<template>
  <div class="confirm">
    <BaseCard>
      <h1>Confirm email</h1>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>

<style lang="scss">
.confirm {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
