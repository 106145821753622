/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessageByRecipientEmail = /* GraphQL */ `
  subscription OnCreateMessageByRecipientEmail($recipientEmail: String!) {
    onCreateMessageByRecipientEmail(recipientEmail: $recipientEmail) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageByRecipientEmail = /* GraphQL */ `
  subscription OnUpdateMessageByRecipientEmail($recipientEmail: String!) {
    onUpdateMessageByRecipientEmail(recipientEmail: $recipientEmail) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageByRecipientEmail = /* GraphQL */ `
  subscription OnDeleteMessageByRecipientEmail($recipientEmail: String!) {
    onDeleteMessageByRecipientEmail(recipientEmail: $recipientEmail) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageBySenderEmail = /* GraphQL */ `
  subscription OnCreateMessageBySenderEmail($senderEmail: String!) {
    onCreateMessageBySenderEmail(senderEmail: $senderEmail) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageBySenderEmail = /* GraphQL */ `
  subscription OnUpdateMessageBySenderEmail($senderEmail: String!) {
    onUpdateMessageBySenderEmail(senderEmail: $senderEmail) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageBySenderEmail = /* GraphQL */ `
  subscription OnDeleteMessageBySenderEmail($senderEmail: String!) {
    onDeleteMessageBySenderEmail(senderEmail: $senderEmail) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
