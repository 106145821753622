<template>
  <BaseCard>
    <template #title>Message history</template>
    <template #description>
      Check the status of the messages you have sent and delete messages you'd
      rather not have sent.
    </template>
    List of messages sent (and any replies?) showing status
    (unread/read/deleted) and option to delete.
  </BaseCard>
</template>

<script>
export default {
  name: "MessageHistory"
};
</script>
