<template>
  <div class="send-blocked">
    <BaseCard>
      <BaseErrorMessage>
        <template #title>
          Send blocked
        </template>
        <template #description>
          You have been blocked from sending messages to this address.
        </template>
      </BaseErrorMessage>
      <BaseButtonGroup>
        <BaseButton @click.native="tryAgain" primary pad>
          Send to a different address
        </BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "SendError",
  props: {
    recipientData: {
      type: Object,
      required: true
    }
  },
  methods: {
    tryAgain() {
      this.$emit("tryAgain");
    }
  }
};
</script>

<style lang="scss">
.send-blocked {
  height: 100vh;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
