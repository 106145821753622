<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="view-icon"
      d="M17.56,17.66 C16.0594539,19.1622163 14.023274,20.0062933 11.9,20.0062933 C9.77672602,20.0062933 7.74054606,19.1622163 6.24,17.66 L1.3,12.7 C1.11068735,12.5122334 1.00420168,12.2566375 1.00420168,11.99 C1.00420168,11.7233625 1.11068735,11.4677666 1.3,11.28 L6.25,6.33 C7.75054606,4.82778366 9.78672602,3.98370675 11.91,3.98370675 C14.033274,3.98370675 16.0694539,4.82778366 17.57,6.33 L22.52,11.28 C22.7093127,11.4677666 22.8157983,11.7233625 22.8157983,11.99 C22.8157983,12.2566375 22.7093127,12.5122334 22.52,12.7 L17.57,17.65 L17.56,17.66 Z M7.66,16.24 C10.0025404,18.579627 13.7974596,18.579627 16.14,16.24 L20.38,12 L16.14,7.76 C13.7974596,5.42037298 10.0025404,5.42037298 7.66,7.76 L3.4,12 L7.65,16.24 L7.66,16.24 Z M11.9,16 C9.690861,16 7.9,14.209139 7.9,12 C7.9,9.790861 9.690861,8 11.9,8 C14.109139,8 15.9,9.790861 15.9,12 C15.9,14.209139 14.109139,16 11.9,16 Z M11.9,14 C13.0045695,14 13.9,13.1045695 13.9,12 C13.9,10.8954305 13.0045695,10 11.9,10 C10.7954305,10 9.9,10.8954305 9.9,12 C9.9,13.1045695 10.7954305,14 11.9,14 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseViewIcon"
};
</script>

<style>
.view-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
