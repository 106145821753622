<template>
  <div class="modal-view__overlay" @click="closeView">
    <div class="modal-view__dialog" @click.stop>
      <div class="modal-view__header">
        <div class="modal-view__header-left">
          <BaseButton
            v-if="back"
            secondary
            iconOnly
            @click.native="backToPreviousView"
          >
            <template #icon>
              <BaseLeftChevronIcon />
            </template>
          </BaseButton>
        </div>
        <div class="modal-view__header-center" v-if="$slots.title">
          <h3 class="modal-view__title">
            <slot name="title" />
          </h3>
        </div>
        <div class="modal-view__header-right">
          <BaseButton v-if="close" secondary iconOnly @click.native="closeView">
            <template #icon>
              <BaseCloseIcon />
            </template>
          </BaseButton>
          <BaseButton v-if="help" secondary iconOnly @click.native="getHelp">
            <template #icon>
              <BaseHelpIcon />
            </template>
          </BaseButton>
        </div>
      </div>
      <div class="modal-view__body">
        <slot />
      </div>
      <div class="modal-view__footer" v-if="$slots.footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalView",
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    back: {
      type: Boolean,
      required: false,
      default: false
    },
    close: {
      type: Boolean,
      required: false,
      default: false
    },
    help: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    document.body.classList.add("--modal-is-open");
  },
  mounted() {
    document.addEventListener("keydown", e => {
      if (e.keyCode === 27) {
        this.closeView();
      }
    });
  },
  beforeDestroy() {
    document.body.classList.remove("--modal-is-open");
  },
  methods: {
    backToPreviousView() {
      this.$emit("back");
    },
    // NB: We're using `closeView` here because we already have a prop named `close`.
    closeView() {
      this.$emit("close");
    },
    getHelp() {
      this.$emit("help");
    }
  }
};
</script>

<style lang="scss">
body.--modal-is-open {
  overflow: hidden !important;
}
.modal-view__overlay {
  background-color: rgba(#000, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 90;
}
.modal-view__dialog {
  max-width: 30rem;
  margin: 4rem auto 0;
  background-color: rgba(#fff, 1);
  -webkit-backdrop-filter: $backdrop-blur;
  backdrop-filter: $backdrop-blur;
  box-shadow: $base-box-shadow;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  @include responsive(small) {
    margin: 2rem auto;
    border-radius: 1rem;
  }
  @include responsive(medium) {
    max-width: 36rem;
    margin: 3rem auto;
  }
  @include responsive(large) {
    max-width: 40rem;
    margin: 4rem auto;
  }
}
.modal-view__header {
  width: 100%;
  min-height: 5rem;
  padding: 1rem 1rem 1rem;
  background-color: rgba(#fff, 1);
  border-radius: 1rem 1rem 0 0;
  border-bottom: $base-border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 20;
  @include responsive(small) {
    padding: 1.5rem 1.5rem 1rem;
  }
}
@supports (padding: max(0px)) {
  .modal-view__header {
    padding-top: max(1rem, calc(env(safe-area-inset-top) + 1rem));
  }
}
.modal-view__header-left,
.modal-view__header-right {
  min-width: 40px;
  @include responsive(small) {
    min-width: 48px;
  }
}
.modal-view__header-center {
  overflow: hidden;
}
.modal-view__title {
  margin: 0;
  padding: 0;
  font-size: 1.375rem;
  font-weight: 600;
  @include truncate;
  @include responsive(small) {
    font-size: 1.5rem;
  }
}
.modal-view__body {
  min-height: calc(100vh - 15rem);
  padding: 1rem 1.25rem;
  overflow-y: scroll; // Make the modal content scrollable if necessary
  -webkit-overflow-scrolling: touch; // Use momentum-based scrolling
  scroll-behavior: smooth;
  @include responsive(small) {
    min-height: calc(100vh - 15rem);
    padding: 1rem 1.5rem;
  }
  @include responsive(medium) {
    min-height: calc(100vh - 17rem);
  }
  @include responsive(large) {
    min-height: calc(100vh - 19rem);
  }
}
.modal-view__footer {
  width: 100%;
  min-height: 5rem;
  padding: 1.5rem;
  border-top: $base-border;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 20;
}
</style>
