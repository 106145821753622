<template>
  <div class="message-sent">
    <div class="message-sent__message-decode-status">
      <div class="message-sent__message-decode-status-badge">
        <span
          class="message-sent__message-in-review-indicator"
          v-if="message.inReview"
          title="In review"
        />
        <BaseTickIcon v-else-if="message.decodedAt" />
        <span
          class="message-sent__message-unread-indicator"
          v-else
          title="Unread"
        />
      </div>
    </div>
    <div class="message-sent__message-details" @click="viewMessage">
      <div class="message-sent__primary-message-details">
        <div class="message-sent__message-metadata">
          <div class="message-sent__message-metadata-group">
            <div class="message-sent__recipient-name">
              {{ message.recipientName }}
            </div>
            <div class="message-sent__recipient-email">
              {{ message.recipientEmail }}
            </div>
          </div>
          <div class="message-sent__message-sent-at">
            {{ dateTimeSent }}
          </div>
        </div>
        <div class="message-sent__message-content">
          <div class="message-sent__message-plaintext">
            {{ message.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="message-sent__message-actions">
      <MessageSentActions
        @viewMessage="viewMessage"
        @deleteMessage="deleteMessage"
        @showSecretKey="showSecretKey"
      />
    </div>
    <MessagePreview
      v-if="showMessagePreview"
      :message="message"
      @done="closeMessagePreview"
    />
  </div>
</template>

<script>
import MessagePreview from "@/components/MessagePreview";
import MessageSentActions from "@/components/MessageSentActions";
const { DateTime } = require("luxon");

export default {
  name: "MessageSent",
  components: {
    MessagePreview,
    MessageSentActions
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showMessagePreview: false
    };
  },
  computed: {
    dateTimeSent() {
      const dt = DateTime.fromISO(this.message.createdAt);
      return dt.toLocaleString();
    }
  },
  methods: {
    closeMessagePreview() {
      this.showMessagePreview = false;
    },
    viewMessage() {
      this.showMessagePreview = true;
    },
    deleteMessage() {
      // TODO: Add a confirmation modal before deleting
      this.$emit("delete", this.message);
    },
    showSecretKey() {
      // TODO
    }
  }
};
</script>

<style lang="scss">
.message-sent {
  padding: 0.75rem 0;
  border-bottom: $base-border;
  display: grid;
  grid-template-columns: 2.5rem 1fr 3rem;
  @include responsive(small) {
    padding: 1rem 0;
    grid-template-columns: 3rem 1fr 3.5rem;
  }
}
.message-sent:last-child {
  border-bottom: none;
}
.message-sent__message-decode-status {
  display: flex;
  align-items: center;
}
.message-sent__message-decode-status-badge {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border: 1px solid #dedfe3;
  border-radius: 1.5rem;
  color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive(small) {
    width: 1.75rem;
    height: 1.75rem;
  }
}
.message-sent__message-in-review-indicator {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #f95959;
  border-radius: 4px;
}
.message-sent__message-unread-indicator {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #0baaf3;
  border-radius: 4px;
}
.message-sent__message-details {
  // flex-grow: 1;
  overflow: hidden; // Prevent flex from breaking
  display: flex;
  flex-direction: column;
}
.message-sent__message-details:hover {
  cursor: pointer;
}
.message-sent__primary-message-details {
  // flex-grow: 1;
}
.message-sent__message-metadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.message-sent__message-metadata-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // flex: 1 1 100px;
  @include responsive(small) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}
.message-sent__recipient-name {
  margin-right: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: capitalize;
  @include truncate;
  @include responsive(small) {
    margin-right: 0.75rem;
  }
}
.message-sent__recipient-email {
  margin-right: 0.5rem;
  font-size: 0.85rem;
  color: #8b8e9e;
  @include truncate;
  @include responsive(small) {
    margin-right: 0.75rem;
    // font-size: 1rem;
  }
}
.message-sent__message-sent-at {
  font-size: 0.85rem;
  color: #8b8e9e;
  // flex: 1 1 200px;
}
.message-sent__message-content {
  margin: 0.25rem 0;
  padding: 0;
  @include responsive(small) {
    margin: 0.5rem 0;
  }
}
.message-sent__message-decoded {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.message-sent__message-plaintext {
  font-size: 1rem;
  // @include truncate;
}
.message-sent__message-actions {
  width: auto;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
