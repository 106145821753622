<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      fill="#4285F4"
      fill-rule="nonzero"
      d="M23.4945055,12.2813187 C23.4945055,11.4681319 23.4285714,10.6505495 23.2879121,9.85054945 L12.0043956,9.85054945 L12.0043956,14.4571429 L18.4659341,14.4571429 C18.1978022,15.9428571 17.3362637,17.2571429 16.0747253,18.0923077 L16.0747253,21.0813187 L19.9296703,21.0813187 C22.1934066,18.9978022 23.4945055,15.9208791 23.4945055,12.2813187 Z"
    />
    <path
      fill="#34A853"
      fill-rule="nonzero"
      d="M12.0043956,23.9692308 C15.2307692,23.9692308 17.9516484,22.9098901 19.9340659,21.0813187 L16.0791209,18.0923077 C15.0065934,18.821978 13.621978,19.2351648 12.0087912,19.2351648 C8.88791209,19.2351648 6.24175824,17.1296703 5.29230769,14.2989011 L1.31428571,14.2989011 L1.31428571,17.3802198 C3.34505495,21.4197802 7.48131868,23.9692308 12.0043956,23.9692308 Z"
    />
    <path
      fill="#FBBC04"
      fill-rule="nonzero"
      d="M5.28791209,14.2989011 C4.78681319,12.8131868 4.78681319,11.2043956 5.28791209,9.71868132 L5.28791209,6.63736264 L1.31428571,6.63736264 C-0.382417582,10.0175824 -0.382417582,14 1.31428571,17.3802198 L5.28791209,14.2989011 L5.28791209,14.2989011 Z"
    />
    <path
      fill="#EA4335"
      fill-rule="nonzero"
      d="M12.0043956,4.77802198 C13.7098901,4.75164835 15.3582418,5.39340659 16.5934066,6.57142857 L20.0087912,3.15604396 C17.8461538,1.12527473 14.9758242,0.00879120879 12.0043956,0.043956044 C7.48131868,0.043956044 3.34505495,2.59340659 1.31428571,6.63736264 L5.28791209,9.71868132 C6.23296703,6.88351648 8.88351648,4.77802198 12.0043956,4.77802198 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseGoogleLogoIcon"
};
</script>

<style>
.google-logo-icon {
  width: 24px;
  height: 24px;
}
</style>
