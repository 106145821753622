<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="mobile-icon"
      d="M8,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.1045695,22 16,22 L8,22 C6.8954305,22 6,21.1045695 6,20 L6,4 C6,2.9 6.9,2 8,2 Z M8,4 L8,20 L16,20 L16,4 L8,4 Z M12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseMobileIcon"
};
</script>

<style>
.mobile-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
