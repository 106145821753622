<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="menu-icon"
      d="M15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 L3,20 C2.44771525,20 2,19.5522847 2,19 C2,18.4477153 2.44771525,18 3,18 L15,18 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M17,4 C17.5522847,4 18,4.44771525 18,5 C18,5.55228475 17.5522847,6 17,6 L3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 L17,4 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseMenuIcon"
};
</script>

<style>
.menu-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
