export const getBlockedUserCustom = /* GraphQL */ `
  query GetBlockedUser($blockingUserEmail: String!, $blockedUserId: String!) {
    getBlockedUser(
      blockingUserEmail: $blockingUserEmail
      blockedUserId: $blockedUserId
    ) {
      id
    }
  }
`;
