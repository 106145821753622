<template>
  <div class="send-success">
    <BaseCard>
      <BaseSuccessMessage>
        <template #title>
          Success
        </template>
        <template #description>
          Your message has been sent to {{ recipientData.recipientName }} at
          {{ recipientData.recipientEmail }}.
        </template>
      </BaseSuccessMessage>
      <BaseButtonGroup>
        <BaseButton @click.native="done" primary pad>
          Done
        </BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "SendSuccess",
  props: {
    recipientData: {
      type: Object,
      required: true
    }
  },
  methods: {
    done() {
      this.$emit("done");
    }
  }
};
</script>

<style lang="scss">
.send-success {
  height: 100vh;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
