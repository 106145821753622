<template>
  <div class="form__field">
    <label
      :for="id"
      class="form__label"
      :class="{ 'form__label--is-hidden': hideLabel }"
    >
      {{ label }}
    </label>
    <div class="select__wrapper">
      <select
        :id="id"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        class="select"
        @change="$emit('input', $event.target.value)"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{ option.name || "No name" }}
        </option>
      </select>
      <BaseDownChevronIcon class="select__arrow" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    options: {
      type: Array,
      required: true,
      validator(options) {
        return !options.find(option => typeof option !== "object");
      }
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    isSuccess: {
      type: Boolean,
      default: false,
      required: false
    },
    isWarning: {
      type: Boolean,
      default: false,
      required: false
    },
    isError: {
      type: Boolean,
      default: false,
      required: false
    },
    hideLabel: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>

<style lang="scss">
.select__wrapper {
  position: relative;
}
.select {
  width: 100%;
  height: 2.5rem;
  padding: 0 0.75rem;
  background-color: #ebecef;
  border: none;
  border-radius: 0.5rem;
  font-family: $base-font-family;
  font-size: 1rem;
  font-weight: 400;
  color: #202127;
  line-height: 1.5;
  outline: none;
  -webkit-appearance: none;
  @include responsive(small) {
    height: 3rem;
    padding: 0 1rem;
  }
  &:focus:not([readonly]) {
    // @include focus-highlight;
  }
  &[readonly] {
    cursor: not-allowed;
  }
}
.select__arrow {
  position: absolute;
  right: 0.75rem;
  top: calc(50% - 12px);
  pointer-events: none;
}

.form__field {
  width: 100%;
  margin: 1rem 0;
  position: relative; // So we can position error messages abaolutely
}
.form__label {
  display: block;
  padding: 0.25rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.form__label--is-hidden {
  display: none;
}
.form-group--state-success * .select {
  // border: $border-success;
}
.form-group--state-warning * .select {
  // border: $border-warning;
}
.form-group--state-error * .select {
  // border: $border-danger;
}
</style>
