/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setMessageDecodedAt = /* GraphQL */ `
  mutation SetMessageDecodedAt($decodedAt: AWSTimestamp!) {
    setMessageDecodedAt(decodedAt: $decodedAt) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      senderId
      senderName
      senderEmail
      recipientName
      recipientEmail
      stickerPack
      content
      secretKey
      decodedAt
      inReview
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const createMessageReport = /* GraphQL */ `
  mutation CreateMessageReport(
    $input: CreateMessageReportInput!
    $condition: ModelMessageReportConditionInput
  ) {
    createMessageReport(input: $input, condition: $condition) {
      id
      messageId
      senderId
      senderName
      senderEmail
      plainText
      reason
      other
      messageUrl
      secretKey
      reviewerName
      reviewResult
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageReport = /* GraphQL */ `
  mutation UpdateMessageReport(
    $input: UpdateMessageReportInput!
    $condition: ModelMessageReportConditionInput
  ) {
    updateMessageReport(input: $input, condition: $condition) {
      id
      messageId
      senderId
      senderName
      senderEmail
      plainText
      reason
      other
      messageUrl
      secretKey
      reviewerName
      reviewResult
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageReport = /* GraphQL */ `
  mutation DeleteMessageReport(
    $input: DeleteMessageReportInput!
    $condition: ModelMessageReportConditionInput
  ) {
    deleteMessageReport(input: $input, condition: $condition) {
      id
      messageId
      senderId
      senderName
      senderEmail
      plainText
      reason
      other
      messageUrl
      secretKey
      reviewerName
      reviewResult
      createdAt
      updatedAt
    }
  }
`;
export const createBlockedUser = /* GraphQL */ `
  mutation CreateBlockedUser(
    $input: CreateBlockedUserInput!
    $condition: ModelBlockedUserConditionInput
  ) {
    createBlockedUser(input: $input, condition: $condition) {
      id
      blockedUserId
      blockedUserName
      blockedUserEmail
      blockingUserId
      blockingUserEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateBlockedUser = /* GraphQL */ `
  mutation UpdateBlockedUser(
    $input: UpdateBlockedUserInput!
    $condition: ModelBlockedUserConditionInput
  ) {
    updateBlockedUser(input: $input, condition: $condition) {
      id
      blockedUserId
      blockedUserName
      blockedUserEmail
      blockingUserId
      blockingUserEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlockedUser = /* GraphQL */ `
  mutation DeleteBlockedUser(
    $input: DeleteBlockedUserInput!
    $condition: ModelBlockedUserConditionInput
  ) {
    deleteBlockedUser(input: $input, condition: $condition) {
      id
      blockedUserId
      blockedUserName
      blockedUserEmail
      blockingUserId
      blockingUserEmail
      createdAt
      updatedAt
    }
  }
`;
