<template>
  <div class="message-preview-characters-character">
    <BaseSticker1 v-if="glyphCipherCode === 1" :stickerPack="stickerPack" />
    <BaseSticker2 v-if="glyphCipherCode === 2" :stickerPack="stickerPack" />
    <BaseSticker3 v-if="glyphCipherCode === 3" :stickerPack="stickerPack" />
    <BaseSticker4 v-if="glyphCipherCode === 4" :stickerPack="stickerPack" />
    <BaseSticker5 v-if="glyphCipherCode === 5" :stickerPack="stickerPack" />
    <BaseSticker6 v-if="glyphCipherCode === 6" :stickerPack="stickerPack" />
    <BaseSticker7 v-if="glyphCipherCode === 7" :stickerPack="stickerPack" />
    <BaseSticker8 v-if="glyphCipherCode === 8" :stickerPack="stickerPack" />
    <BaseSticker9 v-if="glyphCipherCode === 9" :stickerPack="stickerPack" />
    <BaseSticker10 v-if="glyphCipherCode === 10" :stickerPack="stickerPack" />
    <BaseSticker11 v-if="glyphCipherCode === 11" :stickerPack="stickerPack" />
    <BaseSticker12 v-if="glyphCipherCode === 12" :stickerPack="stickerPack" />
    <BaseSticker13 v-if="glyphCipherCode === 13" :stickerPack="stickerPack" />
    <BaseSticker14 v-if="glyphCipherCode === 14" :stickerPack="stickerPack" />
    <BaseSticker15 v-if="glyphCipherCode === 15" :stickerPack="stickerPack" />
    <BaseSticker16 v-if="glyphCipherCode === 16" :stickerPack="stickerPack" />
    <BaseSticker17 v-if="glyphCipherCode === 17" :stickerPack="stickerPack" />
    <BaseSticker18 v-if="glyphCipherCode === 18" :stickerPack="stickerPack" />
    <BaseSticker19 v-if="glyphCipherCode === 19" :stickerPack="stickerPack" />
    <BaseSticker20 v-if="glyphCipherCode === 20" :stickerPack="stickerPack" />
    <BaseSticker21 v-if="glyphCipherCode === 21" :stickerPack="stickerPack" />
    <BaseSticker22 v-if="glyphCipherCode === 22" :stickerPack="stickerPack" />
    <BaseSticker23 v-if="glyphCipherCode === 23" :stickerPack="stickerPack" />
    <BaseSticker24 v-if="glyphCipherCode === 24" :stickerPack="stickerPack" />
    <BaseSticker25 v-if="glyphCipherCode === 25" :stickerPack="stickerPack" />
    <BaseSticker26 v-if="glyphCipherCode === 26" :stickerPack="stickerPack" />
    <BaseSticker27 v-if="glyphCipherCode === 27" :stickerPack="stickerPack" />
    <BaseSticker28 v-if="glyphCipherCode === 28" :stickerPack="stickerPack" />
    <BaseSticker29 v-if="glyphCipherCode === 29" :stickerPack="stickerPack" />
    <BaseSticker30 v-if="glyphCipherCode === 30" :stickerPack="stickerPack" />
    <BaseSticker31 v-if="glyphCipherCode === 31" :stickerPack="stickerPack" />
    <BaseSticker32 v-if="glyphCipherCode === 32" :stickerPack="stickerPack" />
    <BaseSticker33 v-if="glyphCipherCode === 33" :stickerPack="stickerPack" />
    <BaseSticker34 v-if="glyphCipherCode === 34" :stickerPack="stickerPack" />
    <BaseSticker35 v-if="glyphCipherCode === 35" :stickerPack="stickerPack" />
    <BaseSticker36 v-if="glyphCipherCode === 36" :stickerPack="stickerPack" />
    <BaseSticker37 v-if="glyphCipherCode === 37" :stickerPack="stickerPack" />
    <BaseSticker38 v-if="glyphCipherCode === 38" :stickerPack="stickerPack" />
    <BaseSticker39 v-if="glyphCipherCode === 39" :stickerPack="stickerPack" />
    <BaseSticker40 v-if="glyphCipherCode === 40" :stickerPack="stickerPack" />
  </div>
</template>

<script>
export default {
  name: "MessagePreviewCharactersCharacter",
  props: {
    character: {
      type: Object,
      required: true
    },
    glyphCipherCode: {
      type: Number,
      required: true
    },
    stickerPack: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.message-preview-characters-character {
  margin-bottom: 0.25rem;
  list-style: none;
  display: flex;
  transition: all 0.2s ease-in-out;
}
.message-preview-characters-character > .sticker {
  width: 2.5rem;
  height: 2.5rem;
  @include responsive(small) {
    width: 3rem;
    height: 3rem;
  }
}
</style>
