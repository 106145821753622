<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="compose-icon"
      d="M6.3,11.3 L16.3,1.3 C16.6888435,0.918857124 17.3111565,0.918857124 17.7,1.3 L21.7,5.3 C22.0811429,5.68884351 22.0811429,6.31115649 21.7,6.7 L11.7,16.7 C11.5153695,16.8884143 11.2637693,16.996243 11,17 L7,17 C6.44771525,17 6,16.5522847 6,16 L6,12 C6.00375705,11.7362307 6.11158574,11.4846305 6.3,11.3 L6.3,11.3 Z M8,15 L10.59,15 L19.59,6 L17,3.41 L8,12.41 L8,15 Z M18,13 C18,12.4477153 18.4477153,12 19,12 C19.5522847,12 20,12.4477153 20,13 L20,19 C20,20.1045695 19.1045695,21 18,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,5 C2,3.9 2.9,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 C11,4.55228475 10.5522847,5 10,5 L4,5 L4,19 L18,19 L18,13 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseComposeIcon"
};
</script>

<style>
.compose-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
