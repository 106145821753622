<template>
  <div class="form__field">
    <label
      :for="id"
      class="form__label"
      :class="{ 'form__label--is-hidden': hideLabel }"
      >{{ label }}</label
    >
    <textarea
      :id="id"
      ref="textarea"
      class="textarea"
      :rows="rows"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :autocomplete="autocomplete"
      :autocapitalize="autocapitalize"
      v-autosize="autosize"
      :value="value"
      @focus="$emit('focus')"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    rows: {
      type: String,
      required: false,
      default: "2"
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    autocomplete: {
      type: String,
      default: "off",
      required: false
    },
    autocapitalize: {
      type: String,
      default: "none",
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    autosize: {
      type: Boolean,
      default: false,
      required: false
    },
    isSuccess: {
      type: Boolean,
      default: false,
      required: false
    },
    isWarning: {
      type: Boolean,
      default: false,
      required: false
    },
    isError: {
      type: Boolean,
      default: false,
      required: false
    },
    hideLabel: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    // Used to focus the textarea el from the parent
    focus: function() {
      this.$refs.textarea.focus();
    }
  }
};
</script>

<style lang="scss">
.textarea {
  width: 100%;
  padding: 0.5rem 0.75rem;
  background-color: #ebecef;
  box-sizing: border-box;
  border: none;
  border-radius: 0.5rem;
  font-family: $base-font-family;
  font-size: 1rem;
  font-weight: 400;
  color: #202127;
  line-height: 1.5;
  outline: none;
  -webkit-appearance: none;
  // transition-delay: 1s;
  // transition: $trans-all-medium;
  @include responsive(small) {
    padding: 0.75rem 1rem;
  }
  &:focus:not([readonly]) {
    @include focus-highlight;
  }
  &[readonly] {
    cursor: not-allowed;
  }
}
.textarea:focus {
  @include focus-highlight;
}
.textarea::selection {
  background-color: #d5ebde;
}

.form-group--state-success * .textarea {
  // border: $border-success;
}
.form-group--state-warning * .textarea {
  // border: $border-warning;
}
.form-group--state-error * .textarea {
  // border: $border-danger;
}
</style>
