<template>
  <div class="cipher-text">
    <h2 class="cipher-text__heading">Message</h2>
    <div class="cipher-text__characters">
      <CipherTextCharacter
        v-for="(character, index) in messageChars"
        :key="index"
        :character="character"
        :glyphCipherCode="glyphCipherCode(character.glyph)"
        :stickerPack="stickerPack"
        @click.native="selectCipherTextCharacter(index)"
        @stickerAdded="stickerAdded"
        @stickerRemoved="stickerRemoved"
      />
    </div>
  </div>
</template>

<script>
import CipherTextCharacter from "@/components/CipherTextCharacter";
export default {
  name: "CipherText",
  components: {
    CipherTextCharacter
  },
  props: {
    messageChars: {
      type: Array,
      required: true
    },
    cipher: {
      type: Array,
      required: true
    },
    stickerPack: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      audioContext: null
    };
  },
  mounted() {},
  methods: {
    // Get the cipher code for a specified glyph
    glyphCipherCode(g) {
      let glyphCipher = this.cipher.find(({ glyph }) => glyph === g);
      // alert(glyphCipher.code);
      return glyphCipher.code;
    },
    selectCipherTextCharacter(charIndex) {
      // console.log("charIndex: " + charIndex);
      this.$emit("selectCipherTextCharacter", charIndex);
    },
    stickerAdded() {
      this.$emit("stickerAdded");
    },
    stickerRemoved() {
      this.$emit("stickerRemoved");
    }
  }
};
</script>

<style lang="scss">
.cipher-text {
  padding: 0.5rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @include responsive(small) {
    padding: 1.5rem 0;
  }
  @include responsive(large) {
    align-items: center;
  }
}
.cipher-text__heading {
  display: none;
}
.cipher-text__characters {
  // border: 1px solid red;
  display: flex;
  flex-wrap: wrap;
}

@media print {
  .cipher-text {
    max-width: auto;
    margin: 0;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
  }
  .cipher-text__heading {
    margin: 1rem 0;
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
  }
  .cipher-text__characters {
    padding-top: 0;
  }
}
</style>
