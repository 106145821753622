<template>
  <div class="block">
    <BlockUserAlreadyBlocked
      v-if="activeComponent === 'UserAlreadyBlocked'"
      @close="close"
    />
    <BlockUserConfirm
      v-else-if="activeComponent === 'BlockUserConfirm'"
      :message="message"
      @blockSuccess="blockSuccess"
      @blockError="blockError"
      @userAlreadyBlocked="userAlreadyBlocked"
      @cancel="close"
    />
    <BlockUserSuccess
      v-else-if="activeComponent === 'BlockUserSuccess'"
      @done="done"
    />
    <BlockUserError
      v-else-if="activeComponent === 'BlockUserError'"
      @cancel="close"
      @tryAgain="tryAgain"
    />
    <MessageLoadingError
      v-else-if="activeComponent === 'MessageLoadingError'"
    />
    <Loading v-else />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import MessageLoadingError from "@/components/MessageLoadingError";
import BlockUserConfirm from "@/components/BlockUserConfirm";
import BlockUserAlreadyBlocked from "@/components/BlockUserAlreadyBlocked";
import BlockUserSuccess from "@/components/BlockUserSuccess";
import BlockUserError from "@/components/BlockUserError";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getMessage } from "@/graphql/queries";

export default {
  name: "Block",
  components: {
    Loading,
    MessageLoadingError,
    BlockUserConfirm,
    BlockUserAlreadyBlocked,
    BlockUserSuccess,
    BlockUserError
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeComponent: "Loading",
      message: null
    };
  },
  async created() {
    await this.getMessage();
  },
  methods: {
    close() {
      this.$router.push("/read/" + this.id);
    },
    done() {
      this.$router.push("/");
    },
    tryAgain() {
      this.activeComponent = "BlockUserConfirm";
    },
    blockSuccess() {
      this.activeComponent = "BlockUserSuccess";
    },
    blockError() {
      this.activeComponent = "BlockUserError";
    },
    userAlreadyBlocked() {
      this.activeComponent = "UserAlreadyBlocked";
    },
    async getMessage() {
      this.activeComponent = "Loading";
      const message = await API.graphql(
        graphqlOperation(getMessage, { id: this.id })
      );
      this.message = message.data.getMessage;
      if (this.message == null) {
        this.activeComponent = "MessageLoadingError";
      } else {
        this.activeComponent = "BlockUserConfirm";
      }
    }
  }
};
</script>

<style lang="scss">
.block {
  padding: 1.25rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.block__wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.block__actions {
  margin: 1rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.block__actions .button {
  margin-left: 0.5rem;
}
</style>
