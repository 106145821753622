<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="link-icon"
      d="M14.5355113,9.46449775 C14.9260331,9.85502454 14.926029,10.4881895 14.5355023,10.8787113 C14.1449755,11.2692331 13.5118105,11.269229 13.1212887,10.8787023 C11.996577,9.75397617 10.201048,9.70898713 9.02266693,10.7437391 L8.87867678,10.8787068 L4.87867904,14.8787045 C3.70711032,16.0502807 3.70711032,17.9497193 4.87867678,19.1212932 C6.00338946,20.2460059 7.79895258,20.2909944 8.97735317,19.2562389 L9.12134569,19.1212708 L10.2229157,18.0197708 C10.6134524,17.6292589 11.2466174,17.629279 11.6371292,18.0198157 C11.9976018,18.3803111 12.0253133,18.947543 11.7202759,19.3398246 L11.6370843,19.4340292 L10.5355368,20.5355068 C8.58291249,22.4881311 5.41708751,22.4881311 3.46446096,20.5355045 C1.57101972,18.6420512 1.51364271,15.6078696 3.292332,13.6452791 L3.46446322,13.4644932 L7.46445869,9.46449775 C9.41708402,7.51184742 12.582886,7.51184742 14.5355113,9.46449775 Z M20.5355068,3.46446322 C22.4289606,5.35791708 22.486338,8.39215836 20.7076365,10.3547533 L20.5355041,10.5355394 L16.5355068,14.5355068 C14.5828825,16.4881311 11.4171175,16.4881311 9.46449322,14.5355068 C9.07396893,14.1449825 9.07396893,13.5118175 9.46449322,13.1212932 C9.85501751,12.7307689 10.4881825,12.7307689 10.8787068,13.1212932 C12.0034195,14.2460059 13.7989273,14.2909944 14.9773059,13.2562564 L15.1212959,13.1212906 L19.1212932,9.12132322 C20.2928689,7.94974751 20.2928689,6.05025249 19.1212955,4.87867904 C17.9965823,3.75397307 16.2010712,3.70898483 15.0226991,4.74370948 L14.87871,4.87867357 L13.77911,5.97828357 C13.3885875,6.36880963 12.7554225,6.36881251 12.3648964,5.97829 C12.0044108,5.61780767 11.9766787,5.05057674 12.2817018,4.65828415 L12.36489,4.56407643 L13.4644955,3.46446096 C15.4171193,1.51184968 18.5828807,1.51184968 20.5355068,3.46446322 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseLinkIcon"
};
</script>

<style>
.link-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
