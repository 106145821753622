<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="exclamation-icon"
      d="M12 9V11M12 15H12.01M5.07183 19H18.9282C20.4678 19 21.4301 17.3333 20.6603 16L13.7321 4C12.9623 2.66667 11.0378 2.66667 10.268 4L3.33978 16C2.56998 17.3333 3.53223 19 5.07183 19Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseExclamationIcon"
};
</script>

<style>
.exclamation-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
