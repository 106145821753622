<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="inbox-icon"
      d="M18,3 C19.5977025,3 20.9036624,4.24891202 20.9949074,5.82372636 L21,6 L21,18 C21,19.5977103 19.7511084,20.903663 18.1762758,20.9949074 L18,21 L6,21 C4.40231088,21 3.09633853,19.7511009 3.00509266,18.176275 L3,18 L3,6 C3,4.40231863 4.2489195,3.09633909 5.82372716,3.00509269 L6,3 L18,3 Z M5,18 C5,18.5128524 5.38602882,18.9355095 5.88337644,18.9932725 L6,19 L18,19 C18.5128642,19 18.9355112,18.6139861 18.9932727,18.1166268 L19,18 L19,14 L17.4142,14 L15,16.4142068 C14.6666364,16.7475772 14.2276151,16.9511381 13.7616473,16.9922581 L13.5858,17 L10.4142,17 C9.94274325,17 9.48843156,16.8334934 9.12983834,16.5330786 L9,16.4142097 L6.58583979,14.0000633 L5,14 L5,18 Z M18,5 L6,5 C5.4871637,5 5.06449277,5.38603976 5.00672772,5.88337878 L5,6 L5,12 L6.58579,12 C7.05725328,12 7.51156631,12.1665051 7.87016107,12.4669209 L8,12.5857903 L10.4142,15 L13.5857932,15 L16,12.5857932 C16.3333636,12.2524228 16.7723849,12.0488619 17.2383527,12.0077419 L17.4142,12 L19,12 L19,6 C19,5.48715192 18.6139752,5.06449109 18.1166244,5.00672754 L18,5 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseInboxIcon"
};
</script>

<style>
.inbox-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
