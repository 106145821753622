<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="tick-icon"
      d="M4.22182541,13.5355339 C3.83130112,13.1450096 3.83130112,12.5118446 4.22182541,12.1213203 C4.6123497,11.7307961 5.24551468,11.7307961 5.63603897,12.1213203 L9.17157288,15.6568542 L18.363961,6.46446609 C18.7544853,6.0739418 19.3876503,6.0739418 19.7781746,6.46446609 C20.1686989,6.85499039 20.1686989,7.48815536 19.7781746,7.87867966 L9.87867966,17.7781746 C9.51604996,18.1408043 8.94420456,18.1667064 8.55168691,17.855881 L8.46446609,17.7781746 L4.22182541,13.5355339 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseTickIcon"
};
</script>

<style>
.tick-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
