<template>
  <div class="sticker-pack-selector">
    <BaseDropdown title="Sticker Packs" position="above" primary>
      <template #dropdownButtonLabel>
        <span class="sticker-pack-selector__selected-pack">
          <BaseSticker1 :stickerPack="selectedStickerPack.id" small />
        </span>
      </template>
      <template #dropdownButtonIcon>
        <BaseDownChevronIcon />
      </template>
      <template #dropdownContent>
        <header class="sticker-pack-selector__header">
          <div class="sticker-pack-selector__title">
            Sticker Packs
          </div>
          <BaseButton
            primary
            iconOnly
            small
            @click.native="showStickerPackList"
          >
            <BaseAddIcon />
          </BaseButton>
        </header>
        <ul class="sticker-pack-selector__options">
          <li
            class="sticker-pack-selector__option"
            :class="{
              'sticker-pack-selector__option--is-selected':
                selectedStickerPack.id === stickerPack.id
            }"
            v-for="stickerPack in stickerPacks"
            :key="stickerPack.id"
            @click="selectStickerPack(stickerPack)"
          >
            <div class="sticker-pack-selector__pack-details">
              <div class="sticker-pack-selector__pack-name">
                {{ stickerPack.name }}
              </div>
              <span class="sticker-pack-selector__pack-sample">
                <BaseSticker1 :stickerPack="stickerPack.id" small />
                <BaseSticker2 :stickerPack="stickerPack.id" small />
                <BaseSticker3 :stickerPack="stickerPack.id" small />
                <BaseSticker4 :stickerPack="stickerPack.id" small />
                <BaseSticker5 :stickerPack="stickerPack.id" small />
              </span>
            </div>
            <div
              class="sticker-pack-selector__pack-selected-icon"
              v-if="selectedStickerPack.id === stickerPack.id"
            >
              <BaseTickIcon />
            </div>
          </li>
        </ul>
      </template>
    </BaseDropdown>
  </div>
</template>

<script>
import { Plugins } from "@capacitor/core";

export default {
  name: "StickerPackSelector",
  data() {
    return {
      platform: "",
      selectedStickerPack: {
        id: "emoji",
        name: "Emoji Pack"
      },
      stickerPacks: [
        {
          id: "emoji",
          name: "Emoji Pack",
          status: "released",
          purchased: true
        }
      ]
    };
  },
  created() {
    this.getPlatform();
  },
  methods: {
    async getPlatform() {
      const { Device } = Plugins;
      let device = await Device.getInfo();
      // Should return either 'web', 'ios' or 'android'
      return (this.platform = device.platform);
    },
    showStickerPackList() {
      this.$emit("listStickerPacks");
    },
    selectStickerPack(pack) {
      if (pack.status === "released" && pack.purchased === true) {
        // Has a different pack been selected?
        if (this.selectedStickerPack.id !== pack.id) {
          this.selectedStickerPack = pack;
          this.$emit("stickerPackSelected", pack);
        }
      } else if (pack.status === "released" && pack.purchased === false) {
        alert("Preview this pack.");
        this.$emit("previewStickerPack", pack);
      } else {
        alert("This pack is not currently available to purchase.");
      }
    }
  }
};
</script>

<style lang="scss">
.sticker-pack-selector__header {
  padding: 0.75rem 1rem 0.75rem;
  background-color: #f7f8fc;
  border-bottom: 1px solid #ebecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sticker-pack-selector__title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}
.sticker-pack-selector__options {
  max-height: 16rem;
  overflow-x: hidden;
  overflow-y: auto;
  @include responsive(small) {
    max-height: 16rem;
  }
}
.sticker-pack-selector__options {
  min-width: 19rem;
  @include responsive(small) {
    min-width: 19rem;
  }
}
.sticker-pack-selector__option {
  margin: 0.5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) {
  .sticker-pack-selector__option:hover {
    background: #f7f8fc;
    border-radius: 0.5rem;
  }
}
.sticker-pack-selector__option--is-selected {
  background: #f7f8fc;
  border-radius: 0.5rem;
}
.sticker-pack-selector__pack-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.sticker-pack-selector__pack-name {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
}
.sticker-pack-selector__pack-sample {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sticker-pack-selector__pack-selected-icon {
  width: 2rem;
  height: 2rem;
  background-color: #ebecef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
