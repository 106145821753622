<template>
  <div class="report-message-success">
    <BaseSuccessMessage>
      <template #title>Message reported</template>
      <template #description>
        <p>
          Thank you. We will take action if a breach of our
          <router-link :to="{ name: 'terms' }">Terms</router-link> has occured.
        </p>
        <p>
          In the meantime, you may want to block this account from sending you
          any more messages.
        </p>
      </template>
    </BaseSuccessMessage>
    <BaseButtonGroup>
      <BaseButton primary pad @click.native="done">Done</BaseButton>
    </BaseButtonGroup>
  </div>
</template>

<script>
export default {
  name: "ReportMessageSuccess",
  methods: {
    done() {
      this.$emit("done");
    }
  }
};
</script>
