<template>
  <button
    class="button"
    :class="{
      'button--primary': primary,
      'button--secondary': secondary,
      'button--is-loading': loading,
      'button--is-disabled': disabled,
      'button--pad': pad,
      'button--full-width': fullWidth,
      'button--feature': feature,
      'button--icon-only': iconOnly,
      'button--small': small,
      'button--rotate-icon-180': rotateIcon180
    }"
    :type="type"
    role="button"
    :disabled="disabled || loading"
  >
    <span class="button__icon" v-if="$slots.icon">
      <slot name="icon" />
    </span>
    <slot />
    <span class="button__icon-right" v-if="$slots.iconRight">
      <slot name="iconRight" />
    </span>
    <span class="button__loading" v-if="loading">
      <BaseSpinner />
    </span>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    type: {
      type: String,
      required: false,
      default: "button"
    },
    primary: {
      type: Boolean,
      required: false,
      default: false
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false
    },
    ariaLabel: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    pad: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    feature: {
      type: Boolean,
      required: false,
      default: false
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    rotateIcon180: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss">
.button {
  padding: 0 0.5rem;
  background-color: transparent;
  border-radius: 1.5rem;
  border: none;
  outline: 0;
  font-family: $base-font-family;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 0;
  color: #2c3e50;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  @include responsive(small) {
    padding: 0 0.75rem;
  }
}
@media (hover: hover) {
  .button:hover {
    background-color: #dedfe3;
  }
}
.button__loading {
  width: 24px;
  height: 24px;
  margin-left: 0.75rem;
}
.button__icon {
  padding-right: 0.5rem;
}
.button--icon-only .button__icon {
  padding-right: 0;
}
.button--icon-only {
  padding-left: 0;
}
.button--primary {
  height: 2.5rem;
  background-color: #353741;
  color: #fff;
  @include responsive(small) {
    height: 3rem;
  }
}
@media (hover: hover) {
  .button--primary:hover {
    background-color: #202127;
    box-shadow: $base-box-shadow;
    transform: translateY(-2px);
  }
}
.button--secondary {
  height: 2.5rem;
  background-color: rgba(#f7f8fc, 0.5);
  -webkit-backdrop-filter: $backdrop-blur-subtle;
  backdrop-filter: $backdrop-blur-subtle;
  border: 1px solid #dedfe3;
  color: #2c3e50;
  @include responsive(small) {
    height: 3rem;
  }
}
@media (hover: hover) {
  .button--secondary:hover {
    background-color: #fff;
    box-shadow: $base-box-shadow;
    transform: translateY(-2px);
  }
}
.button--is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
@media (hover: hover) {
  .button--is-disabled:hover {
    box-shadow: none;
    transform: none;
  }
}
.button--is-disabled:focus {
  box-shadow: none;
  transform: none;
}
.button--pad {
  padding: 0 1.25rem;
  @include responsive(small) {
    padding: 0 1.5rem;
  }
}
.button--full-width {
  width: 100%;
}
.button--feature {
  height: 4rem;
  min-width: 16rem;
  padding: 0 2rem 0 1.25rem;
  border-radius: 2rem;
  font-size: 1.25rem;
  @include responsive(small) {
    height: 5rem;
    min-width: 18rem;
    padding: 0 2rem 0 1.5rem;
    border-radius: 2.5rem;
    font-size: 1.5rem;
  }
}
.button--feature > svg {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  @include responsive(small) {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.75rem;
  }
}
.button--icon-only {
  padding: 0;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive(small) {
    width: 3rem;
  }
}
@media (hover: hover) {
  .button--icon-only:hover {
    // @include hover-highlight;
  }
}
.button--feature.button--icon-only {
  width: 4rem;
  min-width: inherit;
  @include responsive(small) {
    height: 4rem;
    width: 4rem;
  }
}
.button--feature.button--icon-only > svg {
  width: auto;
  height: auto;
  margin: 0;
}
.button--small {
  height: 2rem;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.button--icon-only.button--small {
  width: 2rem;
  height: 2rem;
  padding: 0;
}
.button--rotate-icon-180 > .button__icon > svg,
.button--rotate-icon-180 > .button__icon-right > svg {
  transform: rotate(180deg);
}
</style>
