<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="up-chevron-icon"
      d="M4.29289322,15.7781746 C4.68341751,16.1686989 5.31658249,16.1686989 5.70710678,15.7781746 L12.0710678,9.41421356 L18.4350288,15.7781746 C18.8255531,16.1686989 19.4587181,16.1686989 19.8492424,15.7781746 C20.2397667,15.3876503 20.2397667,14.7544853 19.8492424,14.363961 L12.7781746,7.29289322 C12.3876503,6.90236893 11.7544853,6.90236893 11.363961,7.29289322 L4.29289322,14.363961 C3.90236893,14.7544853 3.90236893,15.3876503 4.29289322,15.7781746 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseUpChevronIcon"
};
</script>

<style>
.up-chevron-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
