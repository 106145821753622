<template>
  <ModalView help @help="help">
    <BaseCard>
      <form
        class="secret-key-challenge__form"
        @submit.prevent="submitSecretKey"
      >
        <label
          class="secret-key-challenge__form-label secret-key-challenge__form-label--is-hidden"
          for="secretKey"
        >
          Secret key
        </label>
        <div class="secret-key-challenge__input-wrapper">
          <div class="secret-key-challenge__icon">
            <BaseKeyIcon />
          </div>
          <input
            id="secretKey"
            ref="secretKey"
            class="secret-key-challenge__input"
            type="text"
            autocapitalize="off"
            autocorrect="off"
            autocomplete="off"
            placeholder="Secret key"
            v-model="secretKey"
          />
        </div>
        <BaseButton
          @click.native.prevent="submitSecretKey"
          type="submit"
          primary
          pad
          :disabled="secretKey.length < 1"
          >Unlock</BaseButton
        >
      </form>
    </BaseCard>
  </ModalView>
</template>

<script>
import ModalView from "@/components/ModalView";
export default {
  name: "SecretKeyChallenge",
  components: {
    ModalView
  },
  data() {
    return {
      secretKey: ""
    };
  },
  validations: {},
  created() {
    this.$nextTick(() => {
      this.$refs.secretKey.focus();
    });
  },
  methods: {
    help() {
      this.$router.push("/help");
    },
    submitSecretKey() {
      this.$emit("submit", this.secretKey);
    }
  }
};
</script>

<style lang="scss">
.secret-key-challenge__form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.secret-key-challenge__form-label--is-hidden {
  @include visually-hidden;
}
.secret-key-challenge__input-wrapper {
  margin-right: 0.5rem;
  background-color: #ebecef;
  border: none;
  border-radius: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include responsive(small) {
    margin-right: 1rem;
  }
}
.secret-key-challenge__icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive(small) {
    width: 3rem;
    height: 3rem;
  }
}
.secret-key-challenge__input {
  width: 6rem;
  height: 2.5rem;
  margin: 0 1rem 0 0;
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  font-family: $base-font-family;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  resize: vertical;
  -webkit-appearance: none;
  color: inherit;
  @include responsive(small) {
    width: 8rem;
    height: 3rem;
    margin: 0 1.5rem 0 0;
  }
}
</style>
