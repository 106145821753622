<template>
  <BaseDropdown title="Options" align="right" secondary icon-only small-button>
    <template v-slot:dropdownButtonIcon>
      <BaseMoreIcon />
    </template>
    <template v-slot:dropdownContent>
      <ul class="message-sent-actions">
        <li class="message-sent-action" @click="viewMessage()">
          <BaseViewIcon />
          <span>View</span>
        </li>
        <li class="message-sent-action" @click="deleteMessage()">
          <BaseDeleteIcon />
          <span>Delete</span>
        </li>
        <!-- <li class="message-sent-action" @click="showSecretKey()">
          <BaseKeyIcon />
          <span>Show secret key</span>
        </li> -->
      </ul>
    </template>
  </BaseDropdown>
</template>

<script>
export default {
  name: "MessageSentActions",

  methods: {
    viewMessage() {
      this.$emit("viewMessage");
    },
    deleteMessage() {
      this.$emit("deleteMessage");
    },
    showSecretKey() {
      this.$emit("showSecretKey");
    }
  }
};
</script>

<style lang="scss">
.message-sent-actions {
  min-width: 12rem;
  padding: 0.5rem;
  @include responsive(small) {
    min-width: 12rem;
  }
}
.message-sent-action {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) {
  .message-sent-action:hover {
    background: #f7f8fc;
    border-radius: 0.5rem;
  }
}
.message-sent-action > svg {
  margin-right: 0.75rem;
}
</style>
