<template>
  <ModalView close @close="done">
    <template #title> Message Preview </template>
    <CipherText
      :messageChars="messageChars"
      :cipher="cipher"
      :stickerPack="message.stickerPack"
    />
  </ModalView>
</template>

<script>
import ModalView from "@/components/ModalView";
import CipherText from "@/components/CipherText";

export default {
  name: "MessagePreview",
  components: {
    ModalView,
    CipherText
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      messageChars: [],
      cipher: [
        { glyph: "a", code: 1, selected: false },
        { glyph: "b", code: 2, selected: false },
        { glyph: "c", code: 3, selected: false },
        { glyph: "d", code: 4, selected: false },
        { glyph: "e", code: 5, selected: false },
        { glyph: "f", code: 6, selected: false },
        { glyph: "g", code: 7, selected: false },
        { glyph: "h", code: 8, selected: false },
        { glyph: "i", code: 9, selected: false },
        { glyph: "j", code: 10, selected: false },
        { glyph: "k", code: 11, selected: false },
        { glyph: "l", code: 12, selected: false },
        { glyph: "m", code: 13, selected: false },
        { glyph: "n", code: 14, selected: false },
        { glyph: "o", code: 15, selected: false },
        { glyph: "p", code: 16, selected: false },
        { glyph: "q", code: 17, selected: false },
        { glyph: "r", code: 18, selected: false },
        { glyph: "s", code: 19, selected: false },
        { glyph: "t", code: 20, selected: false },
        { glyph: "u", code: 21, selected: false },
        { glyph: "v", code: 22, selected: false },
        { glyph: "w", code: 23, selected: false },
        { glyph: "x", code: 24, selected: false },
        { glyph: "y", code: 25, selected: false },
        { glyph: "z", code: 26, selected: false },
        { glyph: ".", code: 27, selected: false },
        { glyph: "?", code: 28, selected: false },
        { glyph: "!", code: 29, selected: false },
        { glyph: "_", code: 30, selected: false },
        { glyph: "0", code: 31, selected: false },
        { glyph: "1", code: 32, selected: false },
        { glyph: "2", code: 33, selected: false },
        { glyph: "3", code: 34, selected: false },
        { glyph: "4", code: 35, selected: false },
        { glyph: "5", code: 36, selected: false },
        { glyph: "6", code: 37, selected: false },
        { glyph: "7", code: 38, selected: false },
        { glyph: "8", code: 39, selected: false },
        { glyph: "9", code: 40, selected: false }
      ]
    };
  },
  async created() {
    this.generateCipher();
    this.getMessageChars();
  },
  methods: {
    done() {
      this.$emit("done");
    },
    getMessageChars() {
      let lowercaseString = this.message.content.toLowerCase();
      // Replace all spaces, tabs, new lines and carriage returns with underscores
      let parsedString = lowercaseString.replace(/\s/g, "_");
      let messageChars = [];
      // Use spread operator to create array of characters from message
      [...parsedString].forEach(glyph => {
        let character = {
          glyph: glyph,
          encryptionState: "encrypted",
          selected: false
        };
        messageChars.push(character);
      });
      return (this.messageChars = messageChars);
    },
    // Update the cipher based on the secret key (if there is one)
    generateCipher() {
      const secretKey = this.message.secretKey;
      let offset = secretKey.length + 1;
      this.cipher.forEach((character, i) => {
        if (secretKey.includes(character.glyph)) {
          let index = secretKey.indexOf(character.glyph);
          this.cipher[i].code = index + 1;
        } else {
          this.cipher[i].code = offset;
          offset++;
        }
      });
    },
    isCharacterEncrypted(charIndex) {
      if (this.messageChars[charIndex].encryptionState === "encrypted") {
        return true;
      } else return false;
    }
  }
};
</script>

<style lang="scss">
.message {
  margin: 0 auto;
  padding: 1rem;
  @include responsive(small) {
    padding: 1.5rem 2rem;
  }
}
.message__header-group {
  display: flex;
  justify-content: flex-start;
}
.message__cipher-text {
  min-height: calc(100vh - 16rem);
  display: flex;
  align-items: center;
  position: relative;
}
.message__cipher-text .cipher-text__characters {
  margin-bottom: 3.5rem;
  padding-top: 3rem;
  @include responsive(medium) {
    margin-bottom: 4rem;
  }
}
</style>
