<template>
  <div class="send-error">
    <BaseCard>
      <BaseErrorMessage>
        <template #title>
          Error
        </template>
        <template #description>
          Your message has been created but unfortunately we were unable to send
          a notification to
          {{ recipientData.recipientName }} at
          {{ recipientData.recipientEmail }}. Please report this error by
          contacting us at
          <a href="mailto:help@stickercode.app">help@stickercode.app</a>. Thank
          you and apologies for the inconvenience!
        </template>
      </BaseErrorMessage>
      <BaseButtonGroup>
        <BaseButton @click.native="done" primary pad>
          Done
        </BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "SendError",
  props: {
    recipientData: {
      type: Object,
      required: true
    }
  },
  methods: {
    done() {
      this.$emit("done");
    }
  }
};
</script>

<style lang="scss">
.send-error {
  height: 100vh;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
