<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      class="delete-icon"
      d="M12,13 C16.4182847,13 20,16.5817153 20,21 C20,21.5522847 19.5522847,22 19,22 L19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 C4,16.5817185 7.58172202,13 12,13 Z M12,15 C9.06284891,15 6.61862269,17.110457 6.10104481,19.897653 L6.10104481,19.897653 L6.084,20 L17.915,20 L17.8989554,19.897652 C17.3946498,17.1819207 15.0612347,15.1086733 12.2249387,15.0041385 L12.2249387,15.0041385 Z M12,2 C14.761397,2 17,4.23858752 17,7 C17,9.76141248 14.761397,12 12,12 C9.23857525,12 7,9.76142475 7,7 C7,4.23857525 9.23857525,2 12,2 Z M12,4 C10.3431447,4 9,5.34314475 9,7 C9,8.65685525 10.3431447,10 12,10 C13.6568306,10 15,8.65683989 15,7 C15,5.34316011 13.6568306,4 12,4 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseDeleteIcon"
};
</script>

<style>
.delete-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
