<template>
  <BaseCard large stickyHeader>
    <template #title>
      Inbox
      <span
        class="messages-received__unread-count"
        v-if="unreadCount > 0"
        title="Unread"
      >
        {{ unreadCount }}
      </span>
      <span
        class="messages-received__in-review-count"
        v-if="inReviewCount > 0"
        title="In review"
      >
        {{ inReviewCount }}
      </span>
    </template>
    <template #subtitle>Received in last 28 days</template>
    <div class="messages-received__table">
      <Loading v-if="!messages" />
      <BaseErrorMessage v-else-if="gettingMessagesReceivedFailed">
        Unable to fetch your messages.
      </BaseErrorMessage>
      <BaseNeutralMessage v-else-if="messages.length < 1">
        <template #description>
          You haven't received any messages in the last 28 days.
          <router-link :to="{ name: 'write' }">Send a message</router-link>
          to a friend and their reply will show up here.
        </template>
      </BaseNeutralMessage>
      <MessageReceived
        v-else
        v-for="message in messages"
        :key="message.Id"
        :message="message"
      />
    </div>
  </BaseCard>
</template>

<script>
import Loading from "@/components/Loading";
import MessageReceived from "@/components/MessageReceived";
import API, { graphqlOperation } from "@aws-amplify/api";
import { messagesByRecipientEmail } from "@/graphql/queries";
import { onCreateMessageByRecipientEmail } from "@/graphql/subscriptions";
import { onUpdateMessageByRecipientEmail } from "@/graphql/subscriptions";
import { onDeleteMessageByRecipientEmail } from "@/graphql/subscriptions";
export default {
  name: "MessagesReceived",
  components: {
    Loading,
    MessageReceived
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      messages: null,
      gettingMessagesReceivedFailed: false
    };
  },
  created() {
    this.getMessagesReceived();
    this.subscribeToOnCreateMessage();
    this.subscribeToOnUpdateMessage();
    this.subscribeToOnDeleteMessage();
  },
  computed: {
    unreadCount() {
      if (this.messages) {
        const unreadMessages = this.messages.filter(
          message => !message.decodedAt
        );
        const unreadCount = unreadMessages.length;
        return unreadCount;
      } else {
        return 0;
      }
    },
    inReviewCount() {
      if (this.messages) {
        const inReviewMessages = this.messages.filter(
          message => message.inReview
        );
        const inReviewCount = inReviewMessages.length;
        return inReviewCount;
      } else {
        return 0;
      }
    }
  },
  methods: {
    async getMessagesReceived() {
      try {
        // Attempt to get messages received from backend.
        const result = await API.graphql(
          graphqlOperation(messagesByRecipientEmail, {
            recipientEmail: this.user.attributes.email,
            limit: 10,
            sortDirection: "DESC"
          })
        );
        // console.log(result.data.messagesByRecipientEmail.items);
        return (this.messages = result.data.messagesByRecipientEmail.items);
      } catch (error) {
        this.gettingMessagesReceivedFailed = true;
        return error;
      }
    },
    async subscribeToOnCreateMessage() {
      const subscription = API.graphql(
        graphqlOperation(onCreateMessageByRecipientEmail, {
          recipientEmail: this.user.attributes.email
        })
      ).subscribe({
        next: event => {
          // Add the new message to the front of the messages array
          this.messages.unshift(
            event.value.data.onCreateMessageByRecipientEmail
          );
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    },
    async subscribeToOnUpdateMessage() {
      const subscription = API.graphql(
        graphqlOperation(onUpdateMessageByRecipientEmail, {
          recipientEmail: this.user.attributes.email
        })
      ).subscribe({
        next: event => {
          // Replace the old message with the new version
          const message = this.messages.find(
            message =>
              message.id === event.value.data.onUpdateMessageByRecipientEmail.id
          );
          const index = this.messages.indexOf(message);
          // alert(index);
          this.messages.splice(
            index,
            1,
            event.value.data.onUpdateMessageByRecipientEmail
          );
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    },
    async subscribeToOnDeleteMessage() {
      const subscription = API.graphql(
        graphqlOperation(onDeleteMessageByRecipientEmail, {
          recipientEmail: this.user.attributes.email
        })
      ).subscribe({
        next: event => {
          // Delete this message from the messages array
          const message = this.messages.find(
            message =>
              message.id === event.value.data.onDeleteMessageByRecipientEmail.id
          );
          const index = this.messages.indexOf(message);
          // alert(index);
          this.messages.splice(index, 1);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }
};
</script>

<style lang="scss">
.messages-received__unread-count {
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  background-color: #0baaf3;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  user-select: none;
}
.messages-received__in-review-count {
  margin-left: 0.25rem;
  padding: 0 0.5rem;
  background-color: #f95959;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  user-select: none;
}
</style>
