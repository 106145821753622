<template>
  <div
    class="badge"
    :class="{
      'badge--primary': primary,
      'badge--danger': danger
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseBadge",
  props: {
    primary: {
      type: Boolean,
      required: false,
      default: false
    },
    danger: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss">
.badge {
  height: 1.25rem;
  margin: 0 1rem;
  padding: 0 0.25rem;
  background: #ebecef;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0125rem;
  line-height: $line-height-tight;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74778a;
}
.badge--primary {
  background: #3fc0ef;
  color: #fff;
}
.badge--danger {
  background: #f26975;
}
</style>
