<template>
  <li
    class="cipher-character"
    :class="{ 'cipher-character--is-selected': character.selected }"
  >
    <BaseSticker1 v-if="character.code === 1" :stickerPack="stickerPack" />
    <BaseSticker2 v-if="character.code === 2" :stickerPack="stickerPack" />
    <BaseSticker3 v-if="character.code === 3" :stickerPack="stickerPack" />
    <BaseSticker4 v-if="character.code === 4" :stickerPack="stickerPack" />
    <BaseSticker5 v-if="character.code === 5" :stickerPack="stickerPack" />
    <BaseSticker6 v-if="character.code === 6" :stickerPack="stickerPack" />
    <BaseSticker7 v-if="character.code === 7" :stickerPack="stickerPack" />
    <BaseSticker8 v-if="character.code === 8" :stickerPack="stickerPack" />
    <BaseSticker9 v-if="character.code === 9" :stickerPack="stickerPack" />
    <BaseSticker10 v-if="character.code === 10" :stickerPack="stickerPack" />
    <BaseSticker11 v-if="character.code === 11" :stickerPack="stickerPack" />
    <BaseSticker12 v-if="character.code === 12" :stickerPack="stickerPack" />
    <BaseSticker13 v-if="character.code === 13" :stickerPack="stickerPack" />
    <BaseSticker14 v-if="character.code === 14" :stickerPack="stickerPack" />
    <BaseSticker15 v-if="character.code === 15" :stickerPack="stickerPack" />
    <BaseSticker16 v-if="character.code === 16" :stickerPack="stickerPack" />
    <BaseSticker17 v-if="character.code === 17" :stickerPack="stickerPack" />
    <BaseSticker18 v-if="character.code === 18" :stickerPack="stickerPack" />
    <BaseSticker19 v-if="character.code === 19" :stickerPack="stickerPack" />
    <BaseSticker20 v-if="character.code === 20" :stickerPack="stickerPack" />
    <BaseSticker21 v-if="character.code === 21" :stickerPack="stickerPack" />
    <BaseSticker22 v-if="character.code === 22" :stickerPack="stickerPack" />
    <BaseSticker23 v-if="character.code === 23" :stickerPack="stickerPack" />
    <BaseSticker24 v-if="character.code === 24" :stickerPack="stickerPack" />
    <BaseSticker25 v-if="character.code === 25" :stickerPack="stickerPack" />
    <BaseSticker26 v-if="character.code === 26" :stickerPack="stickerPack" />
    <BaseSticker27 v-if="character.code === 27" :stickerPack="stickerPack" />
    <BaseSticker28 v-if="character.code === 28" :stickerPack="stickerPack" />
    <BaseSticker29 v-if="character.code === 29" :stickerPack="stickerPack" />
    <BaseSticker30 v-if="character.code === 30" :stickerPack="stickerPack" />
    <BaseSticker31 v-if="character.code === 31" :stickerPack="stickerPack" />
    <BaseSticker32 v-if="character.code === 32" :stickerPack="stickerPack" />
    <BaseSticker33 v-if="character.code === 33" :stickerPack="stickerPack" />
    <BaseSticker34 v-if="character.code === 34" :stickerPack="stickerPack" />
    <BaseSticker35 v-if="character.code === 35" :stickerPack="stickerPack" />
    <BaseSticker36 v-if="character.code === 36" :stickerPack="stickerPack" />
    <BaseSticker37 v-if="character.code === 37" :stickerPack="stickerPack" />
    <BaseSticker38 v-if="character.code === 38" :stickerPack="stickerPack" />
    <BaseSticker39 v-if="character.code === 39" :stickerPack="stickerPack" />
    <BaseSticker40 v-if="character.code === 40" :stickerPack="stickerPack" />
    <span class="cipher-character__letter">{{ character.glyph }}</span>
  </li>
</template>

<script>
export default {
  name: "CipherCharacter",
  props: {
    character: {
      type: Object,
      required: true
    },
    stickerPack: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.cipher-character {
  padding: 0.5rem 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.2s ease-in-out;
  transform-origin: bottom center;
  overflow: visible;
  @include responsive(small) {
    padding: 0.75rem 0.25rem;
  }
}
@media (hover: hover) {
  .cipher-character:hover {
    transform: scale(1.25);
  }
}
.cipher-character > svg {
  width: 54px;
  height: 54px;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
  opacity: 1;
  transition: all 1s ease-in-out;
  overflow: visible;
  @include responsive(small) {
    width: 64px;
    height: 64px;
  }
}
// .cipher-character--is-selected svg {
//   filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.5));
//   animation: bounce 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
//   animation-delay: 0.2s;
//   transform: translate3d(0, 0, 0);
//   backface-visibility: hidden;
//   perspective: 1000px;
// }
.cipher-character--is-decoded {
}
.cipher-character__letter {
  font-size: 1.25rem;
  font-weight: 500;
  // text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.5rem;
  @include responsive(small) {
    font-size: 1.5rem;
  }
}

@media print {
  .cipher-character {
    padding: 0.5rem 0.0625rem;
  }
  .cipher-character__letter {
    display: block;
    margin: 0 0.25rem 0;
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: capitalize;
    @include responsive(small) {
      font-size: 1.5rem;
    }
  }
}
</style>
