<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="right-chevron-icon"
      d="M7.82842712,19.3137085 C7.43790283,18.9231842 7.43790283,18.2900192 7.82842712,17.8994949 L14.1923882,11.5355339 L7.82842712,5.17157288 C7.43790283,4.78104858 7.43790283,4.1478836 7.82842712,3.75735931 C8.21895142,3.36683502 8.8521164,3.36683502 9.24264069,3.75735931 L16.3137085,10.8284271 C16.7042328,11.2189514 16.7042328,11.8521164 16.3137085,12.2426407 L9.24264069,19.3137085 C8.8521164,19.7042328 8.21895142,19.7042328 7.82842712,19.3137085 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BaseRightChevronIcon"
};
</script>

<style>
.right-chevron-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}
</style>
