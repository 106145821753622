<template>
  <PageView>
    <BaseCard>
      <template #title>Help</template>
      <template #description>Coming soon.</template>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente
        adipisci culpa debitis quo id reprehenderit perferendis non quam qui eum
        at labore, tempore distinctio itaque. A neque similique ea ratione,
        soluta, commodi fuga nihil doloribus.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum
        earum, quasi doloremque nisi! Nobis voluptates officiis beatae itaque
        placeat, vel assumenda, veritatis in sunt.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint labore
        corporis incidunt maxime dolores id assumenda expedita, vitae odit.
        Sapiente esse neque ullam impedit harum minus rem, repellat nemo
        recusandae temporibus!
      </p>
    </BaseCard>
  </PageView>
</template>

<script>
import PageView from "@/components/PageView";

export default {
  name: "Help",
  components: {
    PageView
  }
};
</script>
