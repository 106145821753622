<template>
  <PageView
    class="editor"
    :class="{ 'editor--message-box-is-focused': messageBoxIsFocused === true }"
  >
    <Cipher
      class="editor__cipher"
      :cipher="cipher"
      :stickerPack="selectedStickerPack.id"
    />
    <CipherText
      class="editor__cipher-text"
      :messageChars="messageChars"
      :cipher="cipher"
      :stickerPack="selectedStickerPack.id"
      @stickerAdded="playStickerAdded"
      @stickerRemoved="playStickerRemoved"
    />
    <StickerPackList
      v-if="showStickerPackList"
      @close="closeStickerPackList"
      @previewPack="previewStickerPack"
      @buyPack="buyStickerPack"
    />
    <StickerPackPreview
      v-if="showStickerPackPreview"
      :stickerPack="stickerPackToPreview"
      @back="backToStickerPackList"
      @buyPack="buyStickerPack"
    />
    <form class="editor__controls">
      <div class="editor__controls-inner">
        <div class="editor__message">
          <transition name="fade">
            <div
              class="editor__message-error"
              v-if="!$v.form.content.validMessage && $v.form.content.$dirty"
            >
              Messages can only contain letters, numbers, spaces and simple
              punctuation (.?!).
            </div>
          </transition>
          <textarea
            ref="message"
            class="editor__message-input"
            v-model="$v.form.content.$model"
            rows="1"
            placeholder="Type your secret message here."
            @input="contentChanged = true"
            @focus="toggleMessageBoxIsFocused()"
            @blur="toggleMessageBoxIsFocused()"
          />
        </div>
        <div class="editor__options">
          <StickerPackSelector
            @stickerPackSelected="setSelectedStickerPack"
            @listStickerPacks="listStickerPacks"
          />
          <BaseButtonGroup>
            <!-- <SecretKey
              v-model.trim="$v.form.secretKey.$model"
              :show="false"
              :validator="$v.form.secretKey"
              @input="generateCipher()"
              @focusMessageBox="focusMessageBox()"
            /> -->
            <BaseDropdown
              title="More options"
              position="above"
              align="right"
              secondary
              icon-only
              :disabled="
                !$v.form.content.validMessage ||
                  !$v.form.content.required ||
                  !$v.form.secretKey.alphaNum
              "
            >
              <template #dropdownButtonIcon>
                <BaseMoreIcon />
              </template>
              <template #dropdownContent>
                <ul class="editor__more-options">
                  <li class="editor__more-option" @click="print()">
                    <BasePrintIcon />
                    <span>Print</span>
                  </li>
                  <li
                    v-if="messageEncryptionState === 'encrypted'"
                    class="editor__more-option"
                    @click="toggleMessageEncryptionState()"
                  >
                    <BaseViewIcon />
                    <span>Show text</span>
                  </li>
                  <li
                    v-else
                    class="editor__more-option"
                    @click="toggleMessageEncryptionState()"
                  >
                    <BaseViewDisabledIcon />
                    <span>Hide Text</span>
                  </li>
                </ul>
              </template>
            </BaseDropdown>
            <BaseButton
              primary
              pad
              :disabled="
                !$v.form.content.validMessage ||
                  !$v.form.content.required ||
                  !$v.form.secretKey.alphaNum
              "
              type="submit"
              @click.native.prevent="requestSharingOptions"
            >
              Send
            </BaseButton>
          </BaseButtonGroup>
        </div>
      </div>
    </form>
  </PageView>
</template>

<script>
const permittedChars = /^[a-zA-Z0-9.?!_\s]*$/;
import PageView from "@/components/PageView";
import Cipher from "@/components/Cipher";
import StickerPackSelector from "@/components/StickerPackSelector";
import StickerPackList from "@/components/StickerPackList";
import StickerPackPreview from "@/components/StickerPackPreview";
// import SecretKey from "@/components/SecretKey";
import CipherText from "@/components/CipherText";
import { alphaNum, required } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const validMessage = helpers.regex("validMessage", permittedChars);
// import { Howl } from "howler";
// // // Setup sounds
// const stickerAddedSound = new Howl({
//   src: [
//     "https://res.cloudinary.com/dv5har4fh/video/upload/v1603097209/StickerCode/audio/stickerAdded.mp3"
//   ]
// });
// const stickerRemovedSound = new Howl({
//   src: [
//     "https://res.cloudinary.com/dv5har4fh/video/upload/v1603097204/StickerCode/audio/stickerRemoved.mp3"
//   ],
//   volume: 0.1
// });
export default {
  name: "Editor",
  components: {
    PageView,
    Cipher,
    StickerPackSelector,
    StickerPackList,
    StickerPackPreview,
    // SecretKey,
    CipherText
  },
  data() {
    return {
      form: {
        content: "",
        secretKey: ""
      },
      messageBoxIsFocused: false,
      showSecretKey: false,
      messageEncryptionState: "encrypted",
      cipher: [
        { glyph: "a", code: 1 },
        { glyph: "b", code: 2 },
        { glyph: "c", code: 3 },
        { glyph: "d", code: 4 },
        { glyph: "e", code: 5 },
        { glyph: "f", code: 6 },
        { glyph: "g", code: 7 },
        { glyph: "h", code: 8 },
        { glyph: "i", code: 9 },
        { glyph: "j", code: 10 },
        { glyph: "k", code: 11 },
        { glyph: "l", code: 12 },
        { glyph: "m", code: 13 },
        { glyph: "n", code: 14 },
        { glyph: "o", code: 15 },
        { glyph: "p", code: 16 },
        { glyph: "q", code: 17 },
        { glyph: "r", code: 18 },
        { glyph: "s", code: 19 },
        { glyph: "t", code: 20 },
        { glyph: "u", code: 21 },
        { glyph: "v", code: 22 },
        { glyph: "w", code: 23 },
        { glyph: "x", code: 24 },
        { glyph: "y", code: 25 },
        { glyph: "z", code: 26 },
        { glyph: ".", code: 27 },
        { glyph: "?", code: 28 },
        { glyph: "!", code: 29 },
        { glyph: "_", code: 30 },
        { glyph: "0", code: 31 },
        { glyph: "1", code: 32 },
        { glyph: "2", code: 33 },
        { glyph: "3", code: 34 },
        { glyph: "4", code: 35 },
        { glyph: "5", code: 36 },
        { glyph: "6", code: 37 },
        { glyph: "7", code: 38 },
        { glyph: "8", code: 39 },
        { glyph: "9", code: 40 }
      ],
      selectedStickerPack: {
        id: "emoji",
        name: "Emoji Code"
      },
      showStickerPackList: false,
      showStickerPackPreview: false,
      stickerPackToPreview: {}
    };
  },
  validations: {
    form: {
      content: {
        required,
        validMessage
      },
      secretKey: {
        alphaNum
      }
    }
  },
  computed: {
    messageChars() {
      let lowercaseString = this.form.content.toLowerCase();
      // Replace all spaces, tabs, new lines and carriage returns with underscores
      let parsedString = lowercaseString.replace(/\s/g, "_");
      let messageChars = [];
      // Use spread operator to create array of characters from message
      [...parsedString].forEach(glyph => {
        // Only add character to array if it is a permitted char
        const regex = RegExp(permittedChars);
        if (regex.test(glyph)) {
          let character = {
            glyph: glyph,
            encryptionState: this.messageEncryptionState
          };
          messageChars.push(character);
        }
      });
      return messageChars;
    }
  },
  mounted() {
    this.generateCipher();
  },
  methods: {
    closeStickerPackList() {
      this.showStickerPackList = false;
    },
    closeStickerPackPreview() {
      this.showStickerPackPreview = false;
    },
    focusMessageBox() {
      this.$nextTick(() => {
        this.$refs.message.focus();
      });
    },
    toggleMessageBoxIsFocused() {
      this.messageBoxIsFocused = !this.messageBoxIsFocused;
    },
    playStickerAdded() {
      // stickerAddedSound.play();
    },
    playStickerRemoved() {
      // stickerRemovedSound.play();
    },
    print() {
      window.print();
    },
    toggleMessageEncryptionState() {
      if (this.messageEncryptionState === "encrypted") {
        this.messageEncryptionState = "decrypted";
      } else {
        this.messageEncryptionState = "encrypted";
      }
    },
    requestSharingOptions() {
      // Check message data is valid
      this.$v.$touch();
      if (this.$v.$invalid) return;
      // Pass message data to parent component for handling
      const messageData = {
        content: this.form.content,
        secretKey: this.form.secretKey,
        stickerPack: this.selectedStickerPack.id
      };
      this.$emit("requestSharingOptions", messageData);
    },
    resetEditor() {
      this.form.content = "";
      this.form.secretKey = "";
      this.showSecretKey = false;
      // this.selectedStickerPack.id = "emoji";
      this.$nextTick(() => {
        // Reset validations so that we don't display errors
        this.$v.$reset();
      });
    },
    setSelectedStickerPack(pack) {
      this.selectedStickerPack = pack;
    },
    listStickerPacks() {
      this.showStickerPackList = true;
    },
    previewStickerPack(pack) {
      this.showStickerPackPreview = true;
      this.stickerPackToPreview = pack;
    },
    buyStickerPack(pack) {
      alert("Buy pack: " + pack.name);
    },
    backToStickerPackList() {
      this.showStickerPackPreview = false;
      this.showStickerPackList = true;
    },
    generateCipher() {
      // if (this.$v.$error) return;
      // Remove duplicate characters and return array of remaining characters
      let secretKeyWithDuplicateCharactersRemoved = [
        ...new Set(this.form.secretKey)
      ];
      // console.log(secretKeyWithDuplicateCharactersRemoved);
      let offset = secretKeyWithDuplicateCharactersRemoved.length + 1;
      return this.cipher.forEach((character, i) => {
        this.cipher[i].code = offset;
        offset++;
      });
    }
  }
};
</script>

<style lang="scss">
.editor {
}
.editor__actions-group {
  display: flex;
  justify-content: flex-start;
}
.editor__actions-group .button {
  margin-left: 0.5rem;
  @include responsive(small) {
    margin-left: 1rem;
  }
}
.editor__more-options {
  min-width: 12rem;
  padding: 0.5rem;
  @include responsive(small) {
    min-width: 12rem;
  }
}
.editor__more-option {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) {
  .editor__more-option:hover {
    background: #f7f8fc;
    border-radius: 0.5rem;
  }
}
.editor__more-option > svg {
  margin-right: 0.75rem;
}
.editor__controls {
  background-color: rgba(#fff, 0.75);
  -webkit-backdrop-filter: $backdrop-blur-subtle;
  backdrop-filter: $backdrop-blur-subtle;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
  // border: 1px solid red;
}
.editor__controls-inner {
  width: 100%;
  max-width: 64rem;
  padding: 1rem;
  @include responsive(small) {
    padding: 1rem 2rem;
  }
}
@supports (padding: max(0px)) {
  .editor__controls-inner {
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
  }
}
.editor__options {
  margin: 0.125rem 0;
  display: flex;
  justify-content: space-between;
  @include responsive(small) {
    margin: 0.5rem 0;
  }
}
.editor__options-group {
  display: flex;
}
.editor__message {
  position: relative;
}
.editor__message-error {
  padding: 0.75rem 1rem;
  background: $background-dark-linear;
  border-radius: 1rem;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 4.5rem;
  @include responsive(small) {
    bottom: 4.75rem;
  }
}
.editor__message-input {
  width: 100%;
  padding: 1rem 1rem;
  background-color: rgba(#ebecef, 1);
  box-shadow: none;
  box-sizing: border-box;
  border: none;
  border-radius: 1rem;
  font-family: $base-font-family;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  outline: none;
  resize: none;
  -webkit-appearance: none;
  @include responsive(small) {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
  }
}
.editor__message-input:focus {
  background-color: rgba(#fff, 0.85);
  box-shadow: $base-box-shadow;
}
.editor__cipher {
  visibility: hidden; // Don't use display:none as this breaks SVG rendering
}
.editor__cipher-text {
  min-height: calc(100vh - 8rem);
  display: flex;
  align-items: center;
  position: relative;
  @include responsive(small) {
    padding: 1rem 2rem;
  }
}
.editor__cipher-text .cipher-text__characters {
  margin: 0;
  margin-bottom: 6rem;
  padding-top: 3rem;
  @include responsive(medium) {
    margin-bottom: 7rem;
    padding-top: 1rem;
  }
}
.editor--message-box-is-focused .editor__cipher-text {
  min-height: auto;
  margin: 0;
  position: fixed;
  bottom: 4rem;
  @include responsive(small) {
    bottom: 8rem;
  }
  @include responsive(medium) {
    bottom: 8rem;
  }
  @include responsive(large) {
    min-height: calc(100vh - 8rem);
    display: flex;
    align-items: center;
    position: relative;
    bottom: auto;
  }
}

@media print {
  .editor__actions {
    visibility: hidden; // Don't use display:none as this breaks SVG rendering
  }
  .editor__options {
    visibility: hidden; // Don't use display:none as this breaks SVG rendering
  }
  .editor__message {
    visibility: hidden; // Don't use display:none as this breaks SVG rendering
  }
  .editor__cipher {
    visibility: visible; // Don't use display:block as this breaks SVG rendering
  }
  .editor__cipher-text {
    min-height: auto;
  }
}
</style>
