<template>
  <PageView>
    <ViewLoading v-if="loading" />
    <div class="messages" v-else>
      <BaseButton
        class="messages__new-message-button"
        primary
        iconOnly
        feature
        @click.native="newMessage"
      >
        <BaseComposeIcon />
      </BaseButton>
      <MessagesReceived :user="user" />
      <MessagesSent :user="user" />
    </div>
  </PageView>
</template>

<script>
import PageView from "@/components/PageView";
import MessagesReceived from "@/components/MessagesReceived";
import MessagesSent from "@/components/MessagesSent";
import ViewLoading from "@/components/ViewLoading";
import Auth from "@aws-amplify/auth";

export default {
  name: "Messages",
  components: {
    PageView,
    MessagesReceived,
    MessagesSent,
    ViewLoading
  },
  data() {
    return {
      loading: true,
      user: null
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.loading = false;
        return (this.user = user);
      } catch (error) {
        // TODO: add a generic error component in case of failure to get user
        return error;
      }
    },
    newMessage() {
      // Push to write view
      this.$router.push("/write");
    }
  }
};
</script>

<style lang="scss">
.messages {
  display: grid;
  position: relative;
}
.messages__new-message-button {
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  z-index: 30;
  @include responsive(small) {
    bottom: 1.5rem;
    right: 1.5rem;
  }
}
</style>
