<template>
  <div>
    <MessageLoadingError v-if="activeComponent === 'MessageLoadingError'" />
    <MessageInReview v-else-if="activeComponent === 'MessageInReview'" />
    <Message :message="message" v-else-if="activeComponent === 'Message'" />
    <ViewLoading v-else />
  </div>
</template>

<script>
import ViewLoading from "@/components/ViewLoading";
import MessageLoadingError from "@/components/MessageLoadingError";
import MessageInReview from "@/components/MessageInReview";
import Message from "@/components/Message";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getMessage } from "@/graphql/queries";
export default {
  name: "Read",
  components: {
    ViewLoading,
    MessageLoadingError,
    MessageInReview,
    Message
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: null,
      activeComponent: "MessageLoading"
    };
  },
  async created() {
    // Fetch data from GraphQL
    await this.getMessage();
  },
  methods: {
    async getMessage() {
      this.activeComponent = "MessageLoading";
      const message = await API.graphql(
        graphqlOperation(getMessage, { id: this.id })
      );
      // Add a delay to ensure user sees loading modal
      setTimeout(() => {
        this.message = message.data.getMessage;
        if (this.message == null) {
          this.activeComponent = "MessageLoadingError";
        } else if (this.message.inReview === true) {
          this.activeComponent = "MessageInReview";
        } else {
          this.activeComponent = "Message";
        }
      }, 1000);
    }
  }
};
</script>
