<template>
  <div class="sign-in">
    <BaseCard>
      <h1>Sign in</h1>
      <div>
        Enter your <strong>email address</strong> and <strong>password</strong>.
      </div>
      <!-- Login form -->
      <form class="form" @submit.prevent="signInUser">
        <div class="form__body">
          <BaseInput
            id="email"
            ref="email"
            label="Email"
            type="email"
            placeholder="you@example.com"
            hide-label
            v-model="form.email"
          />
          <BaseInput
            id="password"
            ref="password"
            label="Password"
            type="password"
            placeholder="password"
            hide-label
            v-model="form.password"
          />
        </div>
        <div class="form__footer">
          <BaseButtonGroup>
            <BaseButton type="submit">Sign In</BaseButton>
          </BaseButtonGroup>
        </div>
      </form>
    </BaseCard>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  data: () => ({
    form: {
      email: "",
      password: ""
    }
  }),
  methods: {
    async signInUser() {
      try {
        await this.$store.dispatch("auth/login", this.form);
        this.$router.push("/"); // Send user to app now they are signed in
      } catch (error) {
        console.log({ error });
      }
    }
  }
};
</script>

<style lang="scss">
.sign-in {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
